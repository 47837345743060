export default function histogram(data, key, interval) {
  let min = Infinity;
  let max = -Infinity;
  const histogram = data.reduce((acc, record) => {
    const date = record[key];
    min = Math.min(min, date);
    max = Math.max(max, date);
    const value = Math.floor(date / interval) * interval;
    if (!acc[value]) {
      acc[value] = {
        [key]: value,
      };
      // console.log("acc[value]", acc[value], value, key, {
      //   load: record?.load,
      //   sale: record?.sale,
      // });
    }
    const keys = Object.keys(record);
    keys.forEach((prop) => {
      if (prop === "load" || prop === "sale") {
        // console.log("prop", prop, record[prop]);
      }
      if (isNaN(record[prop]) || prop === "date") {
        acc[value][prop] = record[prop];
        return;
      }
      if (!acc[value][prop]) {
        acc[value][prop] = 0;
      }
      acc[value][prop] += record[prop];
    });
    return acc;
  }, {});

  const values = Object.values(histogram);
  // console.log(
  //   "values histogram",
  //   values.map((record) => ({
  //     [key]: parseFloat(record[key]),
  //     load: record?.load,
  //     sale: record?.sale,
  //   }))
  // );
  if (values?.length > 0) {
    const firstData = values[0];
    const keys = Object.keys(firstData);
    const empty = keys.reduce((acc, key) => ({ ...acc, [key]: 0 }), {});
    // fill values with missing entries
    const qty = Math.ceil((max - min) / interval) + 2;
    // console.log("max min", max, min, interval, "==>", qty);
    const fullValues = new Array(qty).fill({ ...empty }).map((emptyObj, i) => {
      const value = (i - 1) * interval + min;
      const found = values.reduce(
        (acc, v) => {
          // console.log(
          //   "v",
          //   v[key],
          //   value,
          //   value + interval,
          //   parseFloat(v[key]) >= value && parseFloat(v[key]) < value + interval
          // );
          if (
            parseFloat(v[key]) >= value &&
            parseFloat(v[key]) < value + interval
          ) {
            Object.keys(v).forEach((_key) => {
              if (_key === "load" || _key === "sale") {
                // console.log("key", _key, v[_key]);
              }
              if (!isNaN(v[_key]) && _key !== key) {
                acc[_key] += parseFloat(v[_key]);
                return;
              }
            });
          }
          return acc;
        },
        { ...emptyObj, [key]: value }
      );
      // console.log("value", key, value, found?.load, found?.sale);
      if (found) {
        return { ...found, [key]: value };
      }
      return { ...empty, [key]: value };
    });
    return fullValues;
  }
  return [];
}
