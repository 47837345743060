import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styles from "./reports.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightArrowLeft,
  faCashRegister,
  faChartSimple,
  faCopy,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { usePage } from "../../hooks/usePage";
import useLang from "../../lang/useLang";
import useEvents from "../../hooks/useEvents";
import useLocations from "../../hooks/useLocations";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import { useEffect, useState } from "react";

export const PKK =
  "eyJjcnYiOiJQLTI1NiIsImQiOiJqejEzUWxVQk5QZU56ZGdYLWpxak9zQUdieE9sQm1jbWR1Qmk1T3VXUEpvIiwiZXh0Ijp0cnVlLCJrZXlfb3BzIjpbInNpZ24iXSwia3R5IjoiRUMiLCJ4IjoiRzltODVpUWl6bDdCNHdZdEhHRnB0WlRYSV9Fa0JtMU11LUV1bnRnT0VxUSIsInkiOiIwZi11RThOVlRtN2Nqak5SZlA0ZV82dXRBM0Z1QjN0ZXRaT1JlTzFmN2o4In0";

export default function ReportsIndex() {
  const { trans } = useLang();
  usePage({ title: trans("reports.reports") });
  const { eventid } = useParams();
  const { event } = useEvents();
  const { locations } = useLocations({ eventid });

  const [selectedLocations, setSelectedLocations] = useState([]);

  const handleCopyViewerLink = () => {
    navigator.clipboard.writeText(viewerLink);
    alert("Link copiado al portapapeles");
  };

  const [viewerLink, setViewerLink] = useState(null);
  // const [publicKey, setPublicKey] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);

  useEffect(() => {
    async function generateFilteredViewerLink(selectedLocations) {
      // const keyPair = await generateKey();
      // window.crypto.subtle
      //   .exportKey("jwk", keyPair.publicKey)
      //   .then((publicKey) => {
      //     const base64PublicKey = btoa(JSON.stringify(publicKey))
      //       .replace(/\+/g, "-")
      //       .replace(/\//g, "_")
      //       .replace(/=+$/, "");
      //     setPublicKey(base64PublicKey);
      //   });
      // window.crypto.subtle
      //   .exportKey("jwk", keyPair.privateKey)
      //   .then((privateKey) => {
      //     const base64PrivateKey = btoa(JSON.stringify(privateKey))
      //       .replace(/\+/g, "-")
      //       .replace(/\//g, "_")
      //       .replace(/=+$/, "");
      //     setPrivateKey(base64PrivateKey);
      //   });
      console.log("PKK", PKK);
      const data = selectedLocations.join(",");
      console.log("data", data);
      const signed = await sign(data);
      console.log("signature", signed);
      const url = `${window.location.origin}/viewer/${event.rtoken}?flt=${signed.data}&sign=${signed.signature}`;
      setViewerLink(url);
    }
    generateFilteredViewerLink(selectedLocations);
  }, [selectedLocations, event?.rtoken]);

  return (
    <>
      <Row>
        <Col>
          <h1>{trans("reports.reports")}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to="loads" className={styles.ReportCard}>
            <FontAwesomeIcon icon={faCashRegister} className={styles.Icon} />
            <h2>{trans("reports.loads")}</h2>
            <p>{trans("reports.loads_subtitle")}</p>
          </Link>
        </Col>
        <Col>
          <Link to="sales" className={styles.ReportCard}>
            <FontAwesomeIcon icon={faStore} className={styles.Icon} />
            <h2>{trans("reports.sales")}</h2>
            <p>{trans("reports.sales_subtitle")}</p>
          </Link>
        </Col>
        {/* <Col>
          <Link to="stock" className={styles.ReportCard}>
            <FontAwesomeIcon icon={faChartSimple} className={styles.Icon} />
            <h2>{trans("reports.stock")}</h2>
            <p>{trans("reports.stock_subtitle")}</p>
          </Link>
        </Col> */}
        <Col>
          <Link to="txs" className={styles.ReportCard}>
            <FontAwesomeIcon
              icon={faArrowRightArrowLeft}
              className={styles.Icon}
            />
            <h2>{trans("reports.transactions")}</h2>
            <p>{trans("reports.transactions_subtitle")}</p>
          </Link>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={4}>
          <h2>{trans("reports.generate_report_links")}</h2>
          <Form.Group>
            <Form.Label>{trans("reports.filters")}</Form.Label>
            <MultiSelectDropdown
              value={selectedLocations}
              options={[
                ...(locations || [])?.map((location) => ({
                  key: location.locationid,
                  label: location.name,
                })),
              ]}
              onChange={(selected) => setSelectedLocations([...selected])}
              style={{ width: "100%" }}
              containerStyle={{ gap: 5, width: "100%", padding: 0, paddingBottom: 10 }}
              badgeStyle={{ fontSize: 14 }}
            />
          </Form.Group>
          {event?.rtoken && (
            <Button onClick={handleCopyViewerLink}>
              <FontAwesomeIcon icon={faCopy} className="me-2" />
              {trans("reports.copy_viewer_link")}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

// async function generateKey() {
//   // 1. Generate a key pair
//   const keyPair = await window.crypto.subtle.generateKey(
//     {
//       name: "ECDSA",
//       namedCurve: "P-256", // Curve to use
//     },
//     true, // Whether the key is extractable (i.e. can be used outside of Web Crypto API)
//     ["sign", "verify"] // Can be used for signing and verifying
//   );
//   return keyPair;
// }

async function sign(data) {
  const encodedData = new TextEncoder().encode(data);

  // Decode and import the private key
  const privateKeyJwk = JSON.parse(
    atob(PKK.replace(/-/g, "+").replace(/_/g, "/"))
  );
  const privateKey = await window.crypto.subtle.importKey(
    "jwk",
    privateKeyJwk,
    { name: "ECDSA", namedCurve: "P-256" },
    true,
    ["sign"]
  );
  // 2. Sign the data with the private key
  const signature = await window.crypto.subtle.sign(
    {
      name: "ECDSA",
      hash: { name: "SHA-256" },
    },
    privateKey, // Use the private key for signing
    encodedData // The data you want to sign
  );
  // Export public key
  // const publicKeyJwk = await window.crypto.subtle.exportKey(
  //   "jwk",
  //   keyPair.publicKey
  // );

  // Encode the signature and public key as Base64URL for URL-safe transmission
  const base64Signature = btoa(
    String.fromCharCode(...new Uint8Array(signature))
  )
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  // const base64PublicKey = btoa(JSON.stringify(publicKeyJwk))
  //   .replace(/\+/g, "-")
  //   .replace(/\//g, "_")
  //   .replace(/=+$/, "");
  return { data, signature: base64Signature /*, publicKey: base64PublicKey */ };
}
