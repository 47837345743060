import { useMutation } from "@tanstack/react-query";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { post } from "../utils/Api";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
    }
    setReady(true);
  }, []);

  const loginMutation = useMutation({
    mutationFn: ({ email, password }) =>
      post("/logincashless", { email, password }),
    onSuccess: (data) => {
      console.log("login cashless data", data);
      if (data?.token) {
        if (!data?.orgid) {
          data.orgid = 1;
        }
        storeUser(data);
        return data;
      }
      throw new Error(data?.message ? data.message : "Error al iniciar sesión");
    },
  });

  const storeUser = useCallback((user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("user");
    setUser(null);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ready,
        user,
        login: loginMutation.mutate,
        logout,
        ...loginMutation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
