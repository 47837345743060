import { Col, Container, Row } from "react-bootstrap";
import { useAuth } from "../hooks/useAuth";
import FastForm from "../components/FastForm/FastForm";
import { usePage } from "../hooks/usePage";
import useLang from "../lang/useLang";

export default function Login() {
  usePage({ title: "Ingresar" });
  const { trans } = useLang();
  const loginMutation = useAuth();

  return (
    <Container className="mt-4">
      <Row>
        <Col xs={{ cols: 8, offset: 1 }} md={{ cols: 4, offset: 4 }}>
          <h1>{trans("login.login")}</h1>
          <FastForm
            mutation={loginMutation}
            sendText={trans("login.login")}
            fields={[
              {
                name: "email",
                label: trans("login.email"),
                placeholder: trans("login.email"),
                type: "email",
                attributes: { required: true },
              },
              {
                name: "password",
                label: trans("login.password"),
                placeholder: trans("login.password"),
                type: "password",
                attributes: { required: true },
              },
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
}
