export const RIGHT_NAMES = {
  0: "All",
  1: "Manager",
  2: "Top-Up",
  3: "Sales",
  4: "Sales Manager",
};

export const ENTITY_NAMES = {
  1: "Organización",
  2: "Evento",
};

export const PRODUCT_STATUS = {
  0: "Inactivo",
  1: "Activo",
};

export const TX_TYPES = {
  init: { value: "init", label: "Inicialización" },
  sale: { value: "sale", label: "Venta" },
  topUp: { value: "top-up", label: "Carga" },
  repair: { value: "repair", label: "Reparación" },
  return: { value: "return", label: "Devolución" },
  refund: { value: "refund", label: "Reintegro" },
  wipe: { value: "wipe", label: "Limpieza" },
};

export const CHART_COLORS = [
  // generate 20 random pastel colors
  "#3182bd",
  "#6baed6",
  "#9ecae1",
  "#c6dbef",
  "#e6550d",
  "#fd8d3c",
  "#fdae6b",
  "#fdd0a2",
  "#31a354",
  "#74c476",
  "#a1d99b",
  "#c7e9c0",
  "#756bb1",
  "#9e9ac8",
  "#bcbddc",
  "#dadaeb",
  "#636363",
  "#969696",
  "#bdbdbd",
  "#d9d9d9",
];
