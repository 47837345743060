import axios from "axios";

const Api = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:3009/entraste-cashless/"
      : "https://api.entraste.com/cashless/V1/",
});

export async function post(method, data) {
  return new Promise((resolve, reject) => {
    Api.post(method, data)
      .then((result) => {
        console.log("post result", result);
        resolve(
          result?.data?.data
            ? result?.data?.data
            : result?.data
            ? result.data
            : result
        );
      })
      .catch((error) => {
        console.log("post error", error);
        if (error.response?.data) return reject(error.response.data);
        reject(error);
      });
  });
}

export async function get(method, query) {
  let url = method;
  if (query) {
    const queryString = new URLSearchParams(query).toString();
    url = `${method}?${queryString}`;
  }
  const result = await Api.get(url);
  if (result?.data) return result.data;
  return result;
}

export const imageUploader = {
  upload: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const formData = new FormData();
        formData.append("file", file);
        Api.post("uploadImage", formData)
          .then((result) => {
            resolve(result?.data?.url);
          })
          .catch((error) => {
            console.log("upload error", error);
            reject(error);
          });
      };
      reader.readAsDataURL(file);
    });
  },
};
