import { useParams } from "react-router-dom";
import useProducts from "../../../hooks/useProducts";
import { useMemo, useState } from "react";
import FastTable from "../../../components/FastTable/FastTable";
import FastForm from "../../../components/FastForm/FastForm";
import useLocations from "../../../hooks/useLocations";

export default function CreateDisplayForm() {
  const [selectedDisplayid, setSelectedDisplay] = useState(null);
  const params = useParams();
  const { products, displays, createDisplayMutation } = useProducts({
    productdisplayid: selectedDisplayid,
  });
  const { locations } = useLocations({ eventid: params.eventid });

  const countedDisplays = useMemo(
    () =>
      displays?.map((category) => ({
        ...category,
        products: products?.filter(
          (product) => product.productdisplayid === category.productdisplayid
        ).length,
      })),
    [displays, products]
  );

  return (
    <>
      <h2>Crear display</h2>
      <FastForm
        mutation={createDisplayMutation}
        fields={[
          {
            name: "eventid",
            type: "hidden",
            defaultValue: parseInt(params.eventid),
            attributes: { readOnly: true },
          },
          {
            name: "name",
            label: "Nombre",
            placeholder: "Nombre del display",
          },
          {
            name: "color",
            label: "Color",
            placeholder: "Color",
            defaultValue: "#ffffff",
            type: "color",
          },
          {
            name: "displayorder",
            label: "Orden",
            placeholder: "Orden",
            defaultValue: 0,
            type: "number",
            attributes: { min: 0, step: 1 },
          },
          {
            name: "columns",
            label: "Columnas",
            placeholder: "Columnas",
            defaultValue: 3,
            type: "number",
            attributes: { min: 1, max: 5, step: 1 },
          },
          {
            name: "locationids",
            label: "Ubicaciones",
            placeholder: "Ubicaciones",
            type: "multi-select",
            defaultValue: [],
            options:
              locations?.map((location) => ({
                key: location.locationid,
                label: location.name,
              })) || [],
          },
        ]}
        sendText="Crear display"
        clearOnSuccess={true}
      />
    </>
  );
}
