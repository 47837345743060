import { Col, Row, Spinner } from "react-bootstrap";
import useProducts from "../../hooks/useProducts";
import { useMemo, useState } from "react";
import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import { useNavigate, useParams } from "react-router-dom";
import PillToggler from "../../components/PillToggler";
import styles from "./Products.module.scss";
import CreateProductForm from "../../components/CreateProductForm";
import { usePage } from "../../hooks/usePage";
import useCurrencies from "../../hooks/useCurrencies";

export default function Products() {
  usePage({ title: "Productos" });
  const params = useParams();
  const productid = !isNaN(params?.productid)
    ? parseInt(params.productid)
    : null;
  const navigate = useNavigate();
  const { productsQuery, categoriesQuery, deleteProductMutation } =
    useProducts();
  const { currencies } = useCurrencies();

  const { data: products } = productsQuery;
  const { data: categories } = categoriesQuery;
  const isLoading = useMemo(
    () => productsQuery.isLoading || categoriesQuery.isLoading,
    [productsQuery, categoriesQuery]
  );

  const [selectedCategory, setSelectedCategory] = useState(null);

  const filteredProducts = useMemo(
    () =>
      products
        ? products.filter(
            (product) =>
              !selectedCategory || product.categoryid === selectedCategory
          )
        : [],
    [products, selectedCategory]
  );

  return (
    <>
      <Row className={styles.Products}>
        <Col md={3}>
          <CreateProductForm />
        </Col>
        <Col md={9}>
          <h1>Productos {productid}</h1>
          <hr className="small" />
          <Spinner animation="border" role="status" hidden={!isLoading} />
          <PillToggler
            options={[
              { value: "", label: "Todos" },
              ...(categories
                ? categories.map((category) => ({
                    value: category.categoryid,
                    label: category.name,
                  }))
                : []),
            ]}
            selected={selectedCategory || ""}
            setSelected={(val) =>
              setSelectedCategory((prev) => (prev === val ? null : val))
            }
          />
          <FastTable
            data={filteredProducts}
            isLoading={isLoading}
            columns={[
              {
                key: "productid",
                label: "ID",
                type: TABLE_TYPES.string,
              },
              {
                key: "categoryid",
                label: "Cat.",
                type: TABLE_TYPES.options,
                options: (categories || []).map((category) => ({
                  value: category.categoryid,
                  label: category.name,
                })),
              },
              {
                key: "name",
                label: "Nombre",
                type: TABLE_TYPES.string,
              },
              { key: "price", label: "Precio", type: "currency", prefix: "$" },
              {
                key: "vprice",
                label: "Variable",
                type: "currency",
                prefix: "$",
              },
              {
                key: "isrefundable",
                label: "Reembols.",
                type: "boolean",
                render: (data) => (data.isrefundable === "1" ? "Sí" : "No"),
              },
              // {
              //   key: "iscomposed",
              //   label: "Comp.",
              //   type: "boolean",
              // },
              // { key: "ispack", label: "Pack.", type: "boolean" },
              {
                key: "accepted_currencies",
                label: "Monedas",
                render: (data) =>
                  data.accepted_currencies
                    .map(
                      (curr) =>
                        (
                          currencies?.find(
                            (_curr) => _curr.currencyid === curr.currencyid
                          ) || { name: "" }
                        ).name
                    )
                    .join(", "),
              },
            ]}
            showFilters={false}
            showGroups={false}
            onSelectRow={(row) =>
              navigate(`/${params?.eventid}/products/${row.productid}`)
            }
            onDeleteMutation={deleteProductMutation}
          />
        </Col>
      </Row>
    </>
  );
}
