import { utils, version, writeFileXLSX } from "xlsx";

export function createExcelFile(data, filename) {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Hoja 1");

  /* calculate columns widths */
  if (data?.length > 0) {
    const maxWidths = Object.keys(data[0]).map(function (columnKey) {
      console.log(10, columnKey, columnKey.length);
      return {
        wch: Math.min(
          30,
          Math.max(
            Math.max(0, columnKey.length),
            data.reduce(function (accWidth2, row) {
              return Math.max(
                accWidth2,
                row[columnKey] ? row[columnKey].toString().length : 0
              );
            }, 0)
          )
        ),
      };
    });
    console.log("max_width", maxWidths);
    worksheet["!cols"] = maxWidths;
  }
  writeFileXLSX(workbook, filename);
}
