import { Button, Col, Nav, Row } from "react-bootstrap";
import styles from "./NavBar.module.scss";
import BrandLogo from "../assets/gopal-brand.svg";
// import BrandLogo from "../assets/entraste-brand.svg";
import { Link, useLocation, useMatch, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWrench,
  faUser,
  faChartLine,
  faHome,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import EventSelector from "./EventSelector";
import { useAuth } from "../hooks/useAuth";
import useLang, { LangSwitcher, T } from "../lang/useLang";
import { useEffect, useState } from "react";
import SideBar from "./SideBar";

export default function NavBar() {
  const { trans } = useLang();
  const params = useParams();
  const { user } = useAuth();
  const orgid = !isNaN(user?.orgid) ? user?.orgid : null;
  const eventid = !isNaN(params?.eventid) ? params?.eventid : null;
  const [collapsed, setCollapsed] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    setCollapsed(true);
  }, [pathname]);

  return (
    <>
      <div
        className={[styles.Overlay, collapsed ? styles.Collapsed : ""].join(
          " "
        )}
        onClick={() => setCollapsed(true)}
      />
      <Row className={`${styles.NavBar}`}>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          xs={6}
          sm={8}
          lg={2}
        >
          <img src={BrandLogo} alt="entraste.com" className={styles.Brand} />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          xs={6}
          sm={4}
          lg={10}
        >
          <Button
            onClick={() => setCollapsed(!collapsed)}
            className={styles.ToggleButton}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
          <Nav
            className={[
              styles.Collapsible,
              collapsed ? styles.Collapsed : "",
            ].join(" ")}
          >
            {orgid && (
              <>
                {eventid && (
                  <>
                    <NavItem
                      as={"a"}
                      to={`/`}
                      icon={faHome}
                      text={trans("nav.events")}
                    />
                    <NavItem
                      as={"a"}
                      to={`/${eventid}`}
                      icon={faWrench}
                      text={trans("nav.settings")}
                    />
                    <NavItem
                      as={"a"}
                      to={`/${eventid}/reports`}
                      icon={faChartLine}
                      text={trans("nav.reports")}
                    />
                  </>
                )}
                <Nav.Item>
                  <EventSelector />
                </Nav.Item>
                <NavItem as={"a"} to={`/profile`} icon={faUser} />
              </>
            )}
            <SideBar inNavbar={true} />
            <LangSwitcher
              name="short"
              buttonStyle={{
                color: "var(--bs-primary)",
                // borderColor: "var(--bs-primary)",
              }}
            />
          </Nav>
        </Col>
      </Row>
    </>
  );
}

export function NavItem({ to, icon, text }) {
  const match = useMatch(to);
  return (
    <Nav.Item>
      <Link as={"a"} to={to} className={`nav-link ${match ? "active" : ""}`}>
        <>
          {icon && <FontAwesomeIcon icon={icon} className="bi me-2" />}
          <span className={styles.ItemText}>{text}</span>
        </>
      </Link>
    </Nav.Item>
  );
}
