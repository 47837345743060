import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./DisplayGrid.module.scss";

export default function DisplayGrid({
  productsindisplay,
  name,
  columns,
  onSelect,
  selected,
}) {
  const colIndex = useMemo(() => Math.floor(12 / columns), [columns]);
  const height = useMemo(() => 100 / columns, [columns]);

  const sorted = useMemo(() => {
    return productsindisplay?.sort((a, b) => a.displayorder - b.displayorder) || [];
  }, [productsindisplay]);

  return (
    <Container>
      <Row>
        <Col>
          <h2>
            Display "{name}" {columns} columnas
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className={styles.Grid}>
            {sorted.map((product) => (
              <Col
                xs={colIndex}
                key={product.cpdid}
                className={[styles.Grid__item].join(" ")}
              >
                <div
                  className={[
                    selected(product)
                      ? styles.Grid__item__selected
                      : "not-selected",
                  ].join(" ")}
                  style={{ backgroundImage: `url(${product.image})` }}
                  onClick={() => onSelect(product)}
                >
                  <div className={styles.Grid__item__overlay}>
                    <span>{product.name}</span>
                    <span>$ {product.price}</span>
                  </div>
                  <div className={styles.Grid__item__badges}>
                    {product.isrefundable ? (
                      <Badge type="refundable" label="Ref" />
                    ) : null}
                    {product.vprice ? (
                      <Badge type="variable" label="Var" />
                    ) : null}
                    {product.iscomposed ? (
                      <Badge type="composed" label="Comp" />
                    ) : null}
                    {product.ispack ? (
                      <Badge type="pack" label="Pack" />
                    ) : null}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export function Badge({ type, label }) {
  return (
    <div
      className={[
        styles.Grid__item__badge,
        styles[`Grid__item__badge__${type}`],
      ].join(" ")}
    >
      {label}
    </div>
  );
}
