import { useQuery, useQueryClient } from "@tanstack/react-query";
import { post } from "../utils/Api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TX_TYPES } from "../constants";

export const PUB =
  "eyJjcnYiOiJQLTI1NiIsImV4dCI6dHJ1ZSwia2V5X29wcyI6WyJ2ZXJpZnkiXSwia3R5IjoiRUMiLCJ4IjoiRzltODVpUWl6bDdCNHdZdEhHRnB0WlRYSV9Fa0JtMU11LUV1bnRnT0VxUSIsInkiOiIwZi11RThOVlRtN2Nqak5SZlA0ZV82dXRBM0Z1QjN0ZXRaT1JlTzFmN2o4In0";

export default function usePublicReports({
  rtoken,
  limit,
  offset,
  start,
  end,
}) {
  const [validData, setValidData] = useState(null);
  const [pin, setPin] = useState(null);

  useEffect(() => {
    if (pin === null) {
      // check local storage for stored pin
      const storedPin = localStorage.getItem("pin");
      if (storedPin) {
        setPin(storedPin);
      } else {
        setPin(0);
      }
    }
  }, [pin]);

  useEffect(() => {
    console.log(
      "useEffect in usePublicReports: ",
      JSON.stringify({ rtoken, limit, offset, start, end })
    );
  }, [rtoken, limit, offset, start, end]);

  const query = useQuery({
    queryKey: ["reportpublic", rtoken, pin, start, end],
    queryFn: async () =>
      post("/reportpublic", {
        rtoken: rtoken,
        pin: parseInt(pin),
        limit,
        offset,
        start,
        end: end || Math.floor(new Date().getTime() / 1000),
      }),
    enabled:
      !!rtoken && !!start && start !== "" && validData !== null && pin !== null,
  });

  const dataQuery = useQuery({
    queryKey: ["public", rtoken, pin],
    queryFn: async () =>
      post("/public", {
        rtoken: rtoken,
        pin: parseInt(pin),
      }),
    enabled: !!rtoken && pin !== null,
    onSuccess: (data) => {
      console.log("public fetch onSuccess data", data);
    },
  });

  // console.log("dataQuery?.data", dataQuery?.data);

  const [devices] = useState([]);

  const {
    event,
    currencies,
    paymentmethods,
    locations,
    users,
    products: productDisplays,
  } = useMemo(
    () =>
      dataQuery?.data || {
        event: {},
        currencies: [],
        paymentmethods: [],
        locations: [],
        products: [],
        users: [],
      },
    [dataQuery.data]
  );

  const products = useMemo(() => {
    return productDisplays
      .map((displayOrProduct) =>
        Array.isArray(displayOrProduct.products)
          ? displayOrProduct.products
          : [displayOrProduct]
      )
      .flat();
  }, [productDisplays]);

  const parsedData = useMemo(() => {
    const allTxs =
      query.data
        ?.filter((tx) => {
          if (validData) {
            if (validData.length > 0) {
              return validData.includes(parseInt(tx?.locationid));
            }
            return true;
          }
          return false;
        })
        .map((tx) => ({
          ...tx,
          deviceName: devices?.find(
            (device) => device.deviceshorttag === tx.deviceshorttag
          )?.name,
          userName: users?.find((user) => user.userid === tx.userid)?.name,
        })) || [];
    return allTxs;
  }, [query.data, devices, users, validData]);

  const queryClient = useQueryClient();

  const refetch = useCallback(() => {
    queryClient.invalidateQueries(["report"]);
  }, [queryClient]);

  useEffect(() => {
    if (rtoken) {
      verifySearchParams().then((data) => {
        console.log("isValid", typeof data, data?.length, data);
        if (typeof data === "string") {
          console.log("split", data.split(","));
          setValidData(
            data
              .split(",")
              .filter((d) => d !== "")
              .map((d) => parseInt(d))
          );
        } else {
          setValidData(null);
        }
      });
    }
  }, [rtoken]);

  useEffect(() => {
    if (pin !== null) {
      localStorage.setItem("pin", pin);
    }
  }, [pin]);

  return {
    ...query,
    refetch,
    parsedData,
    users,
    devices,
    event,
    currencies,
    paymentmethods,
    locations,
    products,
    validData,
    rtoken,
    pin,
    setPin,
    dataQuery,
    dataIsLoading: dataQuery.isLoading,
    dataStatus: dataQuery.status,
    dataError: dataQuery.error,
  };
}

export function usePublicSummary(props) {
  const reports = usePublicReports(props);
  const {
    parsedData,
    dataUpdatedAt,
    users,
    currencies,
    paymentmethods,
    locations,
    products,
  } = reports;

  const summary = useMemo(() => {
    console.log("updateSummary", dataUpdatedAt);
    return parseSummary(
      parsedData,
      paymentmethods,
      products,
      users,
      locations,
      currencies
    );
  }, [
    parsedData,
    dataUpdatedAt,
    paymentmethods,
    users,
    locations,
    products,
    currencies,
  ]);
  return { ...reports, ...summary, allLocations: locations };
}

export function parseSummary(
  parsedData,
  paymentMethods,
  products,
  users,
  locations,
  currencies
) {
  const summary = {
    totalTopUps: 0,
    totalTopUpsAmount: 0,
    totalTopUpsPayments: {},
    totalSales: 0,
    totalSalesAmount: 0,
    totalRemaining: 0,
    totalInits: 0,
    totalInitAmount: 0,
    cashiers: {},
    salespeople: {},
    locations: {},
    products: {},
    currencies: {},
    noPaymentInfo: 0,
    noPaymentInfoAmount: 0,
  };
  parsedData.forEach((tx) => {
    if (tx?.currencyid) {
      if (typeof summary.currencies[tx.currencyid] === "undefined") {
        const foundCurr = currencies?.find(
          (curr) => curr.currencyid === tx.currencyid
        );
        summary.currencies[tx.currencyid] = {
          currencyid: tx.currencyid,
          name: foundCurr?.name || `#${tx.currencyid}`,
          topUps: 0,
          topUpsAmount: 0,
          refunds: 0,
          refundsAmount: 0,
          wipes: 0,
          wipesAmount: 0,
          sales: 0,
          salesAmount: 0,
          tags: {},
        };
      }
    }
    if (isNaN(parseFloat(tx.amount))) {
    }
    const txAmount = parseFloat(tx.amount);
    if (
      tx.type === TX_TYPES.topUp.value ||
      tx.type === TX_TYPES.init.value ||
      tx.type === TX_TYPES.refund.value //  ||
      // tx.type === TX_TYPES.wipe.value
      // ||
      // tx.type === TX_TYPES.repair.value
    ) {
      summary.totalTopUps += 1;
      if (tx?.paymentmethodid === "0") {
        tx.paymentmethodid = tx.type;
      }
      if (tx?.paymentmethodid) {
        if (
          typeof summary.totalTopUpsPayments[tx.paymentmethodid] === "undefined"
        ) {
          const foundPayment = paymentMethods?.find(
            (payment) =>
              parseInt(payment.paymentmethodid) === parseInt(tx.paymentmethodid)
          );
          summary.totalTopUpsPayments[tx.paymentmethodid] = {
            paymentmethodid: tx.paymentmethodid,
            name:
              foundPayment?.name ||
              Object.values(TX_TYPES).find((type) => type.value === tx.type)
                .label,
            amount: 0,
            count: 0,
            currencies: {},
          };
        }
        summary.totalTopUpsPayments[tx.paymentmethodid].count += 1;
        summary.totalTopUpsPayments[tx.paymentmethodid].amount += txAmount;
        summary.totalTopUpsAmount += txAmount;
      } else {
        summary.noPaymentInfo += 1;
        summary.noPaymentInfoAmount += txAmount;
      }
      if (tx?.currencyid) {
        if (
          tx.type === TX_TYPES.topUp.value ||
          tx.type === TX_TYPES.init.value
        ) {
          summary.currencies[tx.currencyid].topUps += txAmount > 0 ? 1 : -1;
          summary.currencies[tx.currencyid].topUpsAmount += txAmount;
        } else if (tx.type === TX_TYPES.refund.value) {
          summary.currencies[tx.currencyid].refunds += 1;
          summary.currencies[tx.currencyid].refundsAmount += txAmount;
          // } else if (tx.type === TX_TYPES.wipe.value) {
          //   summary.currencies[tx.currencyid].wipes += 1;
          //  summary.currencies[tx.currencyid].wipesAmount += txAmount;
        }
        if (
          typeof summary.totalTopUpsPayments[tx.paymentmethodid].currencies[
            tx.currencyid
          ] === "undefined"
        ) {
          summary.totalTopUpsPayments[tx.paymentmethodid].currencies[
            tx.currencyid
          ] = {
            currencyid: tx.currencyid,
            name: currencies?.find(
              (currency) => currency.currencyid === tx.currencyid
            )?.name,
            amount: 0,
            count: 0,
          };
        }
        summary.totalTopUpsPayments[tx.paymentmethodid].currencies[
          tx.currencyid
        ].count += 1;
        summary.totalTopUpsPayments[tx.paymentmethodid].currencies[
          tx.currencyid
        ].amount += txAmount;
      } else {
        summary.noPaymentInfo += 1;
        summary.noPaymentInfoAmount += txAmount;
      }
    }
    if (tx.type === TX_TYPES.init.value) {
      summary.totalInits += 1;
      summary.totalInitAmount += txAmount;
      if (tx?.paymentmethodid) {
        if (
          typeof summary.totalTopUpsPayments[tx.paymentmethodid] === "undefined"
        ) {
          const foundPayment = paymentMethods?.find(
            (payment) =>
              parseInt(payment.paymentmethodid) === parseInt(tx.paymentmethodid)
          );
          summary.totalTopUpsPayments[tx.paymentmethodid] = {
            paymentmethodid: tx.paymentmethodid,
            name: foundPayment?.name || `#${tx.paymentmethodid}`,
            amount: 0,
          };
        }
        // summary.totalTopUpsPayments[tx.paymentmethodid].amount += txAmount;
      }
      if (tx?.currencyid) {
        // summary.currencies[tx.currencyid].topUpsAmount += txAmount;
      }
    }
    if (
      tx.type === TX_TYPES.topUp.value ||
      tx.type === TX_TYPES.init.value ||
      tx.type === TX_TYPES.refund.value /* ||
      tx.type === TX_TYPES.wipe.value */
    ) {
      if (tx?.userid) {
        if (typeof summary.cashiers[tx.userid] === "undefined") {
          const foundUser = users?.find(
            (user) => parseInt(user.userid) === parseInt(tx.userid)
          );
          summary.cashiers[tx.userid] = {
            userid: tx.userid,
            name: foundUser?.name ? foundUser.name : `#${tx.userid}`,
            amount: 0,
          };
        }
        summary.cashiers[tx.userid].amount += txAmount;
        if (
          tx.type === TX_TYPES.topUp.value ||
          tx.type === TX_TYPES.init.value
        ) {
          summary.cashiers[tx.userid].topUps += txAmount;
        } else {
          summary.cashiers[tx.userid].refunds += txAmount;
        }
      }
    }
    if (tx.type === TX_TYPES.sale.value || tx.type === TX_TYPES.return.value) {
      summary.totalSales += txAmount > 0 ? 1 : -1;
      summary.totalSalesAmount += txAmount;
      if (tx?.currencyid) {
        summary.currencies[tx.currencyid].sales += txAmount > 0 ? 1 : -1;
        summary.currencies[tx.currencyid].salesAmount += txAmount;
      }
      if (tx?.locationid) {
        if (typeof summary.locations[tx.locationid] === "undefined") {
          const foundLocation = locations?.find(
            (location) =>
              parseInt(location.locationid) === parseInt(tx.locationid)
          );
          summary.locations[tx.locationid] = {
            locationid: tx.locationid,
            name: foundLocation?.name || `#${tx.locationid}`,
            amount: 0,
          };
        }
        summary.locations[tx.locationid].amount += parseFloat(tx.amount);
      }
      if (tx?.items) {
        try {
          const parsedString = tx?.items
            ? tx?.items
            : // .replaceAll("\productid", '"productid"')
              // .replaceAll("qty", '"qty"')
              // .replaceAll("price", '"price"')
              // .replaceAll("total", '"total"')
              "";
          const items = JSON.parse(parsedString);
          items.forEach((item) => {
            if (typeof summary.products[item.productid] === "undefined") {
              summary.products[item.productid] = {
                productid: item.productid,
                name:
                  products?.find(
                    (product) => product.productid === item.productid
                  )?.name || `#${item.productid}`,
                qty: 0,
                qty_return: 0,
                qty_net: 0,
                amount: 0,
              };
            }
            summary.products[item.productid].qty +=
              txAmount < 0 ? 0 : parseInt(item.qty);
            summary.products[item.productid].qty_return +=
              txAmount < 0 ? parseInt(item.qty) : 0;
            summary.products[item.productid].qty_net +=
              txAmount < 0 ? -parseInt(item.qty) : parseInt(item.qty);
            summary.products[item.productid].amount +=
              (txAmount > 0 ? 1 : -1) *
              parseFloat(item.price) *
              parseInt(item.qty);
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
    if (tx.type === TX_TYPES.sale.value || tx.type === TX_TYPES.return.value) {
      if (tx?.userid) {
        if (typeof summary.salespeople[tx.userid] === "undefined") {
          const foundUser = users?.find(
            (user) => parseInt(user.userid) === parseInt(tx.userid)
          );
          summary.salespeople[tx.userid] = {
            userid: tx.userid,
            name: foundUser?.name ? foundUser.name : `#${tx.userid}`,
            amount: 0,
          };
        }
        summary.salespeople[tx.userid].amount += parseFloat(tx.amount);
      }
    }

    if (tx?.tag) {
      if (
        typeof summary.currencies[tx.currencyid].tags[tx.tag] === "undefined"
      ) {
        summary.currencies[tx.currencyid].tags[tx.tag] = {
          tag: tx.tag,
          name: tx.tag,
          topUps: 0,
          topUpsAmount: 0,
          refunds: 0,
          refundsAmount: 0,
          wipes: 0,
          wipesAmount: 0,
          sales: 0,
          salesAmount: 0,
        };
      }
      if (
        tx?.type === TX_TYPES.sale.value ||
        tx?.type === TX_TYPES.return.value
      ) {
        summary.currencies[tx.currencyid].tags[tx.tag].sales +=
          parseFloat(tx.amount) > 0 ? 1 : -1;
        summary.currencies[tx.currencyid].tags[tx.tag].salesAmount +=
          parseFloat(tx.amount);
      } else if (
        tx?.type === TX_TYPES.topUp.value ||
        tx?.type === TX_TYPES.init.value
      ) {
        summary.currencies[tx.currencyid].tags[tx.tag].topUps +=
          parseFloat(tx.amount) > 0 ? 1 : -1;
        summary.currencies[tx.currencyid].tags[tx.tag].topUpsAmount +=
          parseFloat(tx.amount);
      } else if (tx.type === TX_TYPES.refund.value) {
        summary.currencies[tx.currencyid].tags[tx.tag].refunds += 1;
        summary.currencies[tx.currencyid].tags[tx.tag].refundsAmount +=
          parseFloat(tx.amount);
      } else if (tx.type === TX_TYPES.wipe.value) {
        summary.currencies[tx.currencyid].tags[tx.tag].wipes += 1;
        summary.currencies[tx.currencyid].tags[tx.tag].wipesAmount +=
          parseFloat(tx.amount);
      }
    }
  });
  return summary;
}

async function verifySearchParams() {
  try {
    // Extract URL parameters
    const params = new URLSearchParams(window.location.search);
    const data = params.get("flt");
    const base64Signature = params.get("sign");

    // Decode data for verification
    const encodedData = new TextEncoder().encode(data);
    const signature = Uint8Array.from(
      atob(base64Signature.replace(/-/g, "+").replace(/_/g, "/")),
      (c) => c.charCodeAt(0)
    );

    // Decode and import the public key
    const publicKeyJwk = JSON.parse(
      atob(PUB.replace(/-/g, "+").replace(/_/g, "/"))
    );
    const publicKey = await window.crypto.subtle.importKey(
      "jwk",
      publicKeyJwk,
      { name: "ECDSA", namedCurve: "P-256" },
      true,
      ["verify"]
    );

    // Verify the signature
    const isValid = await window.crypto.subtle.verify(
      {
        name: "ECDSA",
        hash: { name: "SHA-256" },
      },
      publicKey,
      signature,
      encodedData
    );

    if (isValid) {
      return data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
}
