import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get, post } from "../utils/Api";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { useAuth } from "./useAuth";

export default function useCurrencies() {
  const { eventid } = useParams();
  const { user } = useAuth();
  const orgid = user?.orgid;
  const queryClient = useQueryClient();
  const currenciesQuery = useQuery({
    queryKey: ["currencies", eventid],
    queryFn: () =>
      post("currencies", {
        token: user?.token,
        orgid: parseInt(orgid),
        eventid: parseInt(eventid),
      }).then((data) => {
        console.log("currencies data", data);
        return data;
      }),
    enabled: !!eventid && !!user?.token,
  });

  const { currencies, currencyGroups, paymentMethods, currenciesGrouped } =
    useMemo(() => {
      console.log("currenciesQuery.data", currenciesQuery.data);
      if (!currenciesQuery.data) {
        return {
          currencies: [],
          currencyGroups: [],
          paymentMethods: [],
          currenciesGrouped: {},
        };
      }
      const filteredCurrencies = currenciesQuery.data.currencies.data.filter(
        (currency) =>
          parseInt(currency.eventid) === parseInt(eventid) ||
          (parseInt(eventid) <= 16 && currency.eventid === "0")
      );
      const filteredPaymentMethods =
        currenciesQuery.data.paymentmethod.data.filter((paymentMethod) =>
          filteredCurrencies.find(
            (currency) =>
              parseInt(paymentMethod.currencyid) ===
              parseInt(currency.currencyid)
          )
        );
      const currenciesGrouped = filteredCurrencies.reduce((acc, currency) => {
        if (!acc[currency.currencygroupid]) {
          acc[currency.currencygroupid] = {
            ...currenciesQuery.data.currenciesgroup.data.find(
              (cg) => cg.currencygroupid === currency.currencygroupid
            ),
            currencies: [],
          };
        }
        acc[currency.currencygroupid].currencies.push(currency);
        return acc;
      }, {});
      return {
        currencies: filteredCurrencies,
        currencyGroups: currenciesQuery.data.currenciesgroup.data,
        paymentMethods: filteredPaymentMethods,
        currenciesGrouped,
      };
    }, [currenciesQuery.data, eventid]);

  const editCurrencyMutation = useMutation({
    mutationFn: (currency) =>
      post("editcurrency", {
        ...currency,
        eventid: parseInt(eventid),
        orgid: parseInt(orgid),
        token: user?.token,
      }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const editCurrencyMutationMultiple = useMutation({
    mutationFn: (currencies) =>
      Promise.all(
        currencies.map((currency, index) => {
          console.log(
            "edit single currency",
            index + 1 + "/" + currencies.length,
            currency
          );
          return post("editcurrency", {
            ...currency,
            eventid: parseInt(eventid),
            orgid: parseInt(orgid),
            token: user?.token,
          });
        })
      ),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const createCurrencyMutation = useMutation({
    mutationFn: (currency) =>
      post("createcurrency", {
        name: currency.name,
        priority: parseInt(currency.priority),
        min: parseInt(currency.min),
        max: parseInt(currency.max),
        eventid: parseInt(eventid),
        orgid: parseInt(orgid),
        token: user?.token,
        currencygroupid: parseInt(currency.currencygroupid),
      }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const deleteCurrencyMutation = useMutation({
    mutationFn: (currency) =>
      post("deletecurrency", {
        ...currency,
        eventid: parseInt(eventid),
        orgid: parseInt(orgid),
        token: user?.token,
      }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const editCurrencyGroupMutation = useMutation({
    mutationFn: (currencyGroup) =>
      post("editcurrencygroup", {
        ...currencyGroup,
        eventid: parseInt(eventid),
        orgid: parseInt(orgid),
        token: user?.token,
      }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const createCurrencyGroupMutation = useMutation({
    mutationFn: (currencyGroup) =>
      post("createcurrencygroup", { token: user?.token, ...currencyGroup }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const deleteCurrencyGroupMutation = useMutation({
    mutationFn: (currencyGroup) =>
      post("deletecurrencygroup", {
        ...currencyGroup,
        eventid: parseInt(eventid),
        orgid: parseInt(orgid),
        token: user?.token,
      }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const editPaymentMethodMutation = useMutation({
    mutationFn: (paymentMethod) =>
      post("editpaymentmethod", {
        ...paymentMethod,
        eventid: parseInt(eventid),
        orgid: parseInt(orgid),
        token: user?.token,
      }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const createPaymentMethodMutation = useMutation({
    mutationFn: (paymentMethod) =>
      post("createpaymentmethod", {
        ...paymentMethod,
        eventid: parseInt(eventid),
        orgid: parseInt(orgid),
        token: user?.token,
      }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  const deletePaymentMethodMutation = useMutation({
    mutationFn: (paymentMethod) =>
      post("deletepaymentmethod", {
        ...paymentMethod,
        eventid: parseInt(eventid),
        orgid: parseInt(orgid),
        token: user?.token,
      }),
    onSettled: () => {
      queryClient.invalidateQueries("currencies");
    },
  });

  return {
    ...currenciesQuery,
    currencies,
    currencyGroups,
    currenciesGrouped,
    paymentMethods,
    editCurrencyMutation,
    editCurrency: editCurrencyMutation.mutate,
    editCurrencyMutationMultiple,
    editMultipleCurrencies: editCurrencyMutationMultiple.mutate,
    createCurrencyMutation,
    createCurrency: createCurrencyMutation.mutate,
    deleteCurrencyMutation,
    deleteCurrency: deleteCurrencyMutation.mutate,
    editCurrencyGroupMutation,
    editCurrencyGroup: editCurrencyGroupMutation.mutate,
    createCurrencyGroupMutation,
    createCurrencyGroup: createCurrencyGroupMutation.mutate,
    deleteCurrencyGroupMutation,
    deleteCurrencyGroup: deleteCurrencyGroupMutation.mutate,
    editPaymentMethodMutation,
    editPaymentMethod: editPaymentMethodMutation.mutate,
    createPaymentMethodMutation,
    createPaymentMethod: createPaymentMethodMutation.mutate,
    deletePaymentMethodMutation,
    deletePaymentMethod: deletePaymentMethodMutation.mutate,
  };
}
