import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import useEvents from "../../hooks/useEvents";
import FastForm from "../../components/FastForm/FastForm";
import { getUnixTime, parse } from "date-fns";
import { inputDate } from "../../utils/date";
import useCurrencies from "../../hooks/useCurrencies";
import { usePage } from "../../hooks/usePage";
import { Fragment, useMemo } from "react";

export default function Event() {
  const { user } = useAuth();
  const { isLoading, event } = useEvents();
  const { eventid } = useParams();
  const isEventHome = useMatch("/:eventid") && !isNaN(eventid);
  const { currencies, isLoading: isLoadingCurrencies } = useCurrencies();
  usePage({ title: event?.name ? event?.name : "Evento ¿?" });

  const isReady = useMemo(
    () => !!user?.token && !!event?.name && !isLoadingCurrencies && !isLoading,
    [user, event, isLoadingCurrencies, isLoading]
  );

  return (
    <Fragment key={eventid}>
      {isEventHome && isReady && (
        <Container fluid>
          <Row>
            <Col>
              <h1>{event?.name}</h1>
              <hr className="small" />
              <FastForm
                key={eventid}
                endpoint="editevent"
                sendText="Guardar cambios"
                fields={[
                  {
                    name: "token",
                    type: "hidden",
                    defaultValue: user?.token,
                  },
                  {
                    name: "orgid",
                    type: "hidden",
                    defaultValue: parseInt(event?.orgid),
                  },
                  {
                    name: "eventid",
                    type: "hidden",
                    defaultValue: parseInt(event?.eventid),
                  },
                  {
                    name: "name",
                    label: "Nombre",
                    placeholder: "Nombre",
                    type: "text",
                    defaultValue: event?.name,
                    attributes: { required: true },
                  },
                  {
                    name: "start",
                    label: "Inicio",
                    placeholder: "Inicio",
                    type: "datetime-local",
                    defaultValue: event?.start ? inputDate(event?.start) : "",
                    attributes: { required: true },
                  },
                  {
                    name: "end",
                    label: "Fin",
                    placeholder: "Fin",
                    type: "datetime-local",
                    defaultValue: event?.end ? inputDate(event?.end) : "",
                    attributes: { required: true },
                  },
                  {
                    name: "activationfee",
                    label: "Costo de activación",
                    placeholder: "Costo de activación",
                    type: "number",
                    defaultValue: event?.activationfee,
                    attributes: { min: 0 },
                    prefix: "$",
                  },
                  {
                    name: "feecurrency",
                    label: "Moneda de depósito",
                    placeholder: "Moneda",
                    type: "select",
                    defaultValue: event?.feecurrency,
                    options: [
                      { value: "0", label: "Elija la moneda de depósito" },
                      ...currencies.map((currency) => ({
                        value: currency.currencyid,
                        label: currency.name,
                      })),
                    ],
                    attributes: {
                      required: true,
                    },
                  },
                  {
                    name: "pin",
                    label: "Pin de visualización de reportes",
                    placeholder: "Pin de visualización de reportes",
                    type: "hidden",
                    defaultValue: ("0000" + (event?.pin || 0)).slice(-4),
                    attributes: {
                      style: { textAlign: "center" },
                      onChange: (e) => {
                        e.target.value = (
                          "0000" + e.target.value.replace(/\D/g, "")
                        ).slice(-4);
                      },
                    },
                  },
                ]}
                parseData={(data) => {
                  console.log("data: ", JSON.stringify(data, null, " "));
                  let parseStart = parseAnyLocalDateFormat(data.start);
                  let parseEnd = parseAnyLocalDateFormat(data.end);
                  console.log("parsed dates: ", parseStart, parseEnd);
                  const parsedData = {
                    ...data,
                    start: getUnixTime(parseStart),
                    end: getUnixTime(parseEnd),
                  };
                  console.log(
                    "parsedData: ",
                    JSON.stringify(parsedData, null, " ")
                  );
                  parsedData.pin = parseInt(parsedData.pin);
                  return parsedData;
                }}
                postParseData={(data) => ({
                  ...data,
                  pin: parseInt(data?.pin ? ("0000" + data.pin).slice(-4) : 0),
                })}
              />
            </Col>
          </Row>
        </Container>
      )}
      {!isReady ? <Spinner animation="border" /> : <Outlet />}
    </Fragment>
  );
}

const DATE_STRINGS_REGEXS = [
  {
    format: "yyyy-MM-dd'T'HH:mm:ss",
    regex: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/,
  },
  {
    format: "yyyy-MM-dd'T'HH:mm",
    regex: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})$/,
  },
  { format: "yyyy-MM-dd", regex: /^(\d{4})-(\d{2})-(\d{2})$/ },
  { format: "dd/MM/yyyy", regex: /^(\d{2})\/(\d{2})\/(\d{4})$/ },
];
function parseAnyLocalDateFormat(dateString) {
  for (let { format, regex } of DATE_STRINGS_REGEXS) {
    if (regex.test(dateString)) {
      return parse(dateString, format, new Date());
    }
  }
  return new Date();
}
