import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightArrowLeft,
  faCartShopping,
  faCashRegister,
  faChartLine,
  faChartPie,
  faChartSimple,
  faChevronDown,
  faChevronUp,
  faHouse,
  faMobile,
  faMoneyBill,
  faStore,
  faUserCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Collapse, Nav } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useEvents from "../hooks/useEvents";
import styles from "./SideBar.module.scss";
import useLang from "../lang/useLang";

export default function SideBar({ inNavbar = false }) {
  const { trans } = useLang();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { user, logout } = useAuth();
  const { eventid } = useEvents();

  const { pathname } = useLocation();
  const isReport = useMemo(() => /^\/\d+\/reports/.test(pathname), [pathname]);
  
  const itemGroups = useMemo(() => {
    let result = [];
    if (user?.orgid) {
      if (isReport) {
        result.push({
          title: trans("nav.reports"),
          icon: <FontAwesomeIcon icon={faChartLine} className="bi me-2" />,
          path: `/${eventid}/reports`,
          defaultOpen: true,
          items: [
            {
              text: trans("nav.viewer"),
              path: `/${eventid}/reports/viewer`,
              icon: <FontAwesomeIcon icon={faChartPie} className="bi me-2" />,
            },
            {
              text: trans("nav.loads"),
              path: `/${eventid}/reports/loads`,
              icon: (
                <FontAwesomeIcon icon={faCashRegister} className="bi me-2" />
              ),
            },
            {
              text: trans("nav.sales"),
              path: `/${eventid}/reports/sales`,
              icon: <FontAwesomeIcon icon={faStore} className="bi me-2" />,
            },
            {
              text: trans("nav.transactions"),
              path: `/${eventid}/reports/txs`,
              icon: (
                <FontAwesomeIcon
                  icon={faArrowRightArrowLeft}
                  className="bi me-2"
                />
              ),
            },
            // {
            //   text: trans("nav.stock"),
            //   path: `/${eventid}/reports/stock`,
            //   icon: (
            //     <FontAwesomeIcon icon={faChartSimple} className="bi me-2" />
            //   ),
            // },
          ],
        });
      } else {
        result.push({
          title: trans("nav.general"),
          icon: <FontAwesomeIcon icon={faHouse} className="bi me-2" />,
          path: `/`,
          defaultOpen: true,
          items: [
            { text: trans("nav.events"), path: `/` },
            { text: trans("nav.createevent"), path: `/createevent` },
          ],
        });
        result.push({
          title: trans("nav.devices"),
          icon: <FontAwesomeIcon icon={faMobile} className="bi me-2" />,
          path: `/`,
          defaultOpen: true,
          items: [
            {
              text: trans(eventid ? "nav.devices" : "nav.devicesall"),
              path: eventid ? `/${eventid}/devices` : "/devices",
            },
          ],
        });
        result.push({
          title: trans("nav.locations"),
          icon: <FontAwesomeIcon icon={faCashRegister} className="bi me-2" />,
          path: `/`,
          defaultOpen: true,
          items: [
            { text: trans("nav.locations"), path: `/${eventid}/locations` },
          ],
        });
        if (eventid) {
          result.push({
            title: trans("nav.users"),
            icon: <FontAwesomeIcon icon={faUsers} className="bi me-2" />,
            defaultOpen: true,
            items: [
              {
                text: trans("nav.userlist"),
                path: `/${eventid}/users`,
              },
              {
                text: trans("nav.createuser"),
                path: `/${eventid}/users/create`,
              },
              { text: trans("nav.search"), path: `/${eventid}/users/search` },
              { text: trans("nav.groups"), path: `/${eventid}/users/groups` },
              // {
              //   text: trans("nav.rights"),
              //   path: `/${eventid}/users/rights`,
              // },
            ],
          });
          result.push({
            title: trans("nav.products"),
            icon: <FontAwesomeIcon icon={faCartShopping} className="bi me-2" />,
            defaultOpen: true,
            items: [
              {
                text: trans("nav.products"),
                path: `/${eventid}/products`,
              },
              {
                text: trans("nav.createproduct"),
                path: `/${eventid}/products/create`,
              },
              {
                text: trans("nav.search"),
                path: `/${eventid}/products/search`,
              },
              {
                text: trans("nav.categories"),
                path: `/${eventid}/products/categories`,
              },
              {
                text: trans("nav.display"),
                path: `/${eventid}/products/display`,
              },
            ],
          });
          result.push({
            title: trans("nav.currencies"),
            icon: <FontAwesomeIcon icon={faMoneyBill} className="bi me-2" />,
            defaultOpen: true,
            items: [
              {
                text: trans("nav.currencies"),
                path: `/${eventid}/currencies`,
              },
            ],
          });
        } else {
          result.push({
            title: trans("nav.users"),
            icon: <FontAwesomeIcon icon={faUsers} className="bi me-2" />,
            defaultOpen: true,
            items: [
              {
                text: trans("nav.userlist"),
                path: `/users`,
              },
            ],
          });
        }
      }
      result.push({
        title: (
          <div
            style={{
              color: "var(--bs-primary)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faUserCircle} className="bi me-2" />
            <span className="ms-2">{user?.username}</span>
          </div>
        ),
        defaultOpen: false,
        items: [
          // { text: "Settings", path: "/settings" },
          // { text: "Profile", path: "/profile" },
          { text: trans("nav.logout"), onClick: logout },
        ],
      });
    }
    return result;
  }, [user?.orgid, user?.username, eventid, logout, trans, isReport]);

  return (
    <>
      <div
        className={`${styles.SideBar} ${
          inNavbar ? styles.inNavbar : "not-in-navbar"
        } side-bar d-flex flex-column flex-shrink-0 bg-gray`}
        style={{ width: sidebarCollapsed ? 72 : 200 }}
        onClick={() => setSidebarCollapsed(false)}
      >
        {itemGroups.map((group, index) => (
          <SideBarGroup key={index} {...group} />
        ))}
      </div>
    </>
  );
}

export function SideBarGroup(props) {
  const { title, items, icon, defaultOpen = false } = props;
  const [open, setOpen] = useState(defaultOpen);
  const { pathname } = useLocation();
  const isGroupActive = useMemo(
    () => items?.find((item) => item.path === pathname),
    [pathname, items]
  );

  return (
    <Nav.Item>
      {items ? (
        <>
          <Button
            onClick={() => setOpen(!open)}
            variant={"secondary"}
            className={`w-100 d-flex align-items-center ${
              isGroupActive && "active"
            }`}
          >
            {icon}
            <span className={styles.ItemTitle}>{title}</span>
            {!isGroupActive && (
              <FontAwesomeIcon
                icon={open ? faChevronUp : faChevronDown}
                style={{ marginLeft: "auto", fontSize: "50%" }}
              />
            )}
          </Button>

          <Collapse in={open || isGroupActive}>
            <div>
              <Nav className="nav nav-pills nav-flush flex-column mb-auto text-center">
                {items.map((item, index) => (
                  <SideBarItem key={index} {...item} />
                ))}
              </Nav>
            </div>
          </Collapse>
        </>
      ) : (
        <div>
          <Nav className="nav nav-pills nav-flush flex-column mb-auto text-center">
            <SideBarItem {...props} />
          </Nav>
        </div>
      )}
    </Nav.Item>
  );
}

export function SideBarItem({ text, path, onClick, icon = null }) {
  const { pathname } = useLocation();
  const active = pathname === path;

  const content = (
    <>
      {icon}
      <span className={styles.ItemText}>{text}</span>
    </>
  );

  return (
    <Nav.Item>
      {onClick ? (
        <Nav.Link
          onClick={onClick}
          className={`d-flex align-items-center link-dark rounded`}
        >
          {content}
        </Nav.Link>
      ) : (
        <Nav.Link
          as={Link}
          to={path}
          className={`d-flex align-items-center text-start link-dark rounded ${
            active && " active"
          } justify-content-start`}
        >
          {content}
        </Nav.Link>
      )}
    </Nav.Item>
  );
}
