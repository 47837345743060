import { faSuperpowers } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faShare,
  faSubscript,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import styles from "./PinField.module.scss";

export default function PinField({
  defaultValue = "0000",
  onChange,
  style = {},
}) {
  const [pin, setPin] = useState(("0000" + defaultValue).slice(-4));
  const [active, setActive] = useState(0);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPin((pin) =>
        (
          pin.slice(0, index) +
          (value.replace(/\D/, "") || "0").slice(-1) +
          pin.slice(index + 1)
        ).slice(-4)
      );
      setActive((index + 1) % 4);
    }
  };

  return (
    <div className={styles.PinFieldContainer} style={style}>
      {pin.split("").map((digit, index) => (
        <DigitField
          key={index}
          active={active === index}
          value={digit}
          onChange={(e) => handleChange(e, index)}
        />
      ))}
      <Button onClick={() => onChange(pin)}>
        <FontAwesomeIcon icon={faShare} />
      </Button>
    </div>
  );
}

function DigitField({ value, onChange, active }) {
  const ref = useRef();

  useEffect(() => {
    if (active) {
      ref.current.focus();
    }
  }, [active]);

  return (
    <input
      ref={ref}
      onFocus={(e) => e.target.select()}
      type="number"
      min={0}
      max={9}
      step={1}
      value={parseInt(value)}
      onChange={onChange}
      style={{
        width: "100%",
        padding: "10px 15px",
        fontSize: 20,
        textAlign: "center",
        border: "1px solid #ccc",
        borderRadius: 0,
      }}
    />
  );
}
