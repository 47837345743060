import { Button, Col, Row } from "react-bootstrap";
import FastTable from "../../components/FastTable/FastTable";
import FastForm from "../../components/FastForm/FastForm";
import useRights from "../../hooks/useRights";
import { ENTITY_NAMES, RIGHT_NAMES } from "../../constants";
import useLocations from "../../hooks/useLocations";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useUsers from "../../hooks/useUsers";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";

export default function UserRights() {
  usePage({ title: "Permisos" });
  const params = useParams();
  const { user } = useAuth();
  const { addRightMutation, removeRight, userRights } = useRights({
    eventid: params?.eventid,
    orgid: user?.orgid,
  });
  const { locations } = useLocations({
    eventid: params?.eventid,
    orgid: user?.orgid,
  });
  const { data: allUsers } = useUsers();

  return (
    <>
      <h1>Permisos</h1>
      <hr className="small" />
      <Row>
        <Col lg={3}>
          <h2>Agregar permisos</h2>
          <FastForm
            mutation={addRightMutation}
            fields={[
              { name: "orgid", type: "hidden", defaultValue: user?.orgid },
              {
                name: "eventid",
                type: "hidden",
                defaultValue: parseInt(params?.eventid),
              },
              {
                name: "userid",
                label: "Elegir usuario",
                type: "autocomplete",
                autocomplete: {
                  data: allUsers,
                  searchkeys: ["name", "email", "tag"],
                  parseValue: (value, pre) => {
                    console.log("parseValue", value, pre);
                    return value.userid;
                  },
                  parseOptions: (ele) => ({
                    ...ele,
                    value: ele?.userid,
                    label: `${ele.name} - ${ele.email} (${ele.tag})`,
                  }),
                },
              },
              {
                name: "uright",
                label: "Permiso",
                type: "select",
                options: [
                  { value: "", label: "" },
                  ...Object.keys(RIGHT_NAMES).map((key) => ({
                    value: key,
                    label: RIGHT_NAMES[key],
                  })),
                ],
                attributes: {
                  required: true,
                  placeholder: "Seleccione un permiso",
                },
              },
              {
                name: "entity",
                label: "Entidad",
                type: "select",
                options: [
                  { value: "", label: "" },
                  ...Object.keys(ENTITY_NAMES).map((key) => ({
                    value: key,
                    label: ENTITY_NAMES[key],
                  })),
                ],
                attributes: { required: true },
              },
              {
                name: "locationid",
                label: "Ubicación",
                type: "select",
                options: [
                  { value: "", label: "" },
                  ...(locations
                    ? locations.map((location) => ({
                        value: location.locationid,
                        label: location.name,
                      }))
                    : []),
                  { value: "0", label: "Todas las ubicaciones" },
                ],
                attributes: { required: true },
              },
              {
                name: "eventid",
                type: "hidden",
                defaultValue: parseInt(params?.eventid),
              },
            ]}
            parseData={(data) => ({
              uright: parseInt(data.uright),
              locationid: parseInt(data.locationid),
              userid: parseInt(data.userid),
              orgid: parseInt(data.orgid),
              entity: parseInt(data.entity),
              usergroupid: 0,
            })}
            sendText="Agregar permiso"
          />
        </Col>
        <Col lg={9}>
          <FastTable
            data={userRights}
            columns={[
              { key: "name", label: "Permiso", type: "string" },
              {
                key: "locationname",
                label: "Ubicación",
                type: "string",
                render: (row) =>
                  typeof row.locationname === "string"
                    ? row.locationname
                    : parseInt(row?.locationid) === 0
                    ? "Todas las ubicaciones"
                    : row.locationid,
              },
              {
                key: "entity",
                label: "Entidad",
                render: (row) => ENTITY_NAMES[row.entity],
                type: "string",
              },
              { key: "username", label: "Usuario", type: "string" },
              { key: "email", label: "Correo", type: "string" },
              { key: "tag", label: "Tag", type: "string" },
              {
                key: "remove",
                label: "",
                sortable: false,
                style: { width: 50 },
                render: (row) => (
                  <Button
                    onClick={(e) => {
                      console.log(row);
                      removeRight(row);
                    }}
                    variant="secondary"
                    className="text-danger"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
