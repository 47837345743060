import { useEffect, useState } from "react";
import MultiSelectDropDown from "../MultiSelectDropdown";

export default function MultiSelectWrapper(props) {
  const { defaultValue, ...rest } = props;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <input type="hidden" name={rest.name} value={value?.join(",") || ""} />
      <MultiSelectDropDown {...rest} value={value || []} onChange={setValue} />
    </>
  );
}
