import { Button, Col, Row } from "react-bootstrap";
import useProducts from "../../hooks/useProducts";
import { useMemo, useState } from "react";
import FastTable from "../../components/FastTable/FastTable";
import FastForm from "../../components/FastForm/FastForm";
import { useParams } from "react-router-dom";
import styles from "./Products.module.scss";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";

export default function Categories() {
  usePage({ title: "Categorías" });
  const params = useParams();
  const { user } = useAuth();
  const {
    products,
    categories,
    createCategoryMutation,
    deleteCategoryMutation,
    editCategoryMutation,
  } = useProducts();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const selectedCategoryData = useMemo(
    () =>
      categories?.find(
        (category) => category.categoryid === selectedCategory?.categoryid
      ),
    [categories, selectedCategory]
  );

  const countedCategories = useMemo(
    () =>
      categories && products
        ? categories?.map((category) => ({
            ...category,
            products: products.filter(
              (product) => product.categoryid === category.categoryid
            ).length,
          }))
        : [],
    [categories, products]
  );

  return (
    <>
      <Row>
        <Col>
          <h1>Categorías</h1>
          <hr className="small" />
        </Col>
      </Row>
      <Row>
        <Col
          md={3}
          style={{ gap: "1rem", display: "flex", flexDirection: "column" }}
        >
          <h2>Categorías</h2>
          <FastTable
            data={countedCategories}
            columns={[
              { key: "categoryid", label: "ID", style: { width: 80 } },
              { key: "name", label: "Nombre", type: "string" },
              { key: "products", label: "Productos", type: "number" },
            ]}
            onSelectRow={(row) => setSelectedCategory({ ...row })}
            showExcel={false}
            showGroups={false}
            showFilters={false}
          />
          <h2>Crear categoría</h2>
          <FastForm
            mutation={createCategoryMutation}
            fields={[
              {
                name: "eventid",
                type: "hidden",
                defaultValue: parseInt(params.eventid),
                attributes: { readOnly: true },
              },
              {
                name: "name",
                label: "Nombre",
                placeholder: "Nombre de la categoría",
              },
            ]}
            sendText="Crear categoría"
            clearOnSuccess={true}
          />
        </Col>
        <Col
          md={9}
          style={{ gap: "1rem", display: "flex", flexDirection: "column" }}
        >
          {selectedCategoryData && (
            <>
              <h2>Editar categoría</h2>
              <FastForm
                key={selectedCategoryData?.categoryid}
                mutation={editCategoryMutation}
                className={styles.CategoriesForm}
                fields={[
                  {
                    name: "eventid",
                    type: "hidden",
                    defaultValue: parseInt(params.eventid),
                    attributes: { readOnly: true },
                  },
                  {
                    name: "categoryid",
                    type: "hidden",
                    defaultValue: selectedCategoryData?.categoryid,
                    attributes: { readOnly: true },
                  },
                  {
                    name: "name",
                    label: "Nombre",
                    placeholder: "Nombre de la categoría",
                    defaultValue: selectedCategoryData?.name,
                  },
                ]}
                sendText="Guardar cambios"
                append={
                  <>
                    <Button
                      variant="danger"
                      className="btn-bordered"
                      style={{ width: "100%" }}
                      onClick={() =>
                        deleteCategoryMutation.mutate({
                          orgid: user.orgid,
                          categoryid: selectedCategoryData?.categoryid,
                          name: selectedCategoryData?.name,
                        })
                      }
                    >
                      Borrar categoría
                    </Button>
                    <Button
                      className="btn-bordered"
                      style={{ width: "100%" }}
                      onClick={() => setSelectedCategory(null)}
                    >
                      Cerrar
                    </Button>
                  </>
                }
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
