import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import { get, post } from "../utils/Api";
import { useParams } from "react-router-dom";
import { useGlobalAlert } from "./useGlobalAlert";
import { useMemo } from "react";

export default function useGroups({ offset = 0, limit, search = "" } = {}) {
  const { user } = useAuth();
  const params = useParams();
  const eventid = params?.eventid;
  const token = user?.token;
  const queryClient = useQueryClient();
  const { setAlert } = useGlobalAlert();

  const groupsQuery = useQuery({
    queryKey: ["usergroups", token],
    queryFn: () =>
      post("/usergroups", {
        orgid: user.orgid,
        eventid: eventid,
        token,
        offset,
        limit,
        filter: search,
      }),
  });

  const removeUserMutation = useMutation({
    mutationFn: ({ userid, eventid, usergroupid }) =>
      post("/removeuserfromgroup", {
        userid,
        token,
        eventid,
        usergroupid,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["usergroups", token]);
      setAlert({ variant: "success", message: "Usuario eliminado" });
    },
    onError: (error) => {
      queryClient.invalidateQueries(["usergroups", token]);
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
  });

  const addUserGroupMutation = useMutation({
    mutationFn: ({ userid, eventid, usergroupid }) =>
      post("/addusertogroup", {
        userid,
        token,
        eventid,
        usergroupid,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["usergroups", token]);
      setAlert({ variant: "success", message: "Usuario agregado" });
    },
    onError: (error) => {
      queryClient.invalidateQueries(["usergroups", token]);
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
  });

  const createUserGroupMutation = useMutation({
    mutationFn: ({ name, eventid }) =>
      post("/creategroup", {
        name,
        token,
        eventid,
        orgid: user.orgid,
        entity: "event",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["usergroups", token]);
      setAlert({ variant: "success", message: "Grupo creado" });
    },
    onError: (error) => {
      queryClient.invalidateQueries(["usergroups", token]);
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
  });

  const userGroups = useMemo(
    () =>
      (groupsQuery?.data || []).filter(
        (group) =>
          parseInt(group?.eventid) === parseInt(eventid) ||
          (parseInt(eventid) <= 16 && parseInt(group?.eventid) === 0)
      ),
    [groupsQuery.data, eventid]
  );

  return {
    ...groupsQuery,
    data: userGroups,
    addUser: addUserGroupMutation.mutate,
    addUserGroupMutation,
    createUserGroup: createUserGroupMutation.mutate,
    createUserGroupMutation,
    removeUser: removeUserMutation.mutate,
    removeUserMutation,
  };
}
