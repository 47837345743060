import { Col, Row } from "react-bootstrap";
import styles from "./Products.module.scss";
import CreateProductForm from "../../components/CreateProductForm";
import { usePage } from "../../hooks/usePage";

export default function CreateProduct() {
  usePage({ title: "Crear Producto" });
  return (
    <Row>
      <Col>
        <CreateProductForm className={styles.ProductForm} />
      </Col>
    </Row>
  );
}
