import FastForm from "../../components/FastForm/FastForm";
import { Container } from "react-bootstrap";
import useUsers from "../../hooks/useUsers";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import useEvents from "../../hooks/useEvents";
import { TABLE_TYPES } from "../../components/FastTable/FastTable";

export default function CreateUser() {
  const { user } = useAuth();
  const { data: events } = useEvents();
  const params = useParams();
  const { createUserMutation } = useUsers();
  usePage({ title: "Crear usuario" });

  const fields = useMemo(() => {
    const fields = [
      { name: "orgid", defaultValue: user.orgid, type: "hidden" },
      {
        name: "name",
        label: "Nombre",
        placeholder: "Nombre",
        type: "text",
        attributes: { required: true },
      },
      {
        name: "email",
        label: "Email",
        placeholder: "Email",
        type: "email",
        attributes: { required: true },
      },
      {
        name: "password",
        label: "Contraseña",
        placeholder: "Contraseña",
        type: "password",
        attributes: { required: true },
      },
      {
        name: "repassword",
        label: "Repetir contraseña",
        placeholder: "Repetir contraseña",
        type: "password",
        attributes: { required: true },
      },
      {
        name: "tag",
        label: "Tag",
        placeholder: "Tag",
        type: "text",
      },
      {
        name: "shortag",
        label: "Short Tag",
        placeholder: "Short Tag",
        type: "text",
      },
      {
        name: "orgid",
        label: "Organización",
        placeholder: "Organización",
        type: "hidden",
        attributes: { readOnly: true },
        defaultValue: user.orgid,
      },
    ];
    if (params.eventid) {
      fields.push({
        name: "eventid",
        defaultValue: params?.eventid,
        label: "Evento",
        type: TABLE_TYPES.options,
        options: [
          { label: "Elige un evento", value: 0 },
          ...events.map((e) => ({
            label: e.name,
            value: e.eventid,
          })),
        ],
      });
    }
    return fields;
  }, [params.eventid, user.orgid, events]);

  return (
    <Container fluid>
      <h1>Crear usuario</h1>
      <FastForm
        autoComplete="off"
        mutation={createUserMutation}
        sendText="Crear usuario"
        fields={fields}
        validate={(values) => {
          if (values.password.length < 6) {
            return {
              password: "La contraseña debe tener al menos 6 caracteres",
            };
          }
          if (values.password !== values.repassword) {
            return { repassword: "Las contraseñas no coinciden" };
          }
          return true;
        }}
        postParseData={(values) => {
          const { repassword, ...data } = values;
          return data;
        }}
      />
    </Container>
  );
}
