import { useState, useMemo, useEffect } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { usePage } from "../hooks/usePage";
import useLang from "../lang/useLang";
import { usePublicSummary } from "../hooks/usePublicSummary";
import DateRange from "../components/FastChart/DateRange";
import PillToggler from "../components/PillToggler";
import styles from "./PublicViewer.module.scss";
import Viewer from "../components/Viewer";
import UpdateReportButton from "../components/UpdateReportButton";
import PinField from "../components/PinField";

export default function Page() {
  const { trans } = useLang();
  usePage({ title: trans("nav.viewer") });
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const params = useParams();
  const rtoken = params.rtoken;

  const {
    event,
    dataUpdatedAt,
    totalTopUpsAmount,
    totalTopUpsPayments,
    totalSales,
    totalSalesAmount,
    noPaymentInfo,
    noPaymentInfoAmount,
    totalInits,
    cashiers,
    salespeople,
    locations,
    allLocations,
    products,
    currencies,
    refetch,
    isLoading,
    isRefetching,
    validData,
    pin,
    setPin,
    status,
    dataQuery,
    dataIsLoading,
    dataStatus,
    dataError,
  } = usePublicSummary({
    start: dateStart,
    end: dateEnd,
    limit: 1000000000,
    offset: 0,
    rtoken,
  });

  const currenciesFiltered = useMemo(
    () =>
      Object.keys(currencies).reduce((acc, currencyid) => {
        const currency = currencies[currencyid];
        if (currency.topUpsAmount || currency.salesAmount) {
          acc[currencyid] = currency;
        }
        return acc;
      }, {}),
    [currencies]
  );
  const [selectedCurrenciesForTotal, setSelectedCurrenciesForTotal] = useState(
    currenciesFiltered ? Object.keys(currenciesFiltered) : []
  );

  useEffect(() => {
    if (
      selectedCurrenciesForTotal.length === 0 &&
      currenciesFiltered &&
      Object.keys(currenciesFiltered).length > 0
    ) {
      setSelectedCurrenciesForTotal(Object.keys(currenciesFiltered));
    }
  }, [currenciesFiltered, selectedCurrenciesForTotal]);

  useEffect(() => {
    if (!dateStart && event?.start) {
      setDateStart(parseInt(event.start));
    }
  }, [event?.start, dateStart]);

  useEffect(() => {
    if (!dateEnd && event?.end) {
      setDateEnd(parseInt(event.end));
    }
  }, [event?.end, dateEnd]);

  if (
    isLoading ||
    pin === null ||
    dataStatus === "loading" ||
    dataStatus === "pending"
  ) {
    return (
      <Container>
        <Row>
          <Col className="p-4 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  }

  if (validData === null) {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="danger" className="text-center">
              {trans("reports.url_not_valid")}
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  if (dataStatus === "error") {
    return (
      <Container>
        <Row>
          <Col className="my-4 text-center" lg={{ offset: 4, span: 4 }}>
            <h2 className="text-center">Introduzca el código de acceso</h2>
            <PinField
              defaultValue={pin}
              onChange={(value) => {
                setPin(value);
                setTimeout(() => dataQuery.refetch(), 100);
              }}
            />
            {dataError?.msg && (
              <Alert variant="danger" className="mt-4">
                {dataError?.msg}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <small>{trans("nav.viewer")}</small>
          <h1 style={{ marginTop: 0 }}>{event?.name}</h1>
          <h2 style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
            {validData &&
              (validData?.length === 0
                ? "Todas las ubicaciones"
                : Object.values(allLocations)
                    .filter((location) =>
                      validData.includes(parseInt(location.locationid))
                    )
                    .map((location, index) => (
                      <span
                        key={index}
                        style={{
                          fontSize: 12,
                          backgroundColor: "#fff",
                          color: "var(--bs-primary)",
                          border: "1px solid var(--bs-primary)",
                          padding: "4px 12px",
                          borderRadius: 20,
                          textAlign: "center",
                        }}
                      >
                        {location.name}
                      </span>
                    )))}
          </h2>
        </Col>
        <Col className="justify-content-end align-items-center d-flex">
          <UpdateReportButton
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
            isLoading={isLoading}
            isRefetching={isRefetching}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4 text-center" lg={{ offset: 4, span: 4 }}>
          <DateRange
            type="datetime-local"
            start={dateStart}
            end={dateEnd}
            setStart={setDateStart}
            setEnd={setDateEnd}
            options={[
              { value: 60 * 60, label: trans("reports.last_1hr") },
              { value: 60 * 60 * 12, label: trans("reports.last_12hr") },
              { value: 60 * 60 * 24, label: trans("reports.last_24hr") },
              { value: 60 * 60 * 48, label: trans("reports.last_48hr") },
              // { value: 60 * 60 * 24 * 7, label: trans("reports.last_7d") },
              // { value: 60 * 60 * 24 * 30, label: trans("reports.last_30d") },
            ]}
            enableSearchParams={false}
          />
        </Col>
      </Row>
      {!!dateStart ? (
        <>
          <Viewer
            totalTopUpsAmount={totalTopUpsAmount}
            totalTopUpsPayments={totalTopUpsPayments}
            totalSales={totalSales}
            totalSalesAmount={totalSalesAmount}
            totalInits={totalInits}
            cashiers={cashiers}
            salespeople={salespeople}
            locations={locations}
            products={products}
            currencies={currencies}
            refetch={refetch}
            noPaymentInfo={noPaymentInfo}
            noPaymentInfoAmount={noPaymentInfoAmount}
            filterZeroes={validData?.length > 0}
          />
        </>
      ) : (
        <Row>
          <Col>
            <h2 className="text-center">Seleccione un rango de fechas</h2>
          </Col>
        </Row>
      )}
    </Container>
  );
}
