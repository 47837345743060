import { faClose, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";

export default function ImageField({
  name,
  label,
  defaultValue,
  onChange,
  uploader,
}) {
  const inputRef = useRef();
  const [value, setValue] = useState(defaultValue);
  const [uploadError, setError] = useState(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleOnChange = useCallback(
    (newValue) => {
      setValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );

  const handleUpload = (e) => {
    console.log("handleUpload", e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      // send file to server
      const file = e.target.files[0];
      uploader
        .upload(file)
        .then((url) => {
          console.log("response url", url);
          handleOnChange(url);
        })
        .catch((error) => {
          setError(error);
          handleOnChange(defaultValue);
        });
    }
    console.log("handleUpload end");
  };
  return (
    <>
      <input
        type="file"
        onChange={handleUpload}
        ref={inputRef}
        style={{ display: "none" }}
      />
      <div
        style={{
          width: 200,
          height: 200,
          overflow: "hidden",
          borderRadius: 10,
          background: "var(--bs-grey)",
          backgroundImage: value && value !== "" ? `url(${value})` : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => inputRef.current.click()}
      >
        {defaultValue && defaultValue !== "" && defaultValue !== value && (
          <button
            className="btn  btn-bordered"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              padding: 0,
              fontSize: 20,
              lineHeight: "20px",
              height: 20,
              width: 20,
              borderRadius: 10,
              border: "none",
              color: "var(--bs-primary)",
            }}
            onClick={(e) => {
              e.preventDefault();
              // stop propagation
              e.stopPropagation();
              setValue(defaultValue);
              return false;
            }}
          >
            <FontAwesomeIcon icon={faUndo} />
          </button>
        )}
        {value && value !== "" ? (
          <>
            <button
              className="btn btn-danger btn-bordered"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: 0,
                fontSize: 20,
                lineHeight: "20px",
                height: 20,
                width: 20,
                borderRadius: 10,
                border: "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                // stop propagation
                e.stopPropagation();
                setValue("");
                return false;
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
            <input
              style={{
                fontSize: "80%",
                fontWeight: "600",
                color: "grey",
                padding: "1.5rem 0.5rem 0.5rem",
                background:
                  "linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                border: "none",
                direction: "rtl",
                textOverflow: "ellipsis",
              }}
              name={name}
              readOnly
              value={value}
            />
          </>
        ) : (
          <div
            style={{ padding: "80px 0", textAlign: "center", color: "grey" }}
          >
            Click to upload
          </div>
        )}
      </div>
      {uploadError && <span className="text-danger">{uploadError}</span>}
    </>
  );
}
