import { useEffect, useMemo, useRef, useState } from "react";
import RBPagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import styles from "./Pagination.module.scss";
import { useSearchParams } from "react-router-dom";

const DEFAULT_LIMIT = 10;

export default function Pagination({
  data = [],
  setData,
  updateSearchParams = true,
}) {
  const [ready, setReady] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);

  useEffect(() => {
    if (!ready) {
      if (updateSearchParams) {
        const urlParams = new URLSearchParams(searchParams);
        const _offset = urlParams.get("p");
        const _limit = urlParams.get("l");
        if (_offset) {
          setOffset(_offset);
        }
        if (_limit) {
          setLimit(_limit);
        }
      }
      setReady(true);
    }
  }, [searchParams, ready, updateSearchParams]);

  useEffect(() => {
    if (updateSearchParams) {
      const urlParams = new URLSearchParams(searchParams);
      if (offset !== 0) {
        urlParams.set("p", offset);
      } else {
        urlParams.delete("p");
      }
      if (limit !== DEFAULT_LIMIT) {
        urlParams.set("l", limit);
      } else {
        urlParams.delete("l");
      }
      setSearchParams(urlParams);
    }
  }, [offset, limit, updateSearchParams, searchParams, setSearchParams]);

  const totalPages = useMemo(
    () => Math.ceil(data.length / limit),
    [data, limit]
  );

  const dataSignature = useRef();

  useEffect(() => {
    if (Array.isArray(data)) {
      const effectiveOffset = Math.min(offset, totalPages * limit - limit);
      const paginatedData = data.slice(
        effectiveOffset,
        effectiveOffset + limit
      );
      const signature = JSON.stringify(paginatedData);
      if (dataSignature.current !== signature) {
        dataSignature.current = signature;
        setData(paginatedData);
      }
    }
  }, [data, offset, limit, setData, totalPages]);

  const maxOffset = (totalPages - 1) * limit;
  const page = offset / limit + 1;

  const arrayPreviousPages = Array.from(
    { length: Math.min(page - 1, 2) },
    (_, i) => page - i - 1
  ).reverse();

  const arrayNextPages = Array.from(
    { length: Math.min(totalPages - page, 2) },
    (_, i) => page + i + 1
  );

  const firstOfPreviousPages = arrayPreviousPages[0];
  const lastOfNextPages = arrayNextPages[arrayNextPages.length - 1];
  const allPagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

  const prevEllipsis =
    firstOfPreviousPages > 2
      ? Math.floor((firstOfPreviousPages - 1) / 2) + 1
      : null;

  const nextEllipsis =
    lastOfNextPages < totalPages - 1
      ? Math.floor((totalPages - lastOfNextPages) / 2) + lastOfNextPages
      : null;

  useEffect(() => {
    setOffset((prev) =>
      Math.min(totalPages * limit, Math.floor(prev / limit) * limit)
    );
  }, [limit, totalPages]);

  useEffect(() => {
    console.log("rerender Pagination updateSearchParams");
  }, [updateSearchParams]);

  if (!ready) return null;

  return (
    <div className={styles.Pagination}>
      <RBPagination>
        {offset > 0 && firstOfPreviousPages > 1 && (
          <RBPagination.First
            onClick={() => setOffset(0)}
            disabled={offset === 0}
          >
            {1}
          </RBPagination.First>
        )}
        {prevEllipsis && (
          <RBPagination.Ellipsis
            onClick={() => setOffset((prevEllipsis - 1) * limit)}
          />
        )}
        {arrayPreviousPages.map((number) => (
          <RBPagination.Item
            key={number}
            onClick={() => setOffset((number - 1) * limit)}
          >
            {number}
          </RBPagination.Item>
        ))}
        {totalPages > 1 && (
          <Form.Select
            value={offset}
            onChange={(e) => setOffset(parseInt(e.target.value))}
            className={styles.PageSelect}
          >
            {allPagesArray.map((page) => (
              <option key={page} value={(page - 1) * limit}>
                {`${page}`}
              </option>
            ))}
          </Form.Select>
        )}
        {arrayNextPages.map((number) => (
          <RBPagination.Item
            key={number}
            onClick={() => setOffset((number - 1) * limit)}
          >
            {number}
          </RBPagination.Item>
        ))}
        {nextEllipsis && (
          <RBPagination.Ellipsis
            onClick={() => setOffset((nextEllipsis - 1) * limit)}
          />
        )}
        {offset < maxOffset && lastOfNextPages < totalPages && (
          <RBPagination.Last onClick={() => setOffset(maxOffset)}>
            {totalPages}
          </RBPagination.Last>
        )}
      </RBPagination>
      <span>{`${offset + 1} a ${Math.min(offset + limit, data.length)} de ${
        data.length
      }`}</span>
      <Form.Select
        value={limit}
        onChange={(e) => setLimit(parseInt(e.target.value))}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Form.Select>
    </div>
  );
}
