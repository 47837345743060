import { Form, Spinner } from "react-bootstrap";
import useEvents from "../hooks/useEvents";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useLang from "../lang/useLang";

export default function EventSelector() {
  const { trans } = useLang();
  const { data, error, isLoading, isError } = useEvents();
  const { user } = useAuth();
  const { orgid } = user;
  const { eventid } = useParams();
  const navigate = useNavigate();

  // get the current route
  const { pathname } = useLocation();

  const handleOnChange = (e) => {
    if (isNaN(e.target.value)) {
      navigate(`/`);
      return;
    }
    console.log("pathname", pathname);
    const newEventid = parseInt(e.target.value);
    if (!eventid) {
      navigate(`/${newEventid}`);
    } else {
      // replace the current route with the selected eventid
      const newPath = pathname.replace(/^\/\d+(.*)/, `/${newEventid}$1`);
      console.log("newPath", newPath);
      navigate(newPath);
    }
  };

  if (isNaN(orgid)) return null;
  if (isLoading) return <Spinner animation="border" />;
  if (isError) return <span title={error?.message}>error</span>;
  return (
    <Form.Select
      aria-label={trans("nav.chooseevent")}
      onChange={handleOnChange}
      value={parseInt(eventid)}
    >
      <option>{trans("nav.chooseevent")}</option>
      {data?.map((event) => (
        <option key={event.eventid} value={event.eventid}>
          {event.name}
        </option>
      ))}
    </Form.Select>
  );
}
