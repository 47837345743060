import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import styles from "./MultiSelectDropdown.module.scss";

export default function MultiSelectDropDown({
  options,
  value,
  onChange,
  className = "",
  style = {},
  containerStyle = {},
  badgeStyle = {},
}) {
  const handleSelect = (option) => {
    if (value.includes(option)) {
      onChange(value.filter((item) => item !== option));
    } else {
      onChange([...value, option]);
    }
  };
  return (
    <Dropdown
      className={[styles.MultiSelectDropdown, className].join(" ")}
      style={style}
    >
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className={styles.Toggle}
        style={containerStyle}
      >
        {value.length === 0
          ? "Select"
          : value.map((singleValue) => (
              <Badge key={singleValue} style={badgeStyle}>
                {options.find((option) => option.key === singleValue)?.label}
              </Badge>
            ))}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ maxHeight: 400, overflow: "auto" }}>
        {value.length > 0 && (
          <Dropdown.Item onClick={() => onChange([])}>Ninguno</Dropdown.Item>
        )}
        {value.length !== options.length && (
          <Dropdown.Item
            onClick={() => onChange([...options.map((option) => option.key)])}
          >
            Todos
          </Dropdown.Item>
        )}
        {options.map((option) => (
          <Dropdown.Item
            key={option.key}
            onClick={() => handleSelect(option.key)}
            style={{
              backgroundColor: value.includes(option.key)
                ? "#f0f0f0"
                : "transparent",
            }}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
