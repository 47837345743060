import { Button, Col, Row } from "react-bootstrap";
import FastTable from "../../components/FastTable/FastTable";
import { useParams } from "react-router-dom";
import FastForm from "../../components/FastForm/FastForm";
import useGroups from "../../hooks/useGroups";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import TabPanel from "../../components/TabPanel";
import useUsers from "../../hooks/useUsers";
import Rights from "../../components/Rights";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";

export default function Groups() {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    removeUser,
    createUserGroupMutation,
    addUserGroupMutation,
  } = useGroups();
  const params = useParams();
  const { user } = useAuth();
  const users = selectedGroup?.users || [];
  const { data: allUsers } = useUsers();
  usePage({ title: "Grupos de usuarios" });

  return (
    <>
      <h1>Grupos de usuarios</h1>
      <hr className="mb-4" />
      <Row>
        <Col md={6} lg={3}>
          <h2>Grupos</h2>
          <FastTable
            data={data}
            columns={[{ key: "name", label: "Nombre", type: "string" }]}
            showGroups={false}
            showFilters={false}
            showExcel={false}
            isLoading={isLoading}
            onSelectRow={(row) => setSelectedGroup(row)}
            SelectIcon={() => <FontAwesomeIcon icon={faChevronRight} />}
            selected={(row) => row.usergroupid === selectedGroup?.usergroupid}
          />
          <hr className="large" />
          <h2>Crear grupo</h2>
          <FastForm
            mutation={createUserGroupMutation}
            validate={(values) => {
              if (values.name.length < 3) {
                return {
                  password: "Elija un nombre más largo",
                };
              }
              return true;
            }}
            fields={[
              {
                name: "name",
                label: "Nombre",
                placeholder: "Nombre",
                type: "text",
                attributes: { required: true },
              },
              {
                name: "eventid",
                label: "Evento",
                placeholder: "Evento",
                type: "hidden",
                attributes: { readOnly: true },
                defaultValue: params.eventid,
              },
            ]}
          />
        </Col>
        <Col md={6} lg={9}>
          {selectedGroup && (
            <>
              <h2>{selectedGroup.name}</h2>
              <TabPanel
                content={[
                  {
                    key: "members",
                    title: "Miembros",
                    content: (
                      <Row>
                        <Col lg={3}>
                          <FastForm
                            mutation={addUserGroupMutation}
                            sendText="Agregar usuario"
                            fields={[
                              {
                                name: "userid",
                                label: "Agregar una cuenta",
                                type: "autocomplete",
                                autocomplete: {
                                  data: allUsers,
                                  searchkeys: ["name", "email", "tag"],
                                  parseValue: (value, pre) => {
                                    console.log("parseValue", value, pre);
                                    return value.userid;
                                  },
                                  parseOptions: (ele) => ({
                                    ...ele,
                                    value: ele?.userid,
                                    label: `${ele.name} - ${ele.email} (${ele.tag})`,
                                  }),
                                },
                              },
                              {
                                name: "orgid",
                                label: "Organización",
                                type: "hidden",
                                defaultValue: user.orgid,
                                attributes: { readOnly: true },
                              },
                              {
                                name: "usergroupid",
                                label: "User Group",
                                type: "hidden",
                                defaultValue: selectedGroup.usergroupid,
                                attributes: { readOnly: true },
                              },
                            ]}
                          />
                        </Col>
                        <Col lg={9}>
                          <FastTable
                            data={users}
                            isLoading={isLoading || isRefetching}
                            columns={[
                              { key: "userid", label: "ID", type: "string" },
                              { key: "cugid", label: "ID in G", type: "string" },
                              { key: "name", label: "Nombre", type: "string" },
                              { key: "email", label: "Email", type: "string" },
                              { key: "tag", label: "Tag", type: "string" },
                              {
                                key: "remove",
                                label: "",
                                sortable: false,
                                style: { width: 50 },
                                render: (row) => (
                                  <Button
                                    onClick={(e) => {
                                      console.log(row);
                                      removeUser({
                                        userid: row.userid,
                                        usergroupid: selectedGroup.usergroupid,
                                        orgid: parseInt(user.orgid),
                                      });
                                    }}
                                    variant="secondary"
                                    className="text-danger"
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                ),
                              },
                            ]}
                            showFilters={false}
                            showGroups={false}
                            showExcel={false}
                          />
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    key: "rights",
                    title: "Permisos",
                    content: <Rights usergroupid={selectedGroup.usergroupid} />,
                  },
                ]}
                onSuccess={() => {
                  refetch();
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
