import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useEvents from "../hooks/useEvents";
import {
  Button,
  ButtonGroup,
  Col,
  InputGroup,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { formatDate } from "../utils/date";
import styles from "./EventsList.module.scss";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import useLang, { T } from "../lang/useLang";

const EVENT_STATUS_PAST = -1;
const EVENT_STATUS_PRESENT = 0;
const EVENT_STATUS_FUTURE = 1;

export default function EventsList() {
  const { data, isLoading } = useEvents();
  const { trans } = useLang();
  const [showStatus, setShowStatus] = useState([
    EVENT_STATUS_FUTURE,
    EVENT_STATUS_PRESENT,
  ]);
  const [quickSearch, setQuickSearch] = useState("");

  const filtered = useMemo(
    (event) =>
      data
        ?.map((event) => {
          const now = Math.floor(new Date().getTime() / 1000);
          let status = EVENT_STATUS_FUTURE;
          if (event.start > now) {
            status = EVENT_STATUS_FUTURE;
          } else if (event.end < now) {
            status = EVENT_STATUS_PAST;
          } else {
            status = EVENT_STATUS_PRESENT;
          }
          return { ...event, dateStatus: status };
        })
        .filter(
          (event) =>
            showStatus.includes(event.dateStatus) &&
            event.name.toLowerCase().includes(quickSearch.toLowerCase())
        ),
    [data, showStatus, quickSearch]
  );

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>{trans("events.events")}</h1>
        </Col>
        <Col className={styles.QuickSearch}>
          <Form>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder={trans("events.search")}
                value={quickSearch}
                onChange={(e) => setQuickSearch(e.target.value)}
              />
              <Button
                onClick={() => setQuickSearch("")}
                disabled={quickSearch === ""}
              >
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </InputGroup>
          </Form>
        </Col>
        <Col className={styles.HeaderButtons}>
          <ButtonGroup>
            <Button
              variant={
                showStatus.includes(EVENT_STATUS_PAST) ? "primary" : "light"
              }
              onClick={() =>
                setShowStatus((prev) =>
                  prev.includes(EVENT_STATUS_PAST)
                    ? prev.filter((s) => s !== EVENT_STATUS_PAST)
                    : [...prev, EVENT_STATUS_PAST]
                )
              }
            >
              Pasados
            </Button>
            <Button
              variant={
                showStatus.includes(EVENT_STATUS_PRESENT) ? "primary" : "light"
              }
              onClick={() =>
                setShowStatus((prev) =>
                  prev.includes(EVENT_STATUS_PRESENT)
                    ? prev.filter((s) => s !== EVENT_STATUS_PRESENT)
                    : [...prev, EVENT_STATUS_PRESENT]
                )
              }
            >
              Activos
            </Button>
            <Button
              variant={
                showStatus.includes(EVENT_STATUS_FUTURE) ? "primary" : "light"
              }
              onClick={() =>
                setShowStatus((prev) =>
                  prev.includes(EVENT_STATUS_FUTURE)
                    ? prev.filter((s) => s !== EVENT_STATUS_FUTURE)
                    : [...prev, EVENT_STATUS_FUTURE]
                )
              }
            >
              Futuros
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <div className={[styles.List, "full-width"].join(" ")}>
        {isLoading ? (
          <Spinner animation="border" />
        ) : (
          filtered?.map((event) => (
            <EventCard key={event.eventid} event={event} />
          ))
        )}
      </div>
    </Fragment>
  );
}

export function EventCard({ event }) {
  const [tick, setTick] = useState(new Date());
  const { trans } = useLang();

  useEffect(() => {
    const ticker = () => {
      setTick(new Date());
    };
    const interval = setInterval(ticker, 1000);
    return () => clearInterval(interval);
  }, []);

  const dateStatus = useMemo(() => {
    const now = Math.floor(tick.getTime() / 1000);
    return event.start > now
      ? EVENT_STATUS_FUTURE
      : event.end < now
      ? EVENT_STATUS_PAST
      : EVENT_STATUS_PRESENT;
  }, [tick, event?.start, event?.end]);

  return (
    <Row key={event.eventid} className={[styles.Event].join(" ")}>
      <Col className={[styles.Col1].join(" ")}>
        <h2>{event.name}</h2>
        <pre>
          {formatDate(event.start)} - {formatDate(event.end)}
        </pre>
        <div
          className={
            dateStatus === EVENT_STATUS_PRESENT ? "text-success" : "text-danger"
          }
        >
          <FontAwesomeIcon icon={faCircle} className="me-2" />
          {dateStatus === EVENT_STATUS_PRESENT
            ? trans("events.live")
            : trans("events.offline")}
        </div>
        <Link to={`/${event.eventid}`} className="btn btn-primary">
          <T>events.event</T>
        </Link>
        <Link to={`/${event.eventid}/reports`} className="btn btn-primary">
          <T>events.reports</T>
        </Link>
      </Col>
    </Row>
  );
}
