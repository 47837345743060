import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { post } from "../utils/Api";
import { useAuth } from "./useAuth";
import { useCallback, useMemo } from "react";
import { TX_TYPES } from "../constants";
import useDevices from "./useDevices";
import useUsers from "./useUsers";
import useProducts from "./useProducts";
import useCurrencies from "./useCurrencies";
import useLocations from "./useLocations";
import { parseSummary } from "./usePublicSummary";

export default function useReports({ eventid, limit, offset, start, end }) {
  const { user } = useAuth();
  const query = useQuery({
    queryKey: ["report", eventid, start, end],
    queryFn: async () =>
      post("/report", {
        token: user.token,
        eventid,
        limit,
        offset,
        start,
        end: end || Math.floor(new Date().getTime() / 1000),
      }),
    enabled: !!user.token && !!eventid && !!start && start !== "",
  });

  const { devicesQuery } = useDevices({ eventid });
  const { data: users } = useUsers();
  const devices = devicesQuery.data;

  const parsedData = useMemo(() => {
    const allTxs =
      query.data?.map((tx) => ({
        ...tx,
        deviceName: devices?.find(
          (device) => device.deviceshorttag === tx.deviceshorttag
        )?.name,
        userName: users?.find((user) => user.userid === tx.userid)?.name,
      })) || [];
    return allTxs;
  }, [query.data, devices, users]);

  const queryClient = useQueryClient();

  const refetch = useCallback(() => {
    queryClient.invalidateQueries(["report"]);
  }, [queryClient]);

  return { ...query, refetch, parsedData };
}

function parseProducts(data, products) {
  return data.map((tx) => {
    let txProducts = [];
    let items = [];
    if (tx?.items) {
      try {
        const parsedString = tx?.items
          ? tx?.items
          : // .replaceAll("\productid", '"productid"')
            // .replaceAll("qty", '"qty"')
            // .replaceAll("price", '"price"')
            // .replaceAll("total", '"total"')
            "";
        // console.log("parsedString", parsedString);
        items = JSON.parse(parsedString);
      } catch (e) {
        console.error(e);
      }
      if (Array.isArray(items)) {
        txProducts = items?.map((item) => {
          const product = products?.find(
            (product) =>
              parseInt(product.productid) === parseInt(item.productid)
          );
          if (product === undefined) {
            // console.error("Product not found", item, products);
            return { ...item };
          }
          return {
            ...item,
            name: product.name,
          };
        });
      }
      return {
        ...tx,
        products: txProducts,
      };
    } else {
      return { ...tx, products: [] };
    }
  });
}

export function useTagHistory({ tag, ...props }) {
  const reports = useReports(props);
  const { products } = useProducts();
  const filteredTagHistory = useMemo(() => {
    return parseProducts(
      reports.parsedData.filter((tx) => tx.tag === tag),
      products
    );
  }, [reports.parsedData, tag, products]);
  return { ...reports, data: filteredTagHistory };
}

export function useLoads(props) {
  const reports = useReports(props);

  const loads = useMemo(() => {
    if (Array.isArray(reports.parsedData)) {
      return reports.parsedData.filter(
        (tx) =>
          tx.type === TX_TYPES.topUp.value ||
          tx.type === TX_TYPES.init.value ||
          tx.type === TX_TYPES.refund.value ||
          tx.type === TX_TYPES.repair.value ||
          tx.type === TX_TYPES.wipe.value
      );
    }
    return [];
  }, [reports.parsedData]);

  console.log("loads", loads);

  return { ...reports, loads };
}

export function useTransactions(props) {
  const reports = useReports(props);
  const { products } = useProducts();

  const transactions = useMemo(() => {
    if (Array.isArray(reports.parsedData)) {
      return parseProducts(reports.parsedData, products);
    }
    return [];
  }, [reports.parsedData, products]);

  return { ...reports, transactions };
}

export function useSales(props) {
  const reports = useReports(props);
  const { products } = useProducts();

  const sales = useMemo(() => {
    if (Array.isArray(reports.parsedData)) {
      return parseProducts(
        reports.parsedData.filter(
          (tx) =>
            tx.type === TX_TYPES.sale.value || tx.type === TX_TYPES.return.value
        ),
        products
      );
    }
    return [];
  }, [reports.parsedData, products]);

  return { ...reports, sales };
}

export function useStock(props) {
  const reports = useReports(props);
  const { eventid } = props;
  const { sales } = useSales(props);
  const { products } = useProducts();

  const queryClient = useQueryClient();

  const restocksQuery = useQuery({
    queryKey: ["restocks", eventid],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        // get restocks from local storage
        const restocks = JSON.parse(localStorage.getItem("restocks"));
        if (typeof restocks[eventid] !== "undefined" && restocks[eventid]) {
          resolve(restocks[eventid]);
        } else {
          resolve([]);
        }
      });
    },
    enabled: !!eventid,
  });

  const restockMutation = useMutation({
    mutationFn: async ({ productid, qty, total, isPositive, notes = "" }) => {
      return new Promise((resolve, reject) => {
        console.log("isPositive", isPositive);
        const restock = {
          productid,
          qty: isPositive * Math.abs(qty),
          total: isPositive * Math.abs(total),
          date: Math.floor(new Date().getTime() / 1000),
          notes,
        };
        // get restocks from local storage
        let restocks = {};
        try {
          restocks = JSON.parse(localStorage.getItem("restocks"));
        } catch (e) {
          console.error(e);
        }
        if (typeof restocks !== "object" || restocks === null) {
          restocks = { [eventid]: [] };
        }
        if (typeof restocks[eventid] === "undefined") {
          restocks[eventid] = [];
        }
        restocks[eventid].push(restock);
        localStorage.setItem("restocks", JSON.stringify(restocks));
        resolve(restock);
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("restocks");
    },
  });

  const stock = useMemo(() => {
    const result = {};
    sales.forEach((sale) => {
      const { products: _products, items, total = 0, ...onlyTxInfo } = sale;
      sale.products.forEach((product) => {
        if (result[product.productid] === undefined) {
          let { total = 0, ...productInfo } =
            products?.find((p) => p.productid === product.productid) || {};
          if (Object.keys(productInfo).length === 0) {
            productInfo = {
              name: `#${product.productid}`,
              productid: product.productid,
            };
          }
          result[product.productid] = {
            ...productInfo,
            qty: 0,
            total: 0,
            txs: [],
          };
        }
        result[product.productid].txs.push({ ...onlyTxInfo, ...product });
        result[product.productid].qty += parseInt(product.qty);
        result[product.productid].total += parseFloat(product.total);
      });
    });
    return result;
  }, [sales, products]);

  const parsedRestockData = useMemo(() => {
    if (Array.isArray(restocksQuery.data)) {
      return restocksQuery.data.map((restock) => {
        const product = products?.find(
          (product) => product.productid === restock.productid
        );
        return {
          ...restock,
          name: product?.name || `#${restock.productid}`,
        };
      });
    }
    return [];
  }, [restocksQuery.data, products]);

  const mergedData = useMemo(() => {
    const result = [
      ...(!stock
        ? []
        : Object.values(stock).map((product) => ({
            ...product,
            total: -parseFloat(product.total),
            qty: -parseInt(product.qty),
            date: product.txs.reduce(
              (acc, tx) => [
                Math.min(acc[0], tx.date),
                Math.max(acc[1], tx.date),
              ],
              [Math.ceil(new Date().getTime() / 1000), 0]
            ),
          }))),
      ...(!parsedRestockData ? [] : parsedRestockData).map((restock) => ({
        ...restock,
        qty: parseInt(restock.qty),
        total: parseFloat(restock.total),
        type: "restock",
        txs: [],
      })),
    ];
    return result;
  }, [parsedRestockData, stock]);

  return {
    ...reports,
    stock: mergedData,
    restocksQuery,
    restocks: parsedRestockData,
    restockMutation,
    restock: restockMutation.mutate,
  };
}

export function useSummary(props) {
  const reports = useReports(props);
  const { products } = useProducts();
  const { locations } = useLocations({ eventid: props.eventid });
  const { currencies } = useCurrencies();
  const { data: users } = useUsers();
  const { paymentMethods } = useCurrencies();
  const { parsedData, dataUpdatedAt } = reports;
  const summary = useMemo(() => {
    console.log("update private summary", dataUpdatedAt);
    return parseSummary(
      parsedData,
      paymentMethods,
      products,
      users,
      locations,
      currencies
    );
  }, [
    parsedData,
    paymentMethods,
    products,
    users,
    locations,
    currencies,
    dataUpdatedAt,
  ]);
  return { ...reports, ...summary };
}
