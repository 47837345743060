import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Button, FormControl } from "react-bootstrap";
import styles from "./DateRange.module.scss";
import useLang, { T } from "../../lang/useLang";
import { useSearchParams } from "react-router-dom";

import * as date from "date-fns";
import { DateRangePicker } from "react-date-range";
import { es, enUS } from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

// locale timezone
const timeZoneOffset = new Date().getTimezoneOffset() * 60;

export default function DateRange({
  start: defaultStart,
  end: defaultEnd,
  setStart: setDefaultStart,
  setEnd: setDefaultEnd,
  type,
  options = [],
  startSearchKey = "st",
  endSearchKey = "ed",
  enableSearchParams = true,
}) {
  const { trans } = useLang();
  const [editing, setEditing] = useState(false);
  const [start, setStart] = useState(defaultStart);
  const [end, setEnd] = useState(defaultEnd);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (enableSearchParams) {
      const spStart = searchParams.get(startSearchKey);
      const spEnd = searchParams.get(endSearchKey);
      if (spStart) {
        setDefaultStart(parseInt(spStart));
        setStart(parseInt(spStart));
      }
      if (spEnd) {
        setDefaultEnd(parseInt(spEnd));
        setEnd(parseInt(spEnd));
      }
    }
  }, [
    searchParams,
    setDefaultStart,
    setDefaultEnd,
    startSearchKey,
    endSearchKey,
    enableSearchParams,
  ]);

  useEffect(() => {
    if (enableSearchParams) {
      if (defaultStart) {
        searchParams.set(startSearchKey, defaultStart);
      }
      setSearchParams(searchParams);
    }
  }, [
    defaultStart,
    searchParams,
    setSearchParams,
    startSearchKey,
    enableSearchParams,
  ]);

  useEffect(() => {
    if (enableSearchParams) {
      if (defaultEnd) {
        searchParams.set(endSearchKey, defaultEnd);
      }
      setSearchParams(searchParams);
    }
  }, [
    defaultEnd,
    searchParams,
    setSearchParams,
    endSearchKey,
    enableSearchParams,
  ]);

  const handleSubmit = useCallback(() => {
    setDefaultStart(start);
    setDefaultEnd(end);
    setEditing(false);
  }, [start, end, setDefaultStart, setDefaultEnd]);

  const handleCancel = useCallback(() => {
    setStart(defaultStart);
    setEnd(defaultEnd);
    setEditing(false);
  }, [defaultStart, defaultEnd]);

  return (
    <>
      <div onClick={() => setEditing(true)} className={styles.DateRangeWrapper}>
        <FontAwesomeIcon icon={faClock} />
        <div style={{ margin: 0, flexGrow: 1, textAlign: "left" }}>
          {defaultStart
            ? new Date(defaultStart * 1000).toLocaleString()
            : trans("daterange.choosestart")}
        </div>

        <div style={{ margin: 0, flexGrow: 1, textAlign: "left" }}>
          {defaultEnd
            ? new Date(defaultEnd * 1000).toLocaleString()
            : trans("daterange.chooseend")}
        </div>
      </div>

      {editing && (
        <DateRangeForm
          start={start}
          end={end}
          setStart={setStart}
          setEnd={setEnd}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </>
  );
}

export function DateRangeForm({
  start,
  end,
  setStart,
  setEnd,
  onSubmit,
  onCancel,
}) {
  const { lang, trans } = useLang();
  const locale = useMemo(() => (lang === "es" ? es.er : enUS.enUS), [lang]);

  const handleSelect = useCallback(
    (ranges) => {
      const newStart = Math.floor(
        Object.values(ranges)[0].startDate.getTime() / 1000
      );
      const newEnd = Math.floor(
        Object.values(ranges)[0].endDate.getTime() / 1000
      );
      setStart(newStart);
      setEnd(newEnd);
    },
    [setStart, setEnd]
  );

  const selectionRange = useMemo(
    () => ({
      startDate: start ? new Date(start * 1000) : undefined,
      endDate: end ? new Date(end * 1000) : undefined,
    }),
    [start, end]
  );

  const defineds = useMemo(
    () => ({
      startOfWeek: startOfWeek(new Date()),
      endOfWeek: endOfWeek(new Date()),
      startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
      endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
      startOfToday: startOfDay(new Date()),
      endOfToday: endOfDay(new Date()),
      startOfYesterday: startOfDay(addDays(new Date(), -1)),
      endOfYesterday: endOfDay(addDays(new Date(), -1)),
      startOfMonth: startOfMonth(new Date()),
      endOfMonth: endOfMonth(new Date()),
      startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
      endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    }),
    []
  );

  const staticRangeHandler = useMemo(
    () => ({
      range: {},
      isSelected(range) {
        const definedRange = selectionRange;
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    }),
    []
  );

  const createStaticRanges = useCallback(
    function (ranges) {
      return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
    },
    [staticRangeHandler]
  );

  const defaultStaticRanges = useMemo(
    () =>
      createStaticRanges([
        {
          label: trans("daterange.today"),
          range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday,
          }),
        },
        {
          label: trans("daterange.yesterday"),
          range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday,
          }),
        },

        {
          label: trans("daterange.this_week"),
          range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
          }),
        },
        {
          label: trans("daterange.last_week"),
          range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek,
          }),
        },
        {
          label: trans("daterange.this_month"),
          range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
          }),
        },
        {
          label: trans("daterange.last_month"),
          range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
          }),
        },
      ]),
    [defineds, createStaticRanges, trans]
  );

  const defaultInputRanges = useMemo(
    () => [
      {
        label: trans("daterange.days_up_to_today"),
        range(value) {
          return {
            startDate: addDays(
              defineds.startOfToday,
              (Math.max(Number(value), 1) - 1) * -1
            ),
            endDate: defineds.endOfToday,
          };
        },
        getCurrentValue(range) {
          if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
          if (!range.startDate) return "∞";
          return (
            differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
          );
        },
      },
      // {
      //   label: trans("daterange.days_starting_today"),
      //   range(value) {
      //     const today = new Date();
      //     return {
      //       startDate: today,
      //       endDate: addDays(today, Math.max(Number(value), 1) - 1),
      //     };
      //   },
      //   getCurrentValue(range) {
      //     if (!isSameDay(range.startDate, defineds.startOfToday)) return "-";
      //     if (!range.endDate) return "∞";
      //     return (
      //       differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1
      //     );
      //   },
      // },
    ],
    [defineds.endOfToday, defineds.startOfToday, trans]
  );

  const handleSetStartTime = useCallback(
    (time) => {
      const dateStart = new Date(start * 1000 || new Date().getTime());
      const [hours, minutes] = time.split(":");
      dateStart.setHours(hours);
      dateStart.setMinutes(minutes);
      setStart(Math.floor(dateStart.getTime() / 1000));
    },
    [start, setStart]
  );

  const handleSetEndTime = useCallback(
    (time) => {
      const dateEnd = new Date(end * 1000 || new Date().getTime());
      const [hours, minutes] = time.split(":");
      dateEnd.setHours(hours);
      dateEnd.setMinutes(minutes);
      setEnd(Math.floor(dateEnd.getTime() / 1000));
    },
    [end, setEnd]
  );

  return (
    <div className={styles.DateRangeOverlay} onClick={onCancel}>
      <div onClick={(e) => e.stopPropagation()}>
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            width: "27.667em",
          }}
          className="rdrDateDisplayWrapper"
        >
          <div
            className="rdrDateDisplay"
            style={{ width: "100%", display: "flex", marginBottom: 0 }}
          >
            <TimeInput value={start} onChange={handleSetStartTime} />
            <TimeInput value={end} onChange={handleSetEndTime} />
          </div>
        </div>
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
          locale={locale}
          staticRanges={defaultStaticRanges}
          inputRanges={defaultInputRanges}
          rangeColors={["var(--bs-primary)"]}
        />
        <Button variant="secondary" onClick={onSubmit} style={{ fontSize: 14 }}>
          {trans("daterange.cancel")}
        </Button>
        <Button variant="primary" onClick={onSubmit} style={{ fontSize: 14 }}>
          {trans("daterange.submit")}
        </Button>
      </div>
    </div>
  );
}

function TimeInput({ value, onChange, ...props }) {
  const inputValue = useMemo(() => {
    const [hours, mins] = value
      ? new Date(value * 1000)
          .toISOString()
          .split("T")[1]
          .split(":")
          .slice(0, 2)
      : [0, 0];
    const adjustedHours =
      (parseInt(hours) - Math.floor(timeZoneOffset / 60 / 60) + 24) % 24;
    const adjustedMinutes =
      (parseInt(mins) - Math.floor((timeZoneOffset / 60) % 60) + 60) % 60;
    return `${("00" + adjustedHours).slice(-2)}:${(
      "00" + adjustedMinutes
    ).slice(-2)}`;
  }, [value]);

  // return <pre>{JSON.stringify(inputValue)}</pre>;

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     flexDirection: "column",
    //     alignItems: "stretch",
    //     width: "100%",
    //   }}
    // >
    <FormControl
      type="time"
      value={inputValue}
      onChange={(e) => onChange(e.target.value)}
      className="rdrDateDisplayItem"
      style={{ fontSize: 12, width: "auto" }}
      {...props}
    />
    // </div>
  );
}
