import { TABLE_TYPES, VARIOUS_VALUES } from "./FastTable";

export default function groupData(groups, data, columns) {
  // console.log("rerender from groupedData");
  if (groups.length === 0 || (groups.length === 1 && groups[0] === "")) {
    return [];
  }
  // set initial value for each column
  const initialValues = columns.reduce((accIni, col) => {
    if (typeof col?.aggregateInitialValue !== "undefined") {
      accIni[col.key] = col.aggregateInitialValue;
    } else {
      // accIni[col.key] = 0;
    }
    console.log("accIni", accIni);
    return accIni;
  }, {});
  return Object.values(
    Object.values(data).reduce((acc, row) => {
      let groupKey = groups
        .map((group) => {
          const column = columns.find((col) => col.key === group);
          if (typeof column?.filterValue === "function") {
            return column.filterValue(row);
          } else {
            switch (column?.type) {
              case TABLE_TYPES.dateUnixDate:
                return new Date(row[group] * 1000).toLocaleDateString();
              case TABLE_TYPES.dateUnixTime:
                return new Date(row[group] * 1000).toLocaleTimeString();
              default:
                break;
            }
          }
          return row[group];
        })
        .join("-");
      if (!acc[groupKey]) {
        acc[groupKey] = { ...initialValues };
      }
      const aggregated = aggregate({ ...row }, { ...acc[groupKey] }, columns);
      acc[groupKey] = aggregated;

      return acc;
    }, {})
  );
}

function aggregate(row, acc, columns) {
  return columns.reduce((resultAcc, col) => {
    const key = col?.sourceKey ? col.sourceKey : col.key;
    let value = row[key];
    let keyValue = value;
    let compareValue = acc[col.key];
    if (compareValue === VARIOUS_VALUES) {
      // skip already various values
      return resultAcc;
    }
    switch (col?.type) {
      case TABLE_TYPES.dateUnixDate:
        console.log("compareValue", compareValue, keyValue);
        const compareDateStr = new Date(
          acc[col.key] * 1000
        ).toLocaleDateString();
        const currDateStr = new Date(row[key] * 1000).toLocaleDateString();
        if (compareDateStr === currDateStr) {
          acc[col.key] = keyValue;
        } else {
          acc[col.key] = VARIOUS_VALUES;
        }
        console.log(
          "--> compareValue",
          currDateStr,
          keyValue,
          compareDateStr,
          acc[col.key]
        );
        return { ...acc, [col.key]: acc[col.key] };
      case TABLE_TYPES.dateUnixTime:
        console.log("compareValue", compareValue, keyValue);
        const compareTimeStr = new Date(
          acc[col.key] * 1000
        ).toLocaleTimeString();
        const currTimeStr = new Date(row[key] * 1000).toLocaleTimeString();
        if (compareTimeStr === currTimeStr) {
          acc[col.key] = keyValue;
        } else {
          acc[col.key] = VARIOUS_VALUES;
        }
        console.log(
          "--> compareValue",
          currTimeStr,
          keyValue,
          compareTimeStr,
          acc[col.key]
        );
        return { ...acc, [col.key]: acc[col.key] };
      default:
        break;
    }
    if (typeof col?.filterValue === "function") {
      keyValue = col.filterValue(row);
      compareValue = col.filterValue(acc);
    }
    if (col?.aggregate) {
      acc[col.key] = col.aggregate(acc[col.key], row);
    } else if (col?.totals) {
      acc[col.key] = (parseFloat(acc[col.key]) || 0) + parseFloat(value);
    } else if (acc[col.key] === undefined) {
      acc[col.key] = keyValue;
    } else if (compareValue !== keyValue) {
      acc[col.key] = VARIOUS_VALUES;
    }
    resultAcc[col.key] = acc[col.key];
    return resultAcc;
  }, acc);
}
