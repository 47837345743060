import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import styles from "./FastGroups.module.scss";
import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

export default function FastGroups({ groups, setGroups, columns }) {
  const [key, setKey] = useState([]);

  const handleSetKey = useCallback(
    (e) => {
      const newKey = e.target.value;
      setKey(newKey);
      setGroups([newKey]);
    },
    [setGroups]
  );

  return (
    <Container>
      <Form className="row">
        <Col className={styles.FastGroupContainer}>
          <h2 className="small" style={{display: "flex", alignItems: "center"}}>
            <FontAwesomeIcon icon={faLayerGroup} />&nbsp;Agrupar
          </h2>
          <Form.Select onChange={handleSetKey} style={{fontSize: 14}}>
            <option value="">Seleccione una columna</option>
            {columns
              .filter(
                (col) =>
                  typeof col.groupBy === "undefined" || col?.groupBy === true
              )
              .map((col) => (
                <option key={col.key} value={col.key}>
                  {col.label}
                </option>
              ))}
          </Form.Select>
        </Col>
      </Form>
    </Container>
  );
}
