import { Col, Container, Row, Spinner } from "react-bootstrap";
import SideBar from "./SideBar";
import { useRouteMiddleWare } from "../routes";
import { useAuth } from "../hooks/useAuth";
import { Outlet } from "react-router-dom";
import styles from "./Layout.module.scss";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { GlobalAlertProvider } from "../hooks/useGlobalAlert";

export default function Layout() {
  const { ready, user } = useAuth();
  useRouteMiddleWare();

  return (
    <GlobalAlertProvider>
      <div className={styles.Layout}>
        <Container fluid className={styles.Container}>
          {/* Main navigation */}
          <NavBar />
          <Row>
            {/* Sidebar */}
            {user && <SideBar />}
            {/* Content */}
            <Col
              className={`${styles.Content}${!user ? styles.FullWidth : ""}`}
            >
              {ready ? <Outlet /> : <Spinner animation="border" />}
              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
    </GlobalAlertProvider>
  );
}
