import { Button, Col, Row } from "react-bootstrap";
import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import useLocations, { LOCATION_TYPES } from "../../hooks/useLocations";
import FastForm from "../../components/FastForm/FastForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";
import useEvents from "../../hooks/useEvents";
import useCurrencies from "../../hooks/useCurrencies";

export default function Locations() {
  const params = useParams();
  const { user } = useAuth();
  const { data: events } = useEvents();
  const { paymentMethods } = useCurrencies();
  const orgid = user?.orgid;
  const { eventid, locationid } = params;
  const navigate = useNavigate();
  const {
    locationsQuery,
    createLocationMutation,
    editLocation,
    editLocationMutation,
    location,
  } = useLocations({ eventid, locationid, orgid });
  usePage({ title: locationid ? `Ubic. ${location?.name}` : "Ubicaciones" });

  useEffect(() => {
    console.log("UseEffect locations");
  }, [locationsQuery?.data]);

  return (
    <>
      <Row>
        <Col>
          <h1>Ubicaciones</h1>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <h2>Crear ubicación</h2>
          <FastForm
            mutation={createLocationMutation}
            fields={[
              {
                name: "eventid",
                type: "hidden",
                defaultValue: parseInt(params?.eventid),
                attributes: { readOnly: true },
              },
              {
                name: "orgid",
                type: "hidden",
                defaultValue: parseInt(user?.orgid),
                attributes: { readOnly: true },
              },
              {
                name: "name",
                label: "Nombre",
                type: "text",
                required: true,
              },
              {
                name: "type",
                label: "Tipo",
                type: "select",
                options: [
                  { label: "Elige un tipo de ubicación", value: "" },
                  ...Object.values(LOCATION_TYPES),
                ],
              },
              {
                name: "paymentmethods",
                label: "Métodos de pago",
                type: "multi-select",
                defaultValue: [],
                options: [
                  ...(paymentMethods || []).map((pm) => ({
                    key: pm.paymentmethodid,
                    label: pm.name,
                  })),
                ],
              },
            ]}
          />
        </Col>
        <Col md={9}>
          <h2>Ubicaciones</h2>
          <FastTable
            {...locationsQuery}
            columns={[
              {
                key: "locationid",
                label: "ID",
                style: { width: 50 },
                editable: true,
                setValue: (row, value) =>
                  editLocation({
                    name: row.name,
                    orgid: row.orgid,
                    eventid: row.eventid,
                    locationid: value,
                    type: row.type,
                  }),
              },
              {
                key: "name",
                label: "Nombre",
                type: "string",
                editable: true,
                setValue: (row, value) =>
                  editLocation({
                    name: value,
                    orgid: row.orgid,
                    eventid: row.eventid,
                    locationid: row.locationid,
                    type: row.type,
                  }),
              },
              {
                key: "type",
                label: "Tipo",
                type: "string",
                render: (row) =>
                  Object.values(LOCATION_TYPES).find(
                    (type) => type.value === parseInt(row.type)
                  )?.label,
                editable: true,
                editableOptions: Object.values(LOCATION_TYPES),
                setValue: (row, value) =>
                  editLocation({
                    name: row.name,
                    orgid: row.orgid,
                    eventid: row.eventid,
                    locationid: row.locationid,
                    type: value,
                  }),
              },
              {
                key: "paymentmethods",
                label: "Métodos de pago",
                type: "string",
                render: (row) =>
                  row?.paymentmethods
                    ? row?.paymentmethods
                        .split(",")
                        .map((pmid) => {
                          const found = paymentMethods.find(
                            (pm) => pm.paymentmethodid === pmid
                          );
                          if (found) {
                            return found.name;
                          }
                          return null;
                        })
                        .filter((pmname) => !!pmname)
                        .join(", ")
                    : "-",
              },
              // {
              //   key: "status",
              //   label: "Estado",
              //   type: "string",
              //   render: (row) => (row.status === 1 ? "Activo" : "Inactivo"),
              //   editable: false,
              //   editableOptions: [
              //     { label: "Activo", value: 1 },
              //     { label: "Inactivo", value: 0 },
              //   ],
              //   setValue: (row, value) =>
              //     editLocation({
              //       name: row.name,
              //       orgid: row.orgid,
              //       eventid: row.eventid,
              //       locationid: row.locationid,
              //       type: row.type,
              //       status: value,
              //     }),
              // },
            ]}
            onSelectRow={(row) =>
              navigate(`/${eventid}/locations/${row.locationid}`)
            }
          />
          <hr />
          {locationid && location && (
            <>
              <Row>
                <Col xs={12}></Col>
                <Col
                  xs={10}
                  md={11}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h3>{location.name}</h3>
                </Col>
                <Col xs={2} md={1} className="text-end">
                  <Button onClick={() => navigate(`/${eventid}/locations`)}>
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FastForm
                    key={locationid}
                    mutation={editLocationMutation}
                    fields={[
                      {
                        name: "name",
                        label: "Nombre",
                        type: "text",
                        defaultValue: location.name,
                      },
                      {
                        name: "locationid",
                        type: "hidden",
                        defaultValue: location.locationid,
                      },
                      {
                        name: "orgid",
                        type: "hidden",
                        defaultValue: location.orgid,
                      },
                      {
                        name: "type",
                        label: "Tipo",
                        type: "select",
                        defaultValue: location.type,
                        options: [
                          { label: "Elige un tipo de ubicación", value: "" },
                          ...Object.values(LOCATION_TYPES),
                        ],
                      },
                      {
                        name: "eventid",
                        label: "Evento",
                        type: "select",
                        defaultValue: location.eventid,
                        options: [
                          { label: "Elija un evento", value: "" },
                          ...events.map((event) => ({
                            label: event.name,
                            value: event.eventid,
                          })),
                        ],
                      },
                      {
                        name: "paymentmethods",
                        label: "Métodos de pago",
                        type: "multi-select",
                        defaultValue: location.paymentmethods?.split(",") || [],
                        options: [
                          ...paymentMethods.map((pm) => ({
                            key: pm.paymentmethodid,
                            label: pm.name,
                          })),
                        ],
                      },
                    ]}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
