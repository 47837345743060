import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useEffect, useMemo, useState } from "react";
import MultiSelectDropDown from "../MultiSelectDropdown";
import styles from "./FastFilter.module.scss";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFilter } from "@fortawesome/free-solid-svg-icons";
import { TABLE_TYPES } from "./FastTable";

export const BOOL_AND = "&";
export const BOOL_NOT_AND = "& not";
export const BOOL_OR = "or";
export const BOOL_NOT_OR = "or not";
export const BOOL_GT = ">=";
export const BOOL_ST = "<";

export default function FastFilter({
  data,
  setFilters,
  columns,
  updateSearchParams = true,
}) {
  const [collapse, setCollapse] = useState(true);
  const [ready, setReady] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyValuePairs, setKeyValuePairs] = useState([
    { key: "", values: [], options: {}, operation: BOOL_AND },
  ]);

  useEffect(() => {
    if (setFilters) {
      const filtersToSet = [...keyValuePairs];
      setFilters(
        filtersToSet.filter((pair) => pair.key !== "" && pair.values.length > 0)
      );
    }
  }, [keyValuePairs, setFilters]);

  useEffect(() => {
    if (!ready) {
      if (updateSearchParams) {
        const keys = new URLSearchParams(searchParams).get("kvp");
        try {
          console.log("keys", keys);
          if (typeof keys === "string" && keys.length > 0) {
            const json = JSON.parse(keys);
            console.log("json", json);
            if (json) {
              setKeyValuePairs(json);
            }
          }
        } catch (e) {
          console.warn(e);
        }
      }
      setReady(true);
    }
  }, [ready, updateSearchParams, searchParams]);

  useEffect(() => {
    if (updateSearchParams) {
      const urlParams = new URLSearchParams(searchParams);
      const validKVP = keyValuePairs
        .filter((pair) => pair.key !== "" && pair.values.length > 0)
        .map((pair) => {
          const { key, values, operation } = pair;
          return { key, values, operation };
        });
      if (validKVP.length === 0) {
        urlParams.delete("kvp");
      } else {
        urlParams.set("kvp", JSON.stringify(validKVP));
      }
      setSearchParams(urlParams);
    }
  }, [keyValuePairs, updateSearchParams, setSearchParams, searchParams]);

  const pairs = useMemo(() => {
    let result = [];
    let hasEmpty = false;
    if (
      Array.isArray(keyValuePairs) &&
      keyValuePairs.length > 0 &&
      Array.isArray(data) &&
      data.length > 0
    ) {
      result = keyValuePairs.map((pair) => {
        if (pair.key !== "") {
          const column = columns.find((col) => col.key === pair.key);
          const options = data.reduce((acc, row) => {
            let value = row[pair.key];
            let label = value;
            if (column) {
              if (typeof column?.filterValue === "function") {
                value = column.filterValue(row);
              }
              label = value;
              if (typeof column?.render === "function") {
                label = column.render({ [pair.key]: value });
              } else {
                switch (column?.type) {
                  case TABLE_TYPES.options:
                    const option = column.options.find(
                      (opt) => opt.value === value
                    );
                    if (option) {
                      label = option.label;
                    }
                    break;
                  case TABLE_TYPES.dateUnixDate:
                    value = label = new Date(value * 1000).toLocaleDateString();
                    break;
                  case TABLE_TYPES.dateUnixTime:
                    value = label = new Date(value * 1000).toLocaleTimeString();
                    break;
                  case TABLE_TYPES.currency:
                    label = `${column.prefix || ""}${value}`;
                    break;
                  default:
                    break;
                }
              }
            }
            if (!acc[value]) {
              acc[value] = { count: 0, label: label };
            }
            acc[value].count += 1;
            return acc;
          }, {});
          return { ...pair, options };
        } else {
          hasEmpty = true;
        }
        return pair;
      });
    }
    if (!hasEmpty) {
      result.push({ key: "", values: [], options: {}, operation: BOOL_AND });
    }
    return result;
  }, [keyValuePairs, data, columns]);

  if (!ready) return null;

  return (
    <Container
      className={[
        styles.FastFilter,
        !collapse ? styles.FastFilterShow : "",
      ].join(" ")}
      fluid
    >
      <Row>
        <Col>
          <Form className="row">
            <Button
              className={styles.ToggleButton}
              onClick={() => setCollapse(!collapse)}
              variant="secondary"
            >
               <FontAwesomeIcon icon={faFilter} /> <span>Filtros</span>
            </Button>
            <div>
              {pairs.map((keyValue, index) => (
                <div key={index} className={styles.SingleFilter}>
                  {index !== 0 && (
                    <Form.Select
                      value={keyValue.operation}
                      onChange={(e) => {
                        setKeyValuePairs((prev) => {
                          try {
                            pairs[index].operation = e.target.value;
                            return [...pairs];
                          } catch (e) {
                            console.warn(e);
                          }
                          return [...prev];
                        });
                      }}
                      className={styles.SelectOperation}
                    >
                      <option value={BOOL_AND}>{BOOL_AND}</option>
                      <option value={BOOL_OR}>{BOOL_OR}</option>
                      <option value={BOOL_NOT_AND}>{BOOL_NOT_AND}</option>
                      <option value={BOOL_NOT_OR}>{BOOL_NOT_OR}</option>
                      <option value={BOOL_GT}>{BOOL_GT}</option>
                      <option value={BOOL_ST}>{BOOL_ST}</option>
                    </Form.Select>
                  )}
                  {(!collapse || index !== 0 || keyValue.key !== "") && (
                    <Form.Select
                      value={keyValue.key}
                      onChange={(e) => {
                        setKeyValuePairs((prev) => {
                          try {
                            pairs[index].key = e.target.value;
                            return [...pairs];
                          } catch (e) {
                            console.warn(e);
                          }
                          return [...prev];
                        });
                      }}
                      disabled={keyValue.values.length > 0}
                      className={styles.SelectKey}
                    >
                      <option value="">Elija la clave</option>
                      {columns.map((col) => (
                        <option key={col.key} value={col.key}>
                          {col.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                  {keyValue.key !== "" || keyValue.values.length ? (
                    <>
                      <MultiSelectDropDown
                        options={Object.keys(keyValue.options).map((key) => {
                          return {
                            key,
                            label: `${keyValue.options[key].label} (${keyValue.options[key].count})`,
                          };
                        })}
                        value={keyValue.values}
                        onChange={(selected) => {
                          setKeyValuePairs(() => {
                            pairs[index].values = selected;
                            return [...pairs];
                          });
                        }}
                        className={styles.SelectValue}
                      />
                      <Button
                        onClick={() => {
                          setKeyValuePairs(() => {
                            pairs.splice(index, 1);
                            return [...pairs];
                          });
                        }}
                        className={styles.ButtonDelete}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </Button>
                    </>
                  ) : null}
                </div>
              ))}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
