import { useTagHistory } from "../hooks/useReports";
import useUsers from "../hooks/useUsers";
import FastTable, { TABLE_TYPES } from "./FastTable/FastTable";

export default function SingleTagTxs({ tag, start, end, eventid }) {
  const { data } = useUsers({ offset: 0, limit: 1000, filter: { tag: tag } });
  const { data: data2 } = useTagHistory({
    offset: 0,
    limit: 1000,
    tag,
    start,
    end,
    eventid,
  });

  return (
    <div>
      <h2>{tag}</h2>
      <FastTable
        data={data2}
        columns={[
          { key: "txid", label: "txid", type: TABLE_TYPES.string },
          { key: "offtxid", label: "offtxid", type: TABLE_TYPES.string },
          { key: "type", label: "type", type: TABLE_TYPES.string },
          { key: "userid", label: "userid", type: TABLE_TYPES.string },
          { key: "currencyid", label: "currencyid", type: TABLE_TYPES.string },
          { key: "amount", label: "amount", type: TABLE_TYPES.string },
          { key: "tag", label: "tag", type: TABLE_TYPES.string },
          { key: "date", label: "date", type: TABLE_TYPES.dateUnixDate },
          { key: "date", label: "date", type: TABLE_TYPES.dateUnixTime },
          { key: "items", label: "items", type: TABLE_TYPES.string },
          { key: "locationid", label: "locationid", type: TABLE_TYPES.string },
          {
            key: "paymentmethodid",
            label: "paymentmethodid",
            type: TABLE_TYPES.string,
          },
          { key: "status", label: "status", type: TABLE_TYPES.string },
          { key: "deviceName", label: "deviceName", type: TABLE_TYPES.string },
          { key: "userName", label: "userName", type: TABLE_TYPES.string },
        ]}
      />
    </div>
  );
}
