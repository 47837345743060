import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";

const GlobalAlertContext = createContext();

export function GlobalAlertProvider({ children }) {
  const alertRef = useRef();
  const [alert, setAlert] = useState(null);

  const handleSetAlert = (props, timeout = 5000) => {
    const uniqueid = Math.floor(Math.random() * 100000);
    setAlert({ ...props, uniqueid });
    setTimeout(() => {
      if (alertRef.current.uniqueid === uniqueid) {
        setAlert(null);
      }
    }, timeout);
  };

  useEffect(() => {
    alertRef.current = { ...alert };
  }, [alert]);

  return (
    <GlobalAlertContext.Provider value={{ setAlert: handleSetAlert }}>
      {alert && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert(null)}
          dismissible
          style={{
            position: "absolute",
            top: "0.5rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {alert.message}
        </Alert>
      )}
      {children}
    </GlobalAlertContext.Provider>
  );
}

export function useGlobalAlert() {
  // check if the context is being used outside of the provider
  const context = useContext(GlobalAlertContext);
  if (!context) {
    throw new Error("useGlobalAlert must be used within a GlobalAlertProvider");
  }
  return context;
}
