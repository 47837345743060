import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import styles from "./UpdateReportButton.module.scss";

export default function UpdateReportButton({
  refetch,
  dataUpdatedAt,
  isLoading,
  isRefetching,
}) {
  return (
    <Button style={{ cursor: "pointer" }} onClick={() => refetch()}>
      {`Última actualización: ${
        !!dataUpdatedAt ? new Date(dataUpdatedAt).toLocaleString() : " - "
      }`}
      <FontAwesomeIcon
        icon={faSyncAlt}
        style={{ marginLeft: 10 }}
        className={isLoading || isRefetching ? styles.Rotating : ""}
      />
    </Button>
  );
}
