export default function calcTotals(data, columns) {
  if (!Array.isArray(data)) {
    console.trace("no data", data);
    return {};
  }
  return columns.reduce((accTotals, col) => {
    if (col.totals) {
      accTotals[col.key] = data.reduce((accSingleTotal, row) => {
        if(typeof col?.calcTotal === "function") {
          return col.calcTotal(accSingleTotal, row);
        }
        accSingleTotal += parseFloat(row[col.key]);
        return accSingleTotal;
      }, 0);
    }
    return accTotals;
  }, {});
}
