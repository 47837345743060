import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get, post } from "../utils/Api";
import { useGlobalAlert } from "./useGlobalAlert";
import { useAuth } from "./useAuth";

export default function useDevices({ eventid }) {
  const queryClient = useQueryClient();
  const { setAlert } = useGlobalAlert();
  const { user } = useAuth();

  const devicesQuery = useQuery({
    queryKey: ["devices", user?.orgid],
    queryFn: async () => {
      const params = { token: user?.token };
      if (user?.orgid) params.orgid = user?.orgid;
      if (eventid) params.eventid = eventid;
      return post("/devices", params);
    },
    enabled: !!user?.orgid && !!user?.token,
    refetchInterval: 10000,
  });

  const registerDeviceMutation = useMutation({
    mutationFn: async ({ deviceshorttag, name, serial, orgid }) => {
      const params = { token: user?.token, name, deviceshorttag, serial };
      if (orgid) params.orgid = orgid;
      if (eventid) params.eventid = eventid;
      return post("/registerdevice", params);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["devices"]);
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
    onSuccess: () => {
      setAlert({ variant: "success", message: "Terminal registrada" });
    },
  });

  const editDeviceMutation = useMutation({
    mutationFn: async ({
      deviceshorttag,
      serial,
      deviceid,
      orgid,
      eventid: newEventid,
      androidid,
      name,
    }) => {
      console.log("editDeviceMutation", {
        deviceshorttag,
        serial,
        deviceid,
        orgid,
        eventid: newEventid,
        androidid,
        name,
      });
      const params = { token: user?.token, deviceid };
      if (typeof deviceshorttag !== "undefined")
        params.deviceshorttag = deviceshorttag;
      if (typeof androidid !== "undefined") {
        if (androidid === "") {
          params.androidid = "reset";
        } else {
          params.androidid = androidid;
        }
      }
      if (typeof serial !== "undefined") params.serial = serial;
      if (typeof orgid !== "undefined") params.orgid = orgid;
      if (typeof name !== "undefined") params.name = name;
      if (typeof newEventid !== "undefined") {
        params.eventid = newEventid;
      } else if (typeof eventid !== "undefined") {
        params.eventid = eventid;
      }
      console.log("params", params);
      return post("/editdevice", params);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["devices"]);
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
    onSuccess: () => {
      setAlert({ variant: "success", message: "Terminal actualizada" });
    },
  });

  return {
    devicesQuery,
    devices: devicesQuery.data,
    registerDeviceMutation,
    registerDevice: registerDeviceMutation.mutate,
    editDeviceMutation,
    editDevice: editDeviceMutation.mutate,
  };
}
