export default function getContrastColor(colorStr) {
  const color = convertColorStrToHex(colorStr);
  const brightness = getBrightness(color);
  // console.log("------- color", colorStr, color, brightness);
  // return `rgb(${brightness},${brightness},${brightness})`; // return same brightness grey color
  if (brightness > 128) {
    return "#000000";
  } else {
    return "#ffffff";
  }
}

function getBrightness(color) {
  return color[0] * 0.299 + color[1] * 0.587 + color[2] * 0.114;
}

function convertColorStrToHex(colorStr) {
  if (/^#([A-Fa-f0-9]{8})$/.test(colorStr)) {
    return colorStr
      .match(
        /^#([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})$/
      )
      .slice(1)
      .map((c) => {
        // console.log("c", c);
        return parseInt(c, 16);
      });
  }
  if (/^#([A-Fa-f0-9]{6})$/.test(colorStr)) {
    return colorStr
      .match(/^#([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})$/)
      .slice(1)
      .map((c) => {
        // console.log("c", c);
        return parseInt(c, 16);
      });
  }
  if (/^#([A-Fa-f0-9]){4}$/.test(colorStr)) {
    return colorStr
      .match(/^#([A-Fa-f0-9]){3}$/)
      .slice(1)
      .map((c) => {
        // console.log("c", c);
        return parseInt(c, 16);
      });
  }
  if (/^#([A-Fa-f0-9])([A-Fa-f0-9])([A-Fa-f0-9])$/.test(colorStr)) {
    return colorStr
      .match(/^#([A-Fa-f0-9])([A-Fa-f0-9])([A-Fa-f0-9])$/)
      .slice(1)
      .map((c) => {
        // console.log("c", c);
        return parseInt(c, 16);
      });
  }

  if (/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/.test(colorStr)) {
    return colorStr
      .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
      .map((c) => parseInt(c));
  }

  if (/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)$/.test(colorStr)) {
    return colorStr
      .match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)$/)
      .map((c) => parseInt(c));
  }

  return [0, 0, 0];
}
