import { Col, Row } from "react-bootstrap";
import useCurrencies from "../../hooks/useCurrencies";
import FastTable, {
  ICONS,
  TABLE_TYPES,
} from "../../components/FastTable/FastTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePage } from "../../hooks/usePage";

export default function Currencies() {
  usePage({ title: "Monedas" });

  const {
    currencies,
    currencyGroups,
    paymentMethods,
    createCurrencyMutation,
    editCurrencyMutation,
    editMultipleCurrencies,
    deleteCurrencyMutation,
    createCurrencyGroupMutation,
    editCurrencyGroupMutation,
    deleteCurrencyGroupMutation,
    createPaymentMethodMutation,
    editPaymentMethodMutation,
    deletePaymentMethodMutation,
  } = useCurrencies();

  const handleChangeCurrencyPriority = (currency, dir) => {
    const index = currencies
      .sort((cA, cB) => (cA?.priority > cB.priority ? 1 : -1))
      .findIndex((c) => c.currencyid === currency.currencyid);
    const newCurrencies = currencies.map((c) => ({ ...c })); // Copy currencies
    const [movedCurrency] = newCurrencies.splice(index, 1);
    newCurrencies.splice(index + dir, 0, movedCurrency);
    const editedCurrencies = newCurrencies.filter((c, i) => {
      const newPriority = i + 1;
      if (c.priority === newPriority) return false;
      c.priority = newPriority;
      return true;
    });
    console.log(
      "editedCurrencies",
      currencies.map((c) => "(" + c.name + ":" + c.priority + ")").join(", ") +
        " ---> " +
        newCurrencies
          .map((c) => "(" + c.name + ":" + c.priority + ")")
          .join(", "),
      editedCurrencies.length
    );
    if (editedCurrencies.length > 0) {
      editMultipleCurrencies(editedCurrencies);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Monedas</h1>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <h2>Grupos de moneda</h2>
          <FastTable
            data={currencyGroups}
            fields={[
              { key: "currencygroupid", label: "ID", type: TABLE_TYPES.string },
              { key: "name", label: "Nombre", type: TABLE_TYPES.string },
            ]}
            showGroups={false}
            showFilters={false}
            showExcel={false}
            onCreateMutation={createCurrencyGroupMutation}
            onEditMutation={editCurrencyGroupMutation}
            onDeleteMutation={{
              ...deleteCurrencyGroupMutation,
              mutate: (data) => {
                // confirm alert before delete
                if (
                  window.confirm(
                    `¿Está seguro que desea eliminar el grupo de moneda "${data.name}"?`
                  )
                ) {
                  deleteCurrencyGroupMutation.mutate(data);
                }
              },
            }}
          />
        </Col>
      </Row> */}
      <Row>
        <Col>
          <h2>Monedas</h2>
          <FastTable
            data={currencies}
            showGroups={false}
            showFilters={false}
            showExcel={false}
            onCreateMutation={createCurrencyMutation}
            onEditMutation={editCurrencyMutation}
            onDeleteMutation={{
              ...deleteCurrencyMutation,
              mutate: (data) => {
                // confirm alert before delete
                if (
                  window.confirm(
                    `¿Está seguro que desea eliminar la moneda "${data.name}"?`
                  )
                ) {
                  deleteCurrencyMutation.mutate(data);
                }
              },
            }}
            columns={[
              {
                label: "ID",
                key: "currencyid",
                type: TABLE_TYPES.number,
                style: { width: 50 },
                nonEditable: true,
              },
              { label: "Nombre", key: "name", type: TABLE_TYPES.string },
              {
                label: "Prioridad",
                key: "priority",
                type: TABLE_TYPES.order,
                onChangeOrder: handleChangeCurrencyPriority,
                sortable: true,
              },
              { label: "Mínimo", key: "min", type: TABLE_TYPES.currency },
              { label: "Máximo", key: "max", type: TABLE_TYPES.currency },
              {
                key: "currencygroupid",
                label: "Grupo de moneda",
                type: "select",
                required: true,
                options: [
                  { value: "", label: "Elija el groupo" },
                  ...(currencyGroups?.map((currencyGroup) => ({
                    value: currencyGroup.currencygroupid,
                    label: currencyGroup.name,
                  })) || []),
                ],
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Métodos de pago</h2>
          <FastTable
            data={paymentMethods}
            columns={[
              {
                key: "paymentmethodid",
                label: "ID",
                style: { width: 50 },
                type: "string",
              },
              { key: "name", label: "Nombre", type: "string" },
              { key: "shortname", label: "Nombre corto", type: "string" },
              {
                key: "icon",
                label: "Icono",
                render: (row) =>
                  ICONS[row.icon] ? (
                    <FontAwesomeIcon icon={ICONS[row.icon]} />
                  ) : null,
                type: TABLE_TYPES.options,
                options: [
                  { value: "", label: "Elija el ícono" },
                  ...Object.keys(ICONS).map((key) => ({
                    value: key,
                    label: key.replaceAll("-", " "),
                  })),
                ],
                style: { textAlign: "center" },
              },
              {
                key: "currencyid",
                label: "Moneda",
                type: TABLE_TYPES.options,
                options: [
                  { value: "", label: "Elija la moneda" },
                  ...(currencies?.map((currency) => ({
                    value: currency.currencyid,
                    label: currency.name,
                  })) || []),
                ],
              },
              {
                key: "currencygroupid",
                label: "Grupo de moneda",
                type: "select",
                required: true,
                options: [
                  { value: "", label: "Elija el groupo" },
                  ...(currencyGroups?.map((currencyGroup) => ({
                    value: currencyGroup.currencygroupid,
                    label: currencyGroup.name,
                  })) || []),
                ],
              },
            ]}
            showGroups={false}
            showFilters={false}
            showExcel={false}
            onCreateMutation={createPaymentMethodMutation}
            onEditMutation={editPaymentMethodMutation}
            onDeleteMutation={{
              ...deletePaymentMethodMutation,
              mutate: (data) => {
                // confirm alert before delete
                if (
                  window.confirm(
                    `¿Está seguro que desea eliminar el método de pago "${data.name}"?`
                  )
                ) {
                  deletePaymentMethodMutation.mutate(data);
                }
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
}
