import { Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useProducts from "../../../hooks/useProducts";
import { useEffect, useMemo } from "react";
import FastForm from "../../../components/FastForm/FastForm";
import styles from "../Products.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../hooks/useAuth";
import useLocations from "../../../hooks/useLocations";

export default function EditDisplayForm({
  selectedDisplayid,
  setSelectedDisplay,
}) {
  const params = useParams();
  const { user } = useAuth();
  const { displays, deleteDisplayMutation, editDisplayMutation } = useProducts({
    productdisplayid: selectedDisplayid,
  });
  const { locations } = useLocations({ eventid: params.eventid });

  const selectedDisplay = useMemo(
    () =>
      displays?.find(
        (display) => display.productdisplayid === selectedDisplayid
      ),
    [displays, selectedDisplayid]
  );

  useEffect(() => {
    if (deleteDisplayMutation.status === "success") {
      setSelectedDisplay(null);
    }
  }, [deleteDisplayMutation.status, setSelectedDisplay]);

  return (
    <>
      <Row>
        <Col>
          <h2>Editar Display</h2>
        </Col>
        <Col className="text-end">
          <Button
            style={{
              padding: "0 5px",
              border: "none",
            }}
            onClick={() => setSelectedDisplay(null)}
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
        </Col>
      </Row>
      <FastForm
        key={selectedDisplayid}
        className={styles.DisplayForm}
        mutation={editDisplayMutation}
        fields={[
          {
            name: "eventid",
            type: "hidden",
            defaultValue: parseInt(params.eventid),
            attributes: { readOnly: true },
          },
          {
            name: "productdisplayid",
            type: "hidden",
            defaultValue: selectedDisplayid,
            attributes: { readOnly: true },
          },
          {
            name: "name",
            label: "Nombre",
            placeholder: "Nombre del display",
            defaultValue: selectedDisplay?.name,
          },
          {
            name: "color",
            label: "Color",
            placeholder: "Color",
            defaultValue: selectedDisplay?.color,
            type: "color",
          },
          {
            name: "displayorder",
            label: "Orden",
            placeholder: "Orden",
            defaultValue: selectedDisplay?.displayorder,
            type: "number",
            attributes: { min: 0, step: 1 },
          },
          {
            name: "ncolumns",
            label: "Columnas",
            placeholder: "Columnas",
            defaultValue: selectedDisplay?.ncolumns,
            type: "number",
            attributes: { min: 1, max: 5, step: 1 },
          },
          {
            name: "locationids",
            label: "Ubicaciones",
            placeholder: "Ubicaciones",
            type: "multi-select",
            defaultValue: selectedDisplay?.locationids.split(",") || [],
            options:
              locations?.map((location) => ({
                key: location.locationid,
                label: location.name,
              })) || [],
          },
        ]}
        sendText="Guardar cambios"
        clearOnSuccess={true}
        append={
          <>
            <Button
              variant="danger"
              className="btn-bordered"
              style={{ width: "100%" }}
              onClick={() =>
                deleteDisplayMutation.mutate({
                  orgid: user.orgid,
                  productdisplayid: selectedDisplayid,
                  name: selectedDisplay?.name,
                })
              }
            >
              Borrar display
            </Button>
          </>
        }
      />
    </>
  );
}
