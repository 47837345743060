import FastForm from "../../components/FastForm/FastForm";
import useEvents from "../../hooks/useEvents";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";
import useLang from "../../lang/useLang";

export default function CreateEvent() {
  const { trans } = useLang();
  usePage({ title: trans("event.createevent") });
  const { createEventMutation } = useEvents();
  const { user } = useAuth();

  return (
    <>
      <h1>{trans("event.createevent")}</h1>
      <FastForm
        mutation={createEventMutation}
        sendText={trans("event.createevent")}
        fields={[
          {
            name: "orgid",
            defaultValue: user.orgid,
            type: "hidden",
            readOnly: true,
          },
          {
            name: "name",
            label: trans("event.name"),
            placeholder: "Nombre",
            type: "text",
            attributes: { required: true },
          },
          {
            name: "start",
            label: trans("event.start"),
            placeholder: trans("event.start"),
            type: "datetime-local",
            attributes: { required: true },
          },
          {
            name: "end",
            label: trans("event.end"),
            placeholder: trans("event.end"),
            type: "datetime-local",
            attributes: { required: true },
          },
          {
            name: "activationfee",
            type: "hidden",
            attributes: { required: true },
            defaultValue: 0,
          },
          {
            name: "feecurrency",
            type: "hidden",
            attributes: { required: true },
            defaultValue: 0,
          },
        ]}
        postParseData={(data) => {
          return {
            ...data,
            start: Math.floor(new Date(data.start).getTime() / 1000),
            end: Math.floor(new Date(data.end).getTime() / 1000),
          };
        }}
      />
    </>
  );
}
