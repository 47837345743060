import useProducts from "../hooks/useProducts";
import FastForm from "./FastForm/FastForm";
import { useParams } from "react-router-dom";
import { PRODUCT_STATUS } from "../constants";
import useCurrencies from "../hooks/useCurrencies";
import { useAuth } from "../hooks/useAuth";

export default function CreateProductForm({ className = "" }) {
  const { user } = useAuth();
  const params = useParams();
  const { categoriesQuery, createProductMutation } = useProducts();
  const { currencies } = useCurrencies();

  const { data: categories } = categoriesQuery;

  return (
    <>
      <h1>Crear producto</h1>
      <hr className="small" />
      <FastForm
        className={[className].join(" ")}
        mutation={createProductMutation}
        sendText="Crear producto"
        fields={[
          { name: "eventid", type: "hidden", defaultValue: params.eventid },
          { name: "orgid", type: "hidden", defaultValue: user.orgid },
          { name: "name", label: "Nombre", type: "text" },
          {
            name: "categoryid",
            label: "Categoría",
            type: "select",
            options: categories
              ? categories.map((category) => ({
                  value: category.categoryid,
                  label: category.name,
                }))
              : [],
          },
          { name: "image", type: "hidden", label: "Imagen" },
          { name: "price", type: "number", label: "Precio", prefix: "$" },
          {
            name: "accepted_currencies",
            label: "Monedas aceptadas",
            type: "multi-select",
            defaultValue: (currencies || [])
              .filter(
                (currency) =>
                  currency.name.indexOf("Peso") !== -1 ||
                  currency.name.indexOf("Cortes") !== -1
              )
              .map((currency) => currency.currencyid),
            options:
              currencies?.map((currency) => ({
                key: currency.currencyid,
                label: currency.name,
              })) || [],
          },
          {
            name: "status",
            type: "select",
            label: "Estado",
            options: Object.keys(PRODUCT_STATUS).map((status) => ({
              value: status,
              label: PRODUCT_STATUS[status],
            })),
            defaultValue: 1,
          },
          {
            name: "vprice",
            type: "hidden",
            label: "Precio variable",
            prefix: "$",
          },
          {
            name: "isrefundable",
            type: "checkbox",
            label: "Reembolsable",
            defaultValue: false,
          },
          {
            name: "iscomposed",
            type: "hidden",
            label: "Compuesto",
            defaultValue: false,
          },
          {
            name: "ispack",
            type: "hidden",
            label: "Pack",
            defaultValue: false,
          },
        ]}
        parseData={(data) => {
          console.log(
            "isrefundable",
            typeof data.isrefundable,
            data.isrefundable,
            data.isrefundable === "on"
          );
          console.log(
            "iscomposed",
            typeof data.iscomposed,
            data.iscomposed,
            data.iscomposed === "on"
          );
          console.log(
            "ispack",
            typeof data.ispack,
            data.ispack,
            data.ispack === "on"
          );
          return {
            ...data,
            orgid: parseInt(data.orgid),
            eventid: parseInt(data.eventid),
            categoryid: parseInt(data.categoryid),
            price: parseFloat(data.price),
            vprice: parseFloat(data.vprice),
            isrefundable: data.isrefundable === "on",
            iscomposed: data.iscomposed === "on",
            ispack: data.ispack === "on",
          };
        }}
      />
    </>
  );
}
