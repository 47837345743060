import { Nav } from "react-bootstrap";
import styles from "./PillToggler.module.scss";

export default function PillToggler({
  options,
  selected,
  setSelected,
  multiple = false,
  style = {},
  pillStyle = {},
  className = "",
  pillClassName = "",
  pillActiveClassName = "",
}) {
  const handleClick = (value) => {
    if (multiple) {
      setSelected(
        selected?.includes(value)
          ? selected.filter((val) => val !== value)
          : [...selected, value]
      );
    } else {
      setSelected(value);
    }
  };
  return (
    <Nav variant={`pills ${styles.NavPills} ${className}`} style={style}>
      {options.map((option, index) => {
        const isSelected = multiple
          ? selected?.includes(option.value)
          : selected === option.value;
        return (
          <Nav.Item key={index}>
            <Nav.Link
              className={`${
                isSelected
                  ? `active ${styles.Active} ${pillActiveClassName}`
                  : `not-active ${styles.NotActive}`
              } ${styles.NavItem} ${pillClassName}`}
              onClick={() => handleClick(option.value)}
              style={pillStyle}
            >
              {option.label}
            </Nav.Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );
}
