import { useCallback, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import histogram from "./histogram";

export default function FastChart({
  data,
  xAxis,
  yAxis,
  preMap,
  postMap,
  intervals = [],
}) {
  const [interval, setInterval] = useState(0);
  const [filteredAxis, setFilteredAxis] = useState(yAxis);
  const [yAxisRef, setYAxisRef] = useState(yAxis[0]);

  const handleAxisClick = useCallback(
    (axis) => {
      const newAxis = [...filteredAxis];
      const index = newAxis.findIndex((a) => a.dataKey === axis.dataKey);
      newAxis[index].hide = !newAxis[index].hide;
      setFilteredAxis(newAxis);
      const ref = newAxis.find((a) => !a.hide);
      setYAxisRef(ref);
    },
    [filteredAxis]
  );

  const preMappedData = useMemo(() => {
    if (typeof preMap === "function") {
      console.log(
        "premapped data",
        data.map((record) => preMap(record, interval))
      );
      return data.map((record) => preMap(record, interval));
    }
    return data;
  }, [data, preMap, interval]);

  const groupedData = useMemo(() => {
    console.log(
      "preMappedData",
      preMappedData,
      "is:",
      intervals,
      "i:",
      interval
    );
    if (intervals?.length > 0) {
      let hist = intervals.find((h) => h.interval === interval);
      if (!hist) {
        hist = intervals.find((h) => h.isDefault);
        if (!hist) {
          hist = intervals[0];
        }
        setInterval(hist.interval);
      }
      const histo = histogram(preMappedData, hist.dataKey, hist.interval);
      console.log(
        "histo",
        histo.map((record) => ({
          load: record.load,
          sale: record.sale,
          date: record.date,
        }))
      );
      return histo;
    }
    return preMappedData;
  }, [preMappedData, intervals, interval]);

  const postMappedData = useMemo(() => {
    if (typeof postMap === "function") {
      return groupedData.map((record) => postMap(record, interval));
    }
    return groupedData;
  }, [groupedData, postMap, interval]);

  const legendFormatter = (value, entry, index) => {
    const { color } = entry;

    return (
      <span style={{ color }}>
        {yAxis[index].label ? yAxis[index].label : value}
      </span>
    );
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          {intervals.map((i, index) => (
            <button
              key={index}
              onClick={() => setInterval(i.interval)}
              className={`btn btn-${
                i.interval === interval ? "primary" : "secondary"
              }`}
              style={{ fontSize: 14, fontWeight: 600 }}
            >
              {i.label}
            </button>
          ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <ResponsiveContainer
            width="100%"
            height={400}
            style={{
              marginBottom: 20,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <BarChart
              width={500}
              height={300}
              data={postMappedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis {...xAxis} />
              <YAxis {...yAxisRef} />
              <Tooltip />
              <Legend formatter={legendFormatter} onClick={handleAxisClick} />
              {filteredAxis.map((_yAxis) => (
                <Bar {..._yAxis} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
}
