import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/custom.scss";
import { AuthProvider } from "./hooks/useAuth";
import { routes } from "./routes";
import { LanguageProvider } from "./lang/useLang";

const queryClient = new QueryClient();

const router = createBrowserRouter(routes);

function App() {
  return (
    <LanguageProvider options={["es", "en"]}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <RouterProvider router={router}></RouterProvider>
        </AuthProvider>
      </QueryClientProvider>
    </LanguageProvider>
  );
}

export default App;
