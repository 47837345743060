import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import i18n from "i18next";
import { initReactI18next, useTranslation, Trans } from "react-i18next";
import es from "./es.json";
import en from "./en.json";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
  },
});

const LANGUAGES = {
  es: { nativeName: "Español", abbreviation: "es" },
  en: { nativeName: "English", abbreviation: "en" },
};

// Function to get a cookie by name
function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

// Function to set a cookie
function setCookie(name, value, options = {}) {
  options = {
    path: "/",
    // add other defaults if necessary
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

const LanguageContext = createContext({ lang: "es" });

export function LanguageProvider({ children, options }) {
  const [lang, setLang] = useState("es");
  const { t } = useTranslation();

  useEffect(() => {
    // Check if "lang" cookie exists
    let _lang = getCookie("lang");

    if (!_lang) {
      // Get the browser language
      _lang = navigator.language || navigator.userLanguage;
    }

    console.log("Language:", _lang);
    if (options.includes(_lang)) {
      setLang(_lang);
      i18n.changeLanguage(_lang);
    }
  }, [options]);

  useEffect(() => {
    setCookie("lang", lang, { "max-age": 60 * 60 * 24 * 365 }); // Cookie expires in 365 days
    i18n.changeLanguage(lang);
    console.log("Language changed to:", lang);
  }, [lang]);

  const tCallback = useCallback((...args) => t(...args), [t, lang]);

  return (
    <LanguageContext.Provider
      value={{ lang, setLang, options, trans: tCallback }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export default function useLang() {
  return useContext(LanguageContext);
}

export function T({ trans, ...props }) {
  return <Trans {...props} i18nKey={trans || ""} />;
}

export function LangSwitcher({
  name = "full",
  style = {},
  buttonStyle = {},
  activeStyle = {},
}) {
  const { options, lang, setLang } = useLang();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      {options.map((lng) => (
        <button
          key={lng}
          style={{
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: "initial",
            borderStyle: lang === lng ? "solid" : "none",
            fontWeight: lang === lng ? "bold" : "normal",
            fontSize: 12,
            padding: 12,
            paddingTop: 5,
            paddingBottom: 5,
            ...buttonStyle,
            ...(lang === lng ? activeStyle : {}),
          }}
          type="submit"
          onClick={() => setLang(lng)}
        >
          {LANGUAGES[lng][name === "full" ? "nativeName" : "abbreviation"]}
        </button>
      ))}
    </div>
  );
}
