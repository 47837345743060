import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get, post } from "../utils/Api";
import { useMemo } from "react";
import { RIGHT_NAMES } from "../constants";
import useLocations from "./useLocations";
import { useAuth } from "./useAuth";
import { useGlobalAlert } from "./useGlobalAlert";

export default function useRights({
  usergroupid = null,
  userid = null,
  eventid = null,
  orgid = null,
} = {}) {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { setAlert } = useGlobalAlert();

  const groupRightsQuery = useQuery({
    queryKey: ["rights", usergroupid, userid, user?.token],
    queryFn: () =>
      post("rights", {
        token: user?.token,
        usergroupid: usergroupid || "",
        userid: userid || "",
      }),
    enabled: !!user?.token && (!!usergroupid || !!userid),
  });

  const userRightsQuery = useQuery({
    queryKey: ["rights", user?.token],
    queryFn: () =>
      post("rights", {
        token: user?.token,
      }),
    enabled: !!user?.token,
  });

  const { locations } = useLocations({ eventid, orgid });

  const groupRights = useMemo(
    () =>
      groupRightsQuery?.data
        ?.filter((right) => right.usergroupid === usergroupid)
        .map((right) => ({
          ...right,
          name: RIGHT_NAMES[parseInt(right.uright)],
          locationname: locations?.find(
            (location) =>
              parseInt(location.locationid) === parseInt(right.locationid)
          )?.name,
        })),
    [groupRightsQuery.data, locations, usergroupid]
  );

  const userRights = useMemo(
    () =>
      userRightsQuery?.data?.map((right) => ({
        ...right,
        name: RIGHT_NAMES[parseInt(right.uright)],
        locationname:
          locations?.find(
            (location) => location.locationid === right.locationid
          )?.name ||
          (parseInt(right.locationid) === 0
            ? "Todas las ubicaciones"
            : "Sin ubicación"),
      })),
    [userRightsQuery.data, locations]
  );

  const removeMutation = useMutation({
    mutationFn: (params) =>
      post("removerights", {
        token: user?.token,
        ...params,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([
        "rights",
        usergroupid,
        userid,
        user?.token,
      ]);
      setAlert({ variant: "success", message: "Derecho eliminado" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
  });

  const addRightMutation = useMutation({
    mutationFn: (body) =>
      post("addrights", {
        token: user?.token,
        userid: "0",
        ...body,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([
        "rights",
        usergroupid,
        userid,
        user?.token,
      ]);
      setAlert({ variant: "success", message: "Derecho agregado" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
  });

  return {
    groupRightsQuery,
    groupRights,
    userRightsQuery,
    userRights,
    removeRight: removeMutation.mutate,
    removeMutation,
    addRight: addRightMutation.mutate,
    addRightMutation,
  };
}
