export const ORDER_DIR = {
  ASC: -1,
  DESC: 1,
};

/**
 * Change the order of an entry in the data array
 *
 * @param {object} originEntry the entry to change the order
 * @param {object[]} rawData the data array
 * @param {string} orderKey the key in the entries that represents the order
 * @param {string} localOrderKey the key in the entries that represents the order
 * @param {string} uniqueKey the key of the entry that is unique
 * @param {number} dir the direction of the order change
 * @returns {object[]} the new data array with the order changed
 */
export default function changeOrder(
  originEntry,
  rawData,
  orderKey,
  localOrderKey,
  uniqueKey,
  dir = 0
) {
  const orderedData = rawData.sort(
    (a, b) => a[localOrderKey] - b[localOrderKey]
  );
  const originIndex = orderedData.findIndex(
    (entry) => entry[uniqueKey] === originEntry[uniqueKey]
  );
  console.log("originIndex", originIndex);
  const dataBefore = orderedData.slice(0, originIndex);
  console.log("dataBefore", dataBefore);
  const dataAfter = orderedData.slice(originIndex + 1);
  console.log("dataAfter", dataAfter);
  if (dir === ORDER_DIR.ASC) {
    const entryToChange = dataBefore.pop();
    dataBefore.push(originEntry);
    dataBefore.push(entryToChange);
  } else if (dir === ORDER_DIR.DESC) {
    const entryToChange = dataAfter.shift();
    dataAfter.unshift(originEntry);
    dataAfter.unshift(entryToChange);
  }
  const result = [...dataBefore, ...dataAfter].map((entry, index) => ({
    ...entry,
    [orderKey]: index + 1,
  }));
  const changedEntries = result.filter((entry) => {
    const originEntry = rawData.find(
      (dataEntry) => dataEntry[uniqueKey] === entry[uniqueKey]
    );
    return originEntry[orderKey] !== entry[orderKey];
  });
  console.log("result", result);
  console.log("changedEntries", changedEntries);
  console.log("originalData", rawData);
  return [result, changedEntries, rawData];
}
