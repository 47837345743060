import { Button, Col, Container, Row } from "react-bootstrap";
import { usePage } from "../../hooks/usePage";
import useProducts from "../../hooks/useProducts";
import useLang, { T } from "../../lang/useLang";
import { useStock } from "../../hooks/useReports";
import { useParams } from "react-router-dom";
import FastForm from "../../components/FastForm/FastForm";
import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faSubtract } from "@fortawesome/free-solid-svg-icons";
import { TX_TYPES } from "../../constants";

export default function ReportStock() {
  const { trans } = useLang();
  usePage({ title: trans("reports.stock") });
  const params = useParams();
  const eventid = params.eventid;
  const { stock, restocks, restocksQuery, restockMutation } = useStock({
    eventid,
    limit: 100000,
    offset: 0,
  });
  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <h1>{trans("reports.stock")}</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <h2>{trans("reports.addstock")}</h2>
          <FastForm
            mutation={restockMutation}
            fields={[
              {
                key: "productid",
                name: "productid",
                label: trans("reports.product_name"),
                type: "select",
                options: Object.values(stock).map((product) => ({
                  value: product.productid,
                  label: product.name,
                })),
                attributes: { required: true },
              },
              {
                key: "qty",
                name: "qty",
                label: trans("reports.product_qty"),
                type: "number",
                attributes: { required: true, step: 1, min: 0 },
                defaultValue: 0,
              },
              {
                key: "total",
                name: "total",
                label: trans("reports.total"),
                type: "number",
                prefix: "$",
                attributes: { required: true, min: 0 },
                defaultValue: 0,
              },
              {
                key: "isPositive",
                name: "isPositive",
                type: "toggle",
                defaultValue: 1,
                options: [
                  {
                    value: 1,
                    label: <FontAwesomeIcon icon={faAdd} />,
                    color: "var(--bs-green)",
                  },
                  {
                    value: -1,
                    label: <FontAwesomeIcon icon={faSubtract} />,
                    color: "var(--bs-danger)",
                  },
                ],
              },
              {
                key: "notes",
                name: "notes",
                label: trans("reports.notes"),
                type: "textarea",
              },
            ]}
          />
        </Col>
        <Col lg={9}>
          <FastTable
            data={Object.values(stock)}
            isLoading={restocksQuery.isLoading}
            columns={[
              {
                label: trans("reports.product_name"),
                key: "name",
                type: TABLE_TYPES.string,
              },
              {
                label: trans("reports.tx_type"),
                key: "txs",
                type: TABLE_TYPES.string,
                render: (row) =>
                  row?.txs?.length > 0 ? (
                    <CollapsibleTxCell {...row} />
                  ) : (
                    <T
                      trans={
                        row?.total > 0 ? "reports.addstock" : "reports.consume"
                      }
                    ></T>
                  ),
                aggregate: (acc, val) => {
                  let txs = acc || [];
                  if (val.txs.length > 0) {
                    txs = txs.concat(val.txs);
                  }
                  return txs;
                },
              },
              {
                label: trans("reports.product_qty"),
                key: "qty",
                type: TABLE_TYPES.number,
                aggregate: (acc, val) =>
                  (parseInt(acc) || 0) + (parseInt(val.qty) || 0),
              },
              {
                label: trans("reports.total"),
                key: "total",
                type: TABLE_TYPES.currency,
                prefix: "$",
                aggregate: (acc, val) =>
                  (parseInt(acc) || 0) + (parseInt(val.total) || 0),
              },
            ]}
            cellStyle={(row, col) => {
              const color =
                row?.total > 0
                  ? "var(--bs-green)"
                  : row?.total < 0
                  ? "var(--bs-danger)"
                  : "var(--bs-body-color)";
              return {
                color,
                fontWeight:
                  col?.key === "total" || col?.key === "qty" ? 600 : 400,
              };
            }}
            showFilters={false}
            showOnlyGrouped={true}
            groupedBy={["productid"]}
          />
          <FastTable
            data={Object.values(stock)}
            isLoading={restocksQuery.isLoading}
            columns={[
              {
                label: trans("reports.product_name"),
                key: "name",
                type: TABLE_TYPES.string,
                filter: true,
              },
              {
                label: trans("reports.date"),
                key: "date",
                type: TABLE_TYPES.dateUnix,
                filter: false,
                render: (row) =>
                  Array.isArray(row.date)
                    ? row.date[0] !== row.date[1]
                      ? row.date
                          .map((d) => new Date(d * 1000).toLocaleString())
                          .join(" - ")
                      : new Date(row.date[0] * 1000).toLocaleString()
                    : new Date(row.date * 1000).toLocaleString(),
              },
              {
                label: trans("reports.tx_type"),
                key: "txs",
                type: TABLE_TYPES.string,
                render: (row) =>
                  row?.txs?.length > 0 ? (
                    <CollapsibleTxCell {...row} />
                  ) : (
                    <span title={row?.notes ? row.notes : ""}>
                      <T
                        trans={
                          row?.total > 0
                            ? "reports.addstock"
                            : "reports.consume"
                        }
                      ></T>
                      {row?.notes && "*"}
                    </span>
                  ),
                aggregate: (acc, val) => {
                  let txs = acc || [];
                  if (val.txs.length > 0) {
                    txs = txs.concat(val.txs);
                  }
                  return txs;
                },
              },
              {
                label: trans("reports.product_qty"),
                key: "qty",
                type: TABLE_TYPES.number,
                aggregate: (acc, val) =>
                  (parseInt(acc) || 0) + (parseInt(val.qty) || 0),
              },
              {
                label: trans("reports.total"),
                key: "total",
                type: TABLE_TYPES.currency,
                prefix: "$",
                aggregate: (acc, val) =>
                  (parseInt(acc) || 0) + (parseInt(val.total) || 0),
              },
            ]}
            cellStyle={(row, col) => {
              const color =
                row?.total > 0
                  ? "var(--bs-green)"
                  : row?.total < 0
                  ? "var(--bs-danger)"
                  : "var(--bs-body-color)";
              return {
                color,
                fontWeight:
                  col?.key === "total" || col?.key === "qty" ? 600 : 400,
              };
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

function CollapsibleTxCell({ txs }) {
  const [show, setShow] = useState(false);
  const { trans } = useLang();

  return (
    <div>
      <header
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          {txs.length} {trans("reports.transactions", { count: txs.length })}
        </span>
        <Button
          type="button"
          onClick={() => setShow(!show)}
          style={{ padding: "0 6px" }}
        >
          <FontAwesomeIcon icon={show ? faSubtract : faAdd} />
        </Button>
      </header>
      {show && (
        <FastTable
          data={txs}
          columns={[
            {
              key: "tag",
              label: trans("reports.usertag"),
              type: TABLE_TYPES.string,
            },
            {
              key: "date",
              label: trans("reports.date"),
              type: TABLE_TYPES.dateUnix,
            },
            {
              key: "type",
              label: trans("reports.tx_type"),
              type: TABLE_TYPES.options,
              options: Object.values(TX_TYPES).map((opt) => ({
                value: opt.value,
                label: trans("tx_types." + opt.value),
              })),
            },
            {
              key: "qty",
              label: trans("reports.qty"),
              type: TABLE_TYPES.number,
              prefix: "x",
            },
            {
              key: "total",
              label: trans("reports.total"),
              type: TABLE_TYPES.currency,
              prefix: "$",
            },
          ]}
          showExcel={false}
          showFilters={false}
          showGroups={false}
          showPagination={false}
        />
      )}
    </div>
  );
}
