import { Button, Col } from "react-bootstrap";
import styles from "./ViewerCard.module.scss";
import { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShekel } from "@fortawesome/free-solid-svg-icons";

export const VIEWER_CARD_SIZES = {
  SMALL: 28,
  MEDIUM: 32,
  LARGE: 36,
};

export default function ViewerCard({
  title,
  prefix = "",
  value,
  className,
  children,
  size = VIEWER_CARD_SIZES.MEDIUM,
  expandable = false,
  ...props
}) {
  const [expanded, setExpanded] = useState(false);
  const valueString = useMemo(() => {
    if (typeof value === "number") {
      if (value > 1e9) {
        return prefix + (value / 1e9).toFixed(1) + " B";
      // } else if (value > 1e6) {
      //   return prefix + (value / 1e6).toFixed(1) + " M";
      // } else if (value > 1e3) {
      //   return prefix + (value / 1e3).toFixed(1) + " K";
      }
      return prefix + value.toLocaleString();
    }
    if (typeof value === "string") {
      return prefix + value;
    }
    if (!value) {
      return "";
    }
    return value;
  }, [prefix, value]);

  const [valueSize, valueFontWeight, valueLength] = useMemo(() => {
    if (typeof valueString === "string" || typeof valueString === "number") {
      const valueLength = (valueString + "").length;
      if (valueLength < 4) {
        return [size * 2.0, "500", valueLength];
      } else if (valueLength < 8) {
        return [size * 1.5, "600", valueLength];
      } else if (valueLength < 10) {
        return [size, "700", valueLength];
      } else if (valueLength < 14) {
        return [size * 0.85, "800", valueLength];
      } else if (valueLength < 16) {
        return [size * 0.65, "800", valueLength];
      } else {
        return [size * 0.5, "900", valueLength];
      }
    }
    return [size, "700", "x"];
  }, [size, valueString]);

  const handleCopyValueToClipboard = useCallback(() => {
    // copy to clipboard
    navigator.clipboard.writeText(value);
  }, [value]);

  const expandableProps = useMemo(() => {
    if (expandable && expanded) {
      return {
        ...props,
        sm: 12,
        xl: 12,
        style: {position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1000}
      };
    }
    return props;
  }, [expanded, expandable, props]);

  return (
    <Col
      className={[className, styles.Card].join(" ")}
      {...expandableProps}
      title={`${title} ${prefix}${value}`}
    >
      <div>
        {(typeof value === "number" || typeof value === "string") && (
          <Button
            onClick={handleCopyValueToClipboard}
            className={styles.CopyButton}
            title="Copiar valor al portapapeles"
          >
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        )}
        {expandable && (
          <Button
            onClick={() => setExpanded(!expanded)}
            className={styles.CopyButton}
            title="Expandir"
          >
            <FontAwesomeIcon icon={faShekel} />
          </Button>
        )}
        <h2>{title}</h2>
        {valueString !== "" && (
          <h3 style={{ fontSize: valueSize, fontWeight: valueFontWeight }}>
            {valueString}
          </h3>
        )}
        {children}
      </div>
    </Col>
  );
}
