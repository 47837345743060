import EventsList from "../../components/EventsList";
import { usePage } from "../../hooks/usePage";

export default function Home({ title }) {
  usePage({ title: "Inicio" });

  return (
    <>
      <EventsList />
    </>
  );
}
