import { Button, Col, Row } from "react-bootstrap";
import useRights from "../hooks/useRights";
import FastTable from "./FastTable/FastTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { ENTITY_NAMES, RIGHT_NAMES } from "../constants";
import FastForm from "./FastForm/FastForm";
import useLocations from "../hooks/useLocations";
import { useAuth } from "../hooks/useAuth";

export default function Rights({ usergroupid }) {
  const params = useParams();
  const { user } = useAuth();
  const { locations } = useLocations({
    eventid: params?.eventid,
    orgid: user?.orgid,
  });
  const { groupRights, addRightMutation, removeRight } = useRights({
    usergroupid,
    eventid: params?.eventid,
  });

  return (
    <Row>
      <Col lg={3}>
        <FastForm
          mutation={addRightMutation}
          fields={[
            {
              name: "uright",
              label: "Permiso",
              type: "select",
              options: [
                { value: "", label: "Seleccione un permiso" },
                ...Object.keys(RIGHT_NAMES).map((key) => ({
                  value: key,
                  label: RIGHT_NAMES[key],
                })),
              ],
              attributes: {
                required: true,
                placeholder: "Seleccione un permiso",
              },
            },
            {
              name: "entity",
              label: "Entidad",
              type: "select",
              options: [
                { value: "", label: "Seleccione una entidad" },
                ...Object.keys(ENTITY_NAMES).map((key) => ({
                  value: key,
                  label: ENTITY_NAMES[key],
                })),
              ],
              attributes: { required: true },
            },
            {
              name: "locationid",
              label: "Ubicación",
              type: "select",
              options: [
                { value: "", label: "Seleccione una ubicación" },
                ...(locations
                  ? locations.map((location) => ({
                      value: location.locationid,
                      label: location.name,
                    }))
                  : []),
                { value: 0, label: "Todas las ubicaciones" },
              ],
              attributes: { required: true },
            },
            {
              name: "usergroupid",
              type: "hidden",
              defaultValue: parseInt(usergroupid),
            },
            {
              name: "eventid",
              type: "hidden",
              defaultValue: parseInt(params?.eventid),
            },
          ]}
          parseData={(data) => ({
            uright: parseInt(data.uright),
            locationid: parseInt(data.locationid),
            usergroupid: parseInt(data.usergroupid),
            orgid: parseInt(data.orgid),
            entity: parseInt(data.entity),
          })}
          sendText="Agregar permiso"
        />
      </Col>
      <Col lg={9}>
        <FastTable
          data={groupRights}
          columns={[
            { key: "name", label: "Nombre", type: "string" },
            {
              key: "locationid",
              label: "Ubicación",
              type: "string",
              render: (row) =>
                typeof row.locationname === "string"
                  ? row.locationname
                  : parseInt(row?.locationid) === 0
                  ? "Todas las ubicaciones"
                  : row.locationid,
            },
            {
              key: "entity",
              label: "Entidad",
              render: (row) => ENTITY_NAMES[row.entity],
              type: "string",
            },
            {
              key: "remove",
              label: "",
              sortable: false,
              style: { width: 50 },
              render: (row) => (
                <Button
                  onClick={(e) => {
                    console.log(row);
                    removeRight({
                      rightid: row.rightid,
                      usergroupid: usergroupid,
                      locationid: row.locationid,
                      entity: row.entity,
                      orgid: parseInt(user.orgid),
                      uright: row.uright,
                      curid: row.curid,
                      userid: row.userid,
                    });
                  }}
                  variant="secondary"
                  className="text-danger"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              ),
            },
          ]}
          showFilters={false}
          showGroups={false}
          showExcel={false}
        />
      </Col>
    </Row>
  );
}
