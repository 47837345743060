import { Col, Form, Row } from "react-bootstrap";
import useProducts from "../../hooks/useProducts";
import { Link, useParams } from "react-router-dom";
import styles from "./Products.module.scss";
import { PRODUCT_STATUS } from "../../constants";
import FastForm from "../../components/FastForm/FastForm";
import useCurrencies from "../../hooks/useCurrencies";
import { useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";

export default function SingleProduct() {
  const params = useParams();
  const { user } = useAuth();
  const productid = !isNaN(params?.productid)
    ? parseInt(params.productid)
    : null;
  const { editProductMutation, categories, product, products } = useProducts({
    productid,
  });
  usePage({ title: product?.name ? product.name : "Producto ¿?" });
  const { currencies } = useCurrencies();

  const [thisProductIndex, nextProductIndex, prevProductIndex] = useMemo(() => {
    if (!products) return [0, 0, 0];
    const thisProductIndex = products.findIndex(
      (product) => parseInt(product.productid) === parseInt(productid)
    );
    return [
      thisProductIndex,
      thisProductIndex < products.length - 1 ? thisProductIndex + 1 : 0,
      thisProductIndex > 0 ? thisProductIndex - 1 : products.length - 1,
    ];
  }, [products, productid]);

  useEffect(() => {
    if (editProductMutation.status !== "idle") {
      editProductMutation.reset();
    }
  }, [params?.productid, editProductMutation]);

  useEffect(() => {
    console.log("rerender SingleProduct with ");
  });

  return (
    <>
      <Row className={styles.Products}>
        <Col>
          <Row>
            <Col>
              <h1>
                {product?.name} <small>({productid})</small>
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {products?.length > 1 && (
                <>
                  <Link
                    className="nav-link text-primary"
                    to={`/${params?.eventid}/products/${products[prevProductIndex]?.productid}`}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Link>
                  <Link
                    className="nav-link text-primary"
                    to={`/${params?.eventid}/products`}
                  >
                    <FontAwesomeIcon icon={faList} />
                  </Link>
                  <Link
                    className="nav-link text-primary"
                    to={`/${params?.eventid}/products/${products[nextProductIndex]?.productid}`}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </>
              )}
            </Col>
          </Row>
          <hr className="small" />
          <>
            {params.productid && params?.eventid && product && (
              <>
                <FastForm
                  key={params.productid}
                  className={styles.EditproductForm}
                  mutation={editProductMutation}
                  sendText="Guardar cambios"
                  fields={[
                    { name: "orgid", type: "hidden", defaultValue: user.orgid },
                    {
                      name: "eventid",
                      type: "hidden",
                      defaultValue: params.eventid,
                    },
                    {
                      name: "productid",
                      type: "hidden",
                      defaultValue: params.productid,
                    },
                    {
                      name: "name",
                      label: "Nombre",
                      type: "text",
                      defaultValue: product?.name,
                    },
                    {
                      name: "categoryid",
                      label: "Categoría",
                      type: "select",
                      options: categories
                        ? categories.map((category) => ({
                            value: category.categoryid,
                            label: category.name,
                          }))
                        : [],
                      defaultValue: product?.categoryid,
                    },
                    {
                      name: "price",
                      type: "number",
                      label: "Precio",
                      defaultValue: product?.price,
                      prefix: "$",
                    },
                    {
                      name: "accepted_currencies",
                      label: "Monedas aceptadas",
                      type: "multi-select",
                      defaultValue: product?.accepted_currencies.map(
                        (curr) => curr.currencyid
                      ),
                      options:
                        currencies?.map((currency) => ({
                          key: currency.currencyid,
                          label: currency.name,
                        })) || [],
                    },
                    {
                      name: "status",
                      type: "select",
                      label: "Estado",
                      options: Object.keys(PRODUCT_STATUS).map((status) => ({
                        value: status,
                        label: PRODUCT_STATUS[status],
                      })),
                      defaultValue: product?.status,
                    },
                    {
                      name: "vprice",
                      type: "hidden",
                      label: "Precio variable",
                      defaultValue: product?.vprice,
                      prefix: "$",
                    },
                    {
                      name: "isrefundable",
                      type: "checkbox",
                      label: "Reembolsable",
                      defaultValue: product?.isrefundable,
                      inline: true,
                    },
                    {
                      name: "iscomposed",
                      type: "hidden",
                      label: "Compuesto",
                      defaultValue: product?.iscomposed,
                      inline: true,
                    },
                    {
                      name: "ispack",
                      type: "hidden",
                      label: "Pack",
                      inline: true,
                      defaultValue: product?.ispack,
                    },
                  ]}
                  parseData={(data) => ({
                    ...data,
                    productid: parseInt(data.productid),
                    orgid: parseInt(data.orgid),
                    eventid: parseInt(data.eventid),
                    categoryid: parseInt(data.categoryid),
                    price: parseFloat(data.price),
                    vprice: parseFloat(data.vprice),
                    isrefundable: !data.isrefundable ? 0 : 1,
                    iscomposed: !data.iscomposed ? 0 : 1,
                    ispack: !data.ispack ? 0 : 1,
                  })}
                />
              </>
            )}
          </>
        </Col>
      </Row>
    </>
  );
}
