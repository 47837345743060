import { Button, Col, Row } from "react-bootstrap";
import useProducts from "../../hooks/useProducts";
import { useMemo, useState } from "react";
import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import FastForm from "../../components/FastForm/FastForm";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Products.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import DisplayGrid from "../../components/DisplayGrid";
import EditDisplayForm from "./Display/EditDisplayForm";
import CreateDisplayForm from "./Display/CreateDisplayForm";
import changeOrder from "../../utils/changeOrder";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";

export default function Displays() {
  usePage({ title: "Displays" });
  const [selectedDisplayid, setSelectedDisplay] = useState(null);
  const [selectedProductindisplayid, setSelectedProduct] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();
  const [categoryid, setCategory] = useState("");
  const {
    productsindisplay,
    productsincategory,
    categories,
    displays,
    editDisplay,
    productdisplays,
    allproductdisplays,
    addProductToDisplayMutation,
    editProductInDisplay,
    editProductInDisplayMutation,
    removeProductFromDisplay,
  } = useProducts({ productdisplayid: selectedDisplayid, categoryid });

  const selectedProduct = useMemo(
    () =>
      productdisplays?.find(
        (product) => product.cpdid === selectedProductindisplayid
      ),
    [productdisplays, selectedProductindisplayid]
  );

  const selectedDisplay = useMemo(
    () =>
      displays?.find(
        (display) => display.productdisplayid === selectedDisplayid
      ),
    [displays, selectedDisplayid]
  );

  const countedDisplays = useMemo(
    () =>
      displays
        ?.sort((a, b) => a.displayorder - b.displayorder)
        ?.map((display, index) => ({
          ...display,
          localorder: index + 1,
          products: allproductdisplays?.filter(
            (product) => product.productdisplayid === display.productdisplayid
          ).length,
        })),
    [displays, allproductdisplays]
  );

  const handleChangeDisplayOrder = (row, dir) => {
    const [newData, changedData] = changeOrder(
      row,
      countedDisplays,
      "displayorder",
      "localorder",
      "productdisplayid",
      dir
    );
    newData.forEach((display) => {
      editDisplay(display);
    });
  };

  const sortedProductsInDisplay = useMemo(
    () =>
      productsindisplay
        ?.sort((a, b) => a.displayorder - b.displayorder || a.cpdid - b.cpdid)
        .map((product, index) => ({
          ...product,
          localorder: index + 1,
        })),

    [productsindisplay]
  );

  const handleChangeProductinDisplayOrder = (row, dir) => {
    const [newData, changedData] = changeOrder(
      row,
      sortedProductsInDisplay,
      "displayorder",
      "localorder",
      "cpdid",
      dir
    );
    changedData.forEach((productInDisplay) => {
      editProductInDisplay(productInDisplay);
    });
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Product Displays</h1>
          <hr className="small" />
        </Col>
      </Row>
      <Row>
        <Col
          md={4}
          style={{ gap: "1rem", display: "flex", flexDirection: "column" }}
        >
          <h2>Displays</h2>
          {countedDisplays && countedDisplays?.length > 0 && (
            <FastTable
              data={countedDisplays}
              uniqueKey="productdisplayid"
              columns={[
                {
                  key: "displayorder",
                  label: "Orden",
                  type: TABLE_TYPES.order,
                  onChangeOrder: handleChangeDisplayOrder,
                },
                { key: "name", label: "Nombre", type: TABLE_TYPES.string },
                {
                  key: "products",
                  label: "Prod.",
                  type: TABLE_TYPES.number,
                  style: { width: 50 },
                },
                { key: "color", label: "Color", type: TABLE_TYPES.color },
              ]}
              onSelectRow={(row) => setSelectedDisplay(row.productdisplayid)}
              selected={(row) => row.productdisplayid === selectedDisplayid}
              showExcel={false}
              showGroups={false}
              showFilters={false}
            />
          )}
          <CreateDisplayForm />
        </Col>
        <Col md={8}>
          {selectedDisplayid && (
            <>
              <Row>
                <Col md={6}>
                  <EditDisplayForm
                    selectedDisplayid={selectedDisplayid}
                    setSelectedDisplay={setSelectedDisplay}
                  />
                </Col>
                {selectedProductindisplayid && selectedProduct ? (
                  <Col md={6}>
                    <Row style={{ width: "calc(400px + 1.5rem)" }}>
                      <Col sm={8}>
                        <h2>
                          <span>Producto</span>{" "}
                          <small style={{ fontSize: "50%" }}>
                            {selectedProduct.name}
                          </small>
                        </h2>
                      </Col>
                      <Col sm={4} className="text-end">
                        <Button
                          style={{
                            padding: "0 5px",
                            border: "none",
                          }}
                          onClick={() => setSelectedProduct(null)}
                        >
                          <FontAwesomeIcon icon={faClose} />
                        </Button>
                      </Col>
                    </Row>
                    <FastForm
                      key={`${selectedDisplayid}-${selectedProductindisplayid}`}
                      className={styles.EditDisplayProductForm}
                      mutation={editProductInDisplayMutation}
                      sendText="Guardar cambios"
                      fields={[
                        {
                          name: "categoryid",
                          label: "Categoría",
                          type: "select",
                          options: [
                            {
                              value: "",
                              label: "Todas",
                            },
                            ...(categories?.map((category) => ({
                              value: category.categoryid,
                              label: category.name,
                            })) || []),
                          ],
                          attributes: {
                            onChange: (e) => setCategory(e.target.value),
                          },
                        },
                        {
                          name: "productid",
                          label: "Producto",
                          type: "select",
                          defaultValue: selectedProduct.productid,
                          options: [
                            {
                              value: "",
                              label: "Cambiar producto",
                            },
                            ...(productsincategory?.map((product) => ({
                              value: product.productid,
                              label: product.name,
                            })) || []),
                          ],
                        },
                        {
                          name: "displayorder",
                          label: "Orden",
                          type: "number",
                          defaultValue: selectedProduct.displayorder,
                          attributes: { min: 0, step: 1 },
                        },
                        {
                          name: "cpdid",
                          type: "hidden",
                          defaultValue: selectedProduct.cpdid,
                          attributes: { readOnly: true },
                        },
                        {
                          name: "eventid",
                          type: "hidden",
                          defaultValue: parseInt(params.eventid),
                          attributes: { readOnly: true },
                        },
                      ]}
                      append={
                        <>
                          <Button
                            className="btn-bordered"
                            onClick={() =>
                              navigate(
                                `/${params.eventid}/products/${selectedProduct.productid}`
                              )
                            }
                          >
                            Editar producto
                          </Button>
                          <Button
                            variant="danger"
                            className="btn-bordered"
                            onClick={() =>
                              removeProductFromDisplay({
                                ...selectedProduct,
                                productdisplayid: selectedDisplayid,
                              })
                            }
                          >
                            Quitar producto
                          </Button>
                        </>
                      }
                    />
                  </Col>
                ) : (
                  <Col md={6}>
                    <h2>Agregar Producto</h2>
                    <FastForm
                      key={`${selectedDisplayid}-new`}
                      mutation={addProductToDisplayMutation}
                      sendText="Agregar producto"
                      fields={[
                        {
                          name: "categoryid",
                          label: "Categoría",
                          type: "select",
                          options: [
                            {
                              value: "",
                              label: "Todas",
                            },
                            ...(categories?.map((category) => ({
                              value: category.categoryid,
                              label: category.name,
                            })) || []),
                          ],
                          attributes: {
                            onChange: (e) => setCategory(e.target.value),
                          },
                        },
                        {
                          name: "productid",
                          label: "Producto",
                          type: "select",
                          options: [
                            {
                              value: "",
                              label: "Agregar producto",
                            },
                            ...(productsincategory?.map((product) => ({
                              value: product.productid,
                              label: product.name,
                            })) || []),
                          ],
                        },
                        {
                          name: "displayorder",
                          label: "Orden",
                          type: "number",
                          defaultValue: 0,
                          attributes: { min: 0, step: 1 },
                        },
                        {
                          name: "productdisplayid",
                          type: "hidden",
                          defaultValue: selectedDisplayid,
                          attributes: { readOnly: true },
                        },
                        {
                          name: "eventid",
                          type: "hidden",
                          defaultValue: parseInt(params.eventid),
                          attributes: { readOnly: true },
                        },
                      ]}
                    />
                  </Col>
                )}
              </Row>
              <hr className="large" />
              <DisplayGrid
                productsindisplay={sortedProductsInDisplay}
                name={selectedDisplay?.name}
                columns={selectedDisplay?.ncolumns || 4}
                selected={(row) => row.cpdid === selectedProductindisplayid}
                onSelect={(product) =>
                  setSelectedProduct((prev) =>
                    prev === product.cpdid ? null : product.cpdid
                  )
                }
              />
              <FastTable
                data={sortedProductsInDisplay}
                showExcel={false}
                showFilters={false}
                showGroups={false}
                columns={[
                  {
                    key: "cpdid",
                    label: "ID",
                    type: "number",
                    style: { width: 40 },
                  },
                  {
                    key: "displayorder",
                    label: "Orden",
                    type: TABLE_TYPES.order,
                    onChangeOrder: handleChangeProductinDisplayOrder,
                    style: { width: 100 },
                  },
                  {
                    key: "name",
                    label: "Nombre",
                    type: "string",
                  },
                  {
                    key: "price",
                    label: "Precio",
                    type: "number",
                    prefix: "$",
                  },
                  {
                    key: "remove",
                    label: <FontAwesomeIcon icon={faTrash} />,
                    sortable: false,
                    style: { width: 50 },
                    render: (row) => (
                      <Button
                        onClick={(e) => {
                          console.log(row);
                          removeProductFromDisplay({
                            cpdid: parseInt(row.cpdid),
                            orgid: parseInt(user.orgid),
                            productdisplayid: selectedDisplayid,
                          });
                        }}
                        variant="secondary"
                        className="text-danger"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    ),
                  },
                  {
                    key: "edit",
                    label: <FontAwesomeIcon icon={faEdit} />,
                    sortable: false,
                    style: { width: 50 },
                    render: (row) => (
                      <Button
                        onClick={() =>
                          navigate(
                            `/${params.eventid}/products/${row.productid}`
                          )
                        }
                        variant="secondary"
                        className="text-primary"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    ),
                  },
                ]}
                selected={(row) => row.cpdid === selectedProductindisplayid}
                onSelectRow={(row) =>
                  setSelectedProduct((prev) =>
                    prev === row.cpdid ? null : row.cpdid
                  )
                }
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
