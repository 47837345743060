import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get, post } from "../utils/Api";
import { useGlobalAlert } from "./useGlobalAlert";
import { useEffect, useMemo } from "react";
import { useAuth } from "./useAuth";

export const LOCATION_TYPES = {
  topUp: {
    label: "Top-Up",
    value: 1,
  },
  sales: {
    label: "Sales",
    value: 2,
  },
};

export default function useLocations({ eventid, locationid }) {
  const queryClient = useQueryClient();
  const { setAlert } = useGlobalAlert();
  const { user } = useAuth();
  const { orgid } = user;

  const locationsQuery = useQuery({
    queryKey: ["locations", eventid],
    queryFn: async () => {
      const params = { token: user?.token };
      console.log("locations params", params, eventid);
      if (eventid) params.eventid = eventid;
      if (orgid) params.orgid = orgid;
      return post("/locations", params);
    },
  });

  const createLocationMutation = useMutation({
    mutationFn: (data) =>
      post("addlocation", { ...data, token: user?.token, status: 1 }),
    onSuccess: () => {},
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.msg
          ? error.msg
          : error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("locations");
    },
  });

  const editLocationMutation = useMutation({
    mutationFn: (data) =>
      post("editlocation", { status: 1, ...data, token: user?.token }),
    onSuccess: () => {},
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.msg
          ? error.msg
          : error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("locations");
    },
  });

  const location = useMemo(() => {
    if (!locationsQuery.data) return null;
    return locationsQuery.data.find(
      (l) => parseInt(l.locationid) === parseInt(locationid)
    );
  }, [locationsQuery.data, locationid]);

  return {
    locations: locationsQuery.data,
    locationsQuery,
    createLocationMutation,
    createLocation: createLocationMutation.mutate,
    editLocationMutation,
    editLocation: editLocationMutation.mutate,
    location,
  };
}
