import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { post } from "../utils/Api";

export default function AutoComplete({
  name,
  data = null,
  endpoint = null,
  searchkeys = ["name"],
  parseValue = (value) => value,
  parseOptions = (option) => option,
  defaultValue = "",
  className = "",
}) {
  const [displayValue, setDisplayValue] = useState(defaultValue);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [value, setValue] = useState(defaultValue);

  const query = useQuery({
    queryKey: [endpoint, displayValue],
    queryFn: () =>
      endpoint
        ? post(endpoint, { keys: searchkeys, search: displayValue })
        : new Promise((resolve, reject) => {
            const result = data.filter((ele) =>
              searchkeys.some((key) =>
                ele[key].toLowerCase().includes(displayValue.toLowerCase())
              )
            );
            resolve(result);
          }),
    enabled: typeof displayValue === "string" && displayValue.length > 2,
  });

  const options = useMemo(() => {
    if (!query.data) {
      return [];
    }
    return query.data.map(parseOptions);
  }, [query.data, parseOptions]);

  useEffect(() => {
    console.log({ selectedLabel, displayValue });
    if (selectedLabel !== "" && displayValue !== selectedLabel) {
      setSelectedLabel("");
      setDisplayValue("");
    }
  }, [selectedLabel, displayValue]);

  return (
    <>
      {/* <pre>
        {JSON.stringify(
          {
            value,
            displayValue,
            selectedLabel,
            endpoint,
            searchkey,
            defaultValue,
          },
          null,
          " "
        )}
      </pre> */}
      <Typeahead
        defaultSelected={displayValue}
        onInputChange={(selected) => {
          console.log("onInputChange", selected);
          setDisplayValue(selected);
        }}
        onChange={(_value) => {
          console.log("onChange", _value);
          if (_value.length > 0) {
            console.log("onChange", _value[0].label);
            setSelectedLabel(_value[0].label);
            setValue((pre) => parseValue(_value[0], pre));
          }
        }}
        options={options}
        className={`autocomplete ${className}`}
      />
      <input type="hidden" name={name} value={value} />
    </>
  );
}
