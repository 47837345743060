import { useState, Fragment, useMemo, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { usePage } from "../hooks/usePage";
import useLang from "../lang/useLang";
import ViewerCard, { VIEWER_CARD_SIZES } from "./ViewerCard";
import FastTable, { TABLE_TYPES } from "./FastTable/FastTable";
import { CHART_COLORS } from "../constants";
import PillToggler from "./PillToggler";
import styles from "./Viewer.module.scss";

export default function Viewer({
  totalTopUpsAmount,
  totalTopUpsPayments,
  totalSales,
  totalSalesAmount,
  totalInits,
  cashiers,
  salespeople,
  locations,
  products,
  currencies,
  filterZeroes = false,
}) {
  const { trans } = useLang();
  usePage({ title: trans("nav.viewer") });

  const currenciesFiltered = useMemo(
    () =>
      Object.keys(currencies).reduce((acc, currencyid) => {
        const currency = currencies[currencyid];
        if (currency.topUpsAmount || currency.salesAmount) {
          acc[currencyid] = currency;
        }
        return acc;
      }, {}),
    [currencies]
  );
  const [selectedCurrenciesForTotal, setSelectedCurrenciesForTotal] = useState(
    currenciesFiltered
      ? Object.values(currenciesFiltered)
          .filter(
            (currency) => currency?.name.toLowerCase().indexOf("cortes") === -1
          )
          .map((currency) => currency.currencyid)
      : []
  );

  useEffect(() => {
    if (
      selectedCurrenciesForTotal.length === 0 &&
      currenciesFiltered &&
      Object.keys(currenciesFiltered).length > 0
    ) {
      setSelectedCurrenciesForTotal(
        Object.values(currenciesFiltered)
          .filter(
            (currency) => currency?.name.toLowerCase().indexOf("cortes") === -1
          )
          .map((currency) => currency.currencyid)
      );
    }
  }, [currenciesFiltered, selectedCurrenciesForTotal]);

  const filteredTotalTopUpsAmount = useMemo(() => {
    return Object.values(currenciesFiltered).reduce(
      (acc, payment) =>
        (selectedCurrenciesForTotal.includes(payment.currencyid)
          ? payment.topUpsAmount + payment.refundsAmount
          : 0) + acc,
      0
    );
  }, [currenciesFiltered, selectedCurrenciesForTotal]);

  const residue = useMemo(() => {
    return (
      Object.values(currenciesFiltered).reduce((result, curr) => {
        if (selectedCurrenciesForTotal.includes(curr.currencyid)) {
          return (
            result + curr.topUpsAmount + curr.refundsAmount - curr.salesAmount
          );
        }
        return result;
      }, 0) || 0
    );
  }, [selectedCurrenciesForTotal, currenciesFiltered]);

  return (
    <Row>
      {(filteredTotalTopUpsAmount || !filterZeroes) && (
        <Col sm={12} md={6} xl={3} style={{ flexGrow: 1 }}>
          <Row>
            {
              <ViewerCard
                title="Total Recargas"
                prefix="$ "
                value={filteredTotalTopUpsAmount}
                sm={12}
                size={VIEWER_CARD_SIZES.MEDIUM}
              >
                {totalTopUpsPayments && (
                  <CurrencyToggler
                    currencies={currenciesFiltered}
                    selected={selectedCurrenciesForTotal}
                    setSelected={setSelectedCurrenciesForTotal}
                  />
                )}
              </ViewerCard>
            }
            {totalTopUpsPayments &&
              Object.values(totalTopUpsPayments).map(
                (payment) =>
                  (payment.amount || !filterZeroes) && (
                    <ViewerCard
                      key={payment.paymentmethodid}
                      title={`${!payment?.paymentmethodid ? "Recargas " : ""}${
                        payment.name
                      }`}
                      prefix="$ "
                      value={payment.amount}
                      sm={6}
                      className="flex-grow-1"
                      size={VIEWER_CARD_SIZES.SMALL}
                    >
                      <table style={{ margin: 0 }}>
                        <tbody>
                          {Object.values(payment?.currencies || {})?.map(
                            (currency) => (
                              <tr key={currency.currencyid}>
                                <td
                                  style={{
                                    fontSize: 12,
                                    whiteSpace: "nowrap",
                                    textAlign: "left",
                                  }}
                                >
                                  {currency.name}
                                </td>
                                <td
                                  style={{
                                    fontSize: 12,
                                    whiteSpace: "nowrap",
                                    textAlign: "right",
                                  }}
                                >
                                  x {currency.count.toLocaleString()}
                                </td>
                                <td
                                  style={{
                                    fontSize: 12,
                                    whiteSpace: "nowrap",
                                    textAlign: "right",
                                  }}
                                >
                                  $ {currency.amount.toLocaleString()}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </ViewerCard>
                  )
              )}
          </Row>
        </Col>
      )}
      {(totalSales || !filterZeroes) && (
        <ViewerCard
          title="Número Total de Ventas"
          value={totalSales}
          sm={6}
          xl={3}
          size={VIEWER_CARD_SIZES.LARGE}
          style={{ flexGrow: 1 }}
        >
          {currenciesFiltered && (
            <table>
              <tbody>
                {Object.values(currenciesFiltered).map((currency) => (
                  <tr key={currency.currencyid}>
                    <td style={{ textAlign: "left" }}>{currency.name}</td>
                    <td style={{ textAlign: "right" }}>x {currency.sales}</td>
                    <td style={{ textAlign: "right" }}>
                      $ {currency.salesAmount.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ViewerCard>
      )}
      {((residue > 0 && totalSales !== 0) || !filterZeroes) && (
        <ViewerCard
          title="No gastado"
          prefix="$ "
          value={residue}
          sm={6}
          xl={3}
          size={VIEWER_CARD_SIZES.LARGE}
          style={{ flexGrow: 1 }}
        >
          <CurrencyToggler
            currencies={currenciesFiltered}
            selected={selectedCurrenciesForTotal}
            setSelected={setSelectedCurrenciesForTotal}
          />
          {currenciesFiltered && (
            <table>
              <tbody>
                {Object.values(currenciesFiltered).map((currency) => (
                  <tr key={currency.currencyid}>
                    <td style={{ textAlign: "left" }}>{currency.name}</td>
                    <td style={{ textAlign: "right" }}>
                      ${" "}
                      {(
                        currency.topUpsAmount +
                        currency.refundsAmount -
                        currency.salesAmount
                      ).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ViewerCard>
      )}
      {(totalInits || !filterZeroes) && (
        <ViewerCard
          title="Activaciones"
          value={totalInits}
          sm={6}
          xl={3}
          size={VIEWER_CARD_SIZES.LARGE}
          style={{ flexGrow: 1 }}
        />
      )}
      {Object.values(currencies).map((currency) => {
        if (currency.topUpsAmount || currency.salesAmount) {
          const loads =
            currency.topUpsAmount +
            currency.refundsAmount +
            currency.wipesAmount;
          return (
            <Fragment key={currency.currencyid}>
              {(loads || !filterZeroes) && (
                <ViewerCard
                  title={`Total carga en ${currency.name}`}
                  sm={6}
                  xl={2}
                  prefix="$ "
                  value={loads}
                  size={VIEWER_CARD_SIZES.LARGE}
                  style={{ flexGrow: 1 }}
                >
                  <table style={{ fontSize: 12 }}>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>Cargas</td>
                        <td style={{ textAlign: "right" }}>
                          {currency?.topUps}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          $ {currency?.topUpsAmount?.toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}>Devoluciones</td>
                        <td style={{ textAlign: "right" }}>
                          {currency?.refunds}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          $ {currency?.refundsAmount?.toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}>Limpieza</td>
                        <td style={{ textAlign: "right" }}>
                          {currency?.wipes}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          $ {currency?.wipesAmount?.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ViewerCard>
              )}
              {(currency.salesAmount || !filterZeroes) && (
                <ViewerCard
                  title={`Total venta en ${currency.name}`}
                  sm={6}
                  xl={2}
                  prefix="$ "
                  value={currency.salesAmount}
                  size={VIEWER_CARD_SIZES.LARGE}
                  style={{ flexGrow: 1 }}
                >
                  <table style={{ fontSize: 12 }}>
                    <tbody>
                      <tr>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </ViewerCard>
              )}
            </Fragment>
          );
        } else {
          return null;
        }
      })}
      {(Object.keys(cashiers).length || !filterZeroes) && (
        <ViewerCard
          title="Recarga x cajero"
          sm={12}
          xl={6}
          style={{ flexGrow: 1 }}
        >
          <FastTable
            showFilters={false}
            showGroups={false}
            showExcel={false}
            showPagination={false}
            data={Object.values(cashiers)}
            pieChart={{
              data: Object.values(cashiers).sort((a, b) => b.amount - a.amount),
              legend: { dataKey: "name", label: "Nombre" },
              dataKey: "amount",
              label: "Monto",
              prefix: "$",
              palette: CHART_COLORS,
            }}
            columns={[
              { label: "ID", key: "userid" },
              { label: "Nombre", key: "name", type: TABLE_TYPES.string },
              {
                label: "Total",
                key: "amount",
                type: TABLE_TYPES.currency,
                prefix: "$",
              },
            ]}
          />
        </ViewerCard>
      )}
      {(Object.keys(salespeople).length || !filterZeroes) && (
        <ViewerCard
          title="Venta x bartender"
          sm={12}
          xl={6}
          style={{ flexGrow: 1 }}
        >
          <FastTable
            showFilters={false}
            showGroups={false}
            showExcel={false}
            showPagination={false}
            data={Object.values(salespeople)}
            pieChart={{
              data: Object.values(salespeople).sort(
                (a, b) => b.amount - a.amount
              ),
              legend: { dataKey: "name", label: "Nombre" },
              dataKey: "amount",
              label: "Monto",
              prefix: "$",
              palette: CHART_COLORS,
            }}
            columns={[
              { label: "ID", key: "userid" },
              { label: "Nombre", key: "name", type: TABLE_TYPES.string },
              {
                label: "Total",
                key: "amount",
                type: TABLE_TYPES.currency,
                prefix: "$",
              },
            ]}
          />
        </ViewerCard>
      )}
      {(Object.keys(locations).length || !filterZeroes) && (
        <ViewerCard
          title="Venta x punto de venta"
          sm={12}
          xl={6}
          style={{ flexGrow: 1 }}
        >
          <FastTable
            showFilters={false}
            showGroups={false}
            showExcel={false}
            showPagination={false}
            data={Object.values(locations)}
            pieChart={{
              data: Object.values(locations).sort(
                (a, b) => b.amount - a.amount
              ),
              legend: { dataKey: "name", label: "Nombre" },
              dataKey: "amount",
              label: "Monto",
              prefix: "$",
              palette: CHART_COLORS,
            }}
            columns={[
              { label: "ID", key: "locationid" },
              { label: "Nombre", key: "name", type: TABLE_TYPES.string },
              {
                label: "Total",
                key: "amount",
                type: TABLE_TYPES.currency,
                prefix: "$",
              },
            ]}
          />
        </ViewerCard>
      )}
      {(Object.keys(products).length || !filterZeroes) && (
        <ViewerCard title="Venta x producto" sm={12} xl={6}>
          <FastTable
            showFilters={false}
            showGroups={false}
            showExcel={false}
            showPagination={true}
            data={Object.values(products)}
            pieChart={{
              data: Object.values(products).sort((a, b) => b.amount - a.amount),
              legend: { dataKey: "name", label: "Nombre" },
              dataKey: "amount",
              label: "Monto",
              prefix: "$",
              palette: CHART_COLORS,
            }}
            columns={[
              { label: "ID", key: "productid" },
              { label: "Nombre", key: "name", type: TABLE_TYPES.string },
              {
                label: "Vent.",
                key: "qty",
                type: TABLE_TYPES.number,
                // prefix: "x ",
              },
              {
                label: "Devol.",
                key: "qty_return",
                type: TABLE_TYPES.number,
                prefix: "-",
              },
              {
                label: "Cant.",
                key: "qty_net",
                type: TABLE_TYPES.number,
                prefix: "x ",
              },
              {
                label: "Total",
                key: "amount",
                type: TABLE_TYPES.currency,
                prefix: "$",
              },
            ]}
          />
        </ViewerCard>
      )}
    </Row>
  );
}

function CurrencyToggler({ currencies, selected, setSelected }) {
  return (
    <PillToggler
      multiple={true}
      options={Object.values(currencies).map((payment) => ({
        value: payment.currencyid,
        label: payment.name,
      }))}
      selected={selected?.length > 0 ? selected : Object.keys(currencies)}
      setSelected={(val) => setSelected((prev) => (prev === val ? null : val))}
      style={{ justifyContent: "center", gap: 5 }}
      className={styles.PillToggler}
      pillClassName={styles.PillItem}
      pillActiveClassName={styles.PillActive}
    />
  );
}
