import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

export default function TabPanel({ content, defaultKey }) {
  const [key, setKey] = useState(defaultKey);

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      {content.map((item, index) => (
        <Tab eventKey={item.key} title={item.title} key={index}>
          {item.content}
        </Tab>
      ))}
    </Tabs>
  );
}
