import { Col, Row } from "react-bootstrap";
import styles from "./Footer.module.scss";

export default function Footer() {
  return (
    <Row className={styles.Footer}>
      <Col>
        <small>{`gopal ${new Date().getFullYear()}`}</small>
      </Col>
    </Row>
  );
}
